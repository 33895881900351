import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";

import { ListType } from "common/list";
import { OrderListActionTypes } from "../OrderListActionTypes";
import { RootState } from "RootReducer";
import { SearchOrdersProps } from "./searchOrders";
import { SupportedOrderImportErrorTypes } from "../OrderImportErrorsTable/SupportedOrderImportErrorTypes";
import { Thunk } from "common/ReduxUtils";
import { batch } from "react-redux";
import { getOrderImportErrorCustomizedFilters } from "../helpers/getOpenSearchFilters";
import { importErrorsAlgoliaService } from "../algoliaConfig";
import log from "Logger";
import produce from "immer";
import { searchServiceFor } from "common/search/Factory";

const orderIngestErrorService = searchServiceFor(ListType.OrderIngestErrors);

export const searchImportErrors: Thunk =
  ({ page, isOrderIngestErrorsLogisticsSearchOn }: SearchOrdersProps = {}) =>
  async (dispatch, getState: () => RootState) => {
    const {
      orderListV2: { currentSearchTerm, importErrors },
      user: { resultsPerPage },
    } = getState();
    const ctx = { fn: "searchImportErrors", currentSearchTerm };
    log.info({ ...ctx, page }, "searching import errors");

    batch(() => {
      dispatch(addLoader(OrderListActionTypes.LOAD_IMPORT_ERRORS_TABLE));
      dispatch({
        type: OrderListActionTypes.SEARCH_IMPORT_ERRORS_START,
      });
    });

    try {
      const errorTypeFilter = SupportedOrderImportErrorTypes.map((error) => `errorType:${error}`).join(" OR ");
      const customizedOpenSearchFilters = getOrderImportErrorCustomizedFilters();
      const searchResult = isOrderIngestErrorsLogisticsSearchOn
        ? await orderIngestErrorService.execute({
            searchTerm: currentSearchTerm,
            page: page,
            customizedOpenSearchFilters: customizedOpenSearchFilters,
            pageSize: resultsPerPage.orderList,
            highlightMatches: true,
          })
        : await importErrorsAlgoliaService.search(
            currentSearchTerm,
            page,
            undefined,
            [errorTypeFilter],
            undefined,
            resultsPerPage.orderList
          );
      log.info(ctx, "successfully retrieved import errors");

      const { response, hits } = searchResult;
      dispatch({
        type: OrderListActionTypes.SEARCH_IMPORT_ERRORS_SUCCESS,
        importErrors: produce(importErrors, (draft) => {
          draft.searchInfo = {
            totalPages: response.nbPages,
            currentPage: response.page,
            totalHits: response.nbHits,
            itemsPerPage: response.hitsPerPage,
          };

          draft.currentPageItems = hits.map((hit) => ({
            ...hit,
            attributes: hit.raw.attributes,
            displayAttributes: hit.attributes,
            lastEventTime: new Date(hit.lastEventTime),
          }));
        }),
      });

      return dispatch(clearLoader(OrderListActionTypes.LOAD_IMPORT_ERRORS_TABLE));
    } catch (err) {
      log.error({ ...ctx, err }, "error searching for import errors");
      return dispatch({ type: OrderListActionTypes.SEARCH_IMPORT_ERRORS_ERROR });
    }
  };
