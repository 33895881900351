import { formatInTimeZone } from "date-fns-tz";

// Intercom chat is active from 7am to 5pm CST, Monday through Friday
const DAYS_OF_WEEK = ["Mon", "Tue", "Wed", "Thu", "Fri"];
const CHAT_ACTIVE_START_TIME_CST = 7;
const CHAT_ACTIVE_END_TIME_CST = 17;

// America/Chicago is CST
export const getIsSupportChatLive = (currentDate: Date = new Date()) => {
  const dayOfTheWeek = formatInTimeZone(currentDate, "America/Chicago", "eee");
  const hour = Number(formatInTimeZone(currentDate, "America/Chicago", "HH"));

  return DAYS_OF_WEEK.includes(dayOfTheWeek) && hour >= CHAT_ACTIVE_START_TIME_CST && hour < CHAT_ACTIVE_END_TIME_CST;
};
