import { CrossdockInboundQuote } from "common/clients/crossdock/CrossdockInboundQuote/CrossdockInboundQuote";
import { BasicTableColumn } from "common/components/BasicTable";
import { useCurrencyValue } from "common/components/Currency";
import { CrossdockIntermediateValues } from "inbounds/crossdock/util";
import { commonMsgs } from "inbounds/utils/commonMsgs";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { CostTableSubTableConfig } from "./CostTableTypes";
import { useCostTableColumns } from "./useCostTableColumns";

interface UseForwardingCostTableProps {
  quote: CrossdockInboundQuote;
  intermediateValues: CrossdockIntermediateValues;
}

export const useForwardingCostTable = ({
  quote,
  intermediateValues,
}: UseForwardingCostTableProps): CostTableSubTableConfig => {
  const { formatMessage, formatNumber } = useIntl();

  const { totalDiscount, itemQty, percentDiscount } = quote;
  const { undiscountedTotal, undiscountedUnitCost } = intermediateValues;
  const formatCurrency = useCurrencyValue({ maximumFractionDigits: 3 });

  // labels
  const labels = useMemo(() => {
    const tableTitle = formatMessage({
      id: "inbounds.shipmentSetup.costTable.forwarding.title",
      defaultMessage: "Forwarding costs",
    });

    const forwardingRowLabel = formatMessage({
      id: "inbounds.shipmentSetup.costTable.forwarding.forwardingRowLabel",
      defaultMessage: "Forwarding fee",
    });

    const discountRowLabel = formatMessage(
      {
        id: "inbounds.shipmentSetup.costTable.forwarding.discountRowLabel",
        defaultMessage: "{percentDiscount} forwarding fee discount",
      },
      {
        percentDiscount: formatNumber(percentDiscount, { style: "percent" }),
      }
    );

    const prepBclRowLabel = formatMessage({
      id: "inbounds.shipmentSetup.costTable.forwarding.prepBclRowLabel",
      defaultMessage: "Flexport Prep: Box contents labeling",
    });

    const totalRowLabel = formatMessage({
      id: "inbounds.shipmentSetup.costTable.forwarding.totalRowLabel",
      defaultMessage: "Total forwarding costs",
    });

    const itemQtyWithUnits = formatMessage(commonMsgs.units, {
      units: itemQty,
    });

    const formattedPricePerUnit = formatMessage(commonMsgs.perUnit, {
      amount: formatCurrency(undiscountedUnitCost),
    });

    return {
      tableTitle,
      forwardingRowLabel,
      discountRowLabel,
      totalRowLabel,
      prepBclRowLabel,
      itemQtyWithUnits,
      formattedPricePerUnit,
    };
  }, [percentDiscount, formatMessage, formatNumber, itemQty, undiscountedUnitCost, formatCurrency]);

  const columns = useCostTableColumns({ tableTitle: labels.tableTitle });

  const data: BasicTableColumn[] = useMemo(() => {
    const rows: BasicTableColumn[] = [
      {
        rowLabel: labels.forwardingRowLabel,
        quantity: labels.itemQtyWithUnits,
        unitCost: labels.formattedPricePerUnit,
        amount: undiscountedTotal,
      },
    ];

    // Only show discount row if there is one
    if (totalDiscount > 0) {
      rows.push({
        rowLabel: labels.discountRowLabel,
        amount: -totalDiscount,
      });
    }

    return rows;
  }, [
    undiscountedTotal,
    totalDiscount,
    labels.forwardingRowLabel,
    labels.discountRowLabel,
    labels.itemQtyWithUnits,
    labels.formattedPricePerUnit,
  ]);

  return {
    columns,
    data,
    aggregatorOptions: { totalRowLabel: labels.totalRowLabel },
  };
};
