/* eslint-disable @typescript-eslint/promise-function-async */
import { createElement } from "react";
import Loadable from "react-loadable";

import { SimpleLoader } from "common/components/WithLoader/WithLoader";
import Loading from "./common/components/Loading";

export const createLoadable = (loader: () => Promise<any>) =>
  Loadable({
    loader,
    loading: Loading as any,
    delay: 400, // don't show loading if page takes less than 400ms to load, avoid flash loading
  });

export const HeadlessLoginChunk = createLoadable(
  () => import(/* webpackChunkName: "HeadlessLogin" */ "./login/HeadlessLogin")
);

export const LoginRedirect = createLoadable(
  () => import(/* webpackChunkName: "LoginRedirect" */ "./login/LoginRedirect")
);

export const OnboardingRedirect = createLoadable(
  () => import(/* webpackChunkName: "OnboardingRedirect" */ "./onboarding/OnboardingRedirect")
);

export const OnboardingCreateCatalog = createLoadable(
  () => import(/* webpackChunkName: "OnboardingCreateCatalog" */ "./onboarding/CreateCatalog/CreateCatalog")
);

export const OnboardingChooseIntegration = createLoadable(
  () => import(/* webpackChunkName: "ChooseIntegration" */ "./onboarding/ChooseIntegration")
);

export const OnboardingIntegrationSetup = createLoadable(
  () => import(/* webpackChunkName: "ListingToolSetup" */ "./onboarding/ListingTool/OnboardingIntegrationSetup")
);

export const OnboardingShopifySettings = createLoadable(
  () => import(/* webpackChunkName: "OnboardingShopifySettings" */ "./onboarding/OnboardingShopifySettings")
);

export const OnboardingSurvey = createLoadable(
  () => import(/* webpackChunkName: "OnboardingSurvey" */ "./onboarding/OnboardingSurvey")
);

export const ChannelsIntegrationSetup = createLoadable(
  () => import(/* webpackChunkName: "ChannelsIntegrationSetup" */ "./channels/ChannelsIntegrationSetup")
);

export const ChannelsUpdateCredentials = createLoadable(
  () => import(/* webpackChunkName: "ChannelsUpdateCredentials" */ "./channels/ChannelsUpdateCredentials")
);

export const IntegrationSetupComplete = createLoadable(
  () =>
    import(
      /* webpackChunkName: "ListingToolSetupComplete" */ "./onboarding/IntegrationSetupComplete/IntegrationSetupComplete"
    )
);

export const ImportingCatalogChunk = createLoadable(
  () => import(/* webpackChunkName: "ImportingCatalog" */ "./onboarding/ImportingCatalog")
);

export const AllSetChunk = createLoadable(() => import(/* webpackChunkName: "AllSet" */ "./onboarding/AllSet"));

export const TariffSelectChunk = Loadable({
  loader: () => import(/* webpackChunkName: "TariffSelect" */ "./common/components/TariffCodeSelect/TariffCodeSelect"),
  loading: SimpleLoader as any,
  delay: 400,
  render: (loaded, props) => createElement(loaded.default, props),
});

export const NewOrderChunk = createLoadable(() => import(/* webpackChunkName: "NewOrder" */ "./order/new/NewOrder"));

export const NonComplianceChunk = Loadable({
  loader: () => import(/* webpackChunkName: "NonCompliance" */ "./inbounds/non-compliance/NonCompliance"),
  loading: SimpleLoader as any,
  delay: 400,
  render: (loaded, props) => createElement(loaded.default, props),
});

export const OrderListV2Chunk = createLoadable(
  () => import(/* webpackChunkName: "OrderListV2" */ "./order/listV2/OrderList")
);

export const OrderDetailChuck = createLoadable(
  () => import(/* webpackChunkName: "OrderDetail" */ "./order/detail/OrderDetail")
);

export const OrderClaimChunk = createLoadable(
  () => import(/* webpackChunkName: "OrderClaim" */ "./order/claim/OrderClaim")
);

export const OrderClaimAppealChunk = createLoadable(
  () => import(/* webpackChunkName: "AppealView" */ "./order/claim/appeal/AppealView")
);

export const OrderClaimReviewChunk = createLoadable(
  () => import(/* webpackChunkName: "OrderClaimReview" */ "./order/claim/reviewV2/ReviewClaim")
);

export const ChannelsList = createLoadable(() => import(/* webpackChunkName: "ChannelsList" */ "./channels/Channels"));

export const ChannelsEdit = createLoadable(
  () => import(/* webpackChunkName: "ChannelsEdit" */ "./channels/edit/EditChannels")
);

export const ChannelsEditLegacy = createLoadable(
  () => import(/* webpackChunkName: "ChannelsEditLegacy" */ "./channels/ChannelsEditLegacy")
);

export const Billing = createLoadable(() => import(/* webpackChunkName: "Billing" */ "./billing/Billing"));

export const NewCredit = createLoadable(
  () => import(/* webpackChunkName: "NewCredit" */ "./billing/Credit/NewCreditForm/NewCredit")
);

export const InboundList = createLoadable(
  () => import(/* webpackChunkName: "InboundList" */ "./inbounds/list/InboundList")
);

export const InventoryList = createLoadable(
  () => import(/* webpackChunkName: "InventoryList" */ "./inventory/list/InventoryList")
);

export const InventoryDetail = createLoadable(
  () => import(/* webpackChunkName: "InventoryDetail" */ "./inventory/detail/InventoryDetail")
);

export const BundleDetail = createLoadable(
  () => import(/* webpackChunkName: "BundleDetail" */ "./inventory/BundleDetail")
);

export const AddComponents = createLoadable(
  () => import(/* webpackChunkName: "AddComponents" */ "./inventory/AddComponents")
);

export const NewProduct = createLoadable(() => import(/* webpackChunkName: "NewProduct" */ "./inventory/NewProduct"));

export const NewKitOrBundle = createLoadable(
  () => import(/* webpackChunkName: "NewKitOrBundle" */ "./inventory/NewKitOrBundle")
);

export const Reports = createLoadable(() => import(/* webpackChunkName: "Reports" */ "./report/Reports"));

export const ReturnsCreate = createLoadable(
  () => import(/* webpackChunkName: "ReturnsCreate" */ "./returns/ReturnsCreate")
);

export const ReturnsPolicyPage = createLoadable(
  () => import(/* webpackChunkName: "ReturnsPolicyPage" */ "./returns/policy/ReturnsPolicyPage")
);

export const StorageInboundDetail = createLoadable(
  () => import(/* webpackChunkName: "StorageInboundDetail" */ "./storage/inbounds/detail/StorageInboundDetail")
);

export const ReturnsLabel = createLoadable(
  () => import(/* webpackChunkName: "ReturnsLabel" */ "./returns/ReturnsLabel")
);

export const ReturnsDetail = createLoadable(
  () => import(/* webpackChunkName: "ReturnsDetail" */ "./returns/detail/ReturnsDetail")
);

export const ReturnsListPage = createLoadable(
  () => import(/* webpackChunkName: "ReturnsListPage" */ "./returns/returnsList/ReturnsListPage")
);

export const TransferDetails = createLoadable(() =>
  import(/* webpackChunkName: "TransferWithShipmentsDetail" */ "./transfers/detail").then(
    (exports) => exports.TransferDetails
  )
);

export const CreateTransfer = createLoadable(() =>
  import(/* webpackChunkName: "CreateTransfer" */ "./transfers/create").then((exports) => exports.CreateTransfer)
);

export const TransfersList = createLoadable(() =>
  import(/* webpackChunkName: "TransfersList" */ "./transfers/list").then((exports) => exports.TransfersListRedirect)
);

export const WholesaleDetail = createLoadable(() =>
  import(/* webpackChunkName: "WholesaleOrderDetail" */ "./wholesale/detail").then(
    (exports) => exports.WholesaleOrderDetail
  )
);

export const CreateWholesaleOrder = createLoadable(() =>
  import(/* webpackChunkName: "CreateWholesaleOrder" */ "./wholesale/create").then(
    (exports) => exports.CreateWholesaleOrder
  )
);

export const WholesaleOrdersList = createLoadable(() =>
  import(/* webpackChunkName: "WholesaleOrdersListRedirect" */ "./wholesale/list").then(
    (exports) => exports.WholesaleListRedirect
  )
);

export const WholesaleOrdersBulk = createLoadable(() =>
  import(/* webpackChunkName: "WholesaleOrdersBulk" */ "./wholesale/bulk").then(
    (exports) => exports.WholesaleOrdersBulk
  )
);

export const WholesaleOrdersBulkJob = createLoadable(() =>
  import(/* webpackChunkName: "WholesaleOrdersBulk" */ "./wholesale/bulk").then(
    (exports) => exports.WholesaleOrdersBulkJob
  )
);

export const WholesaleOrdersBulkJobsList = createLoadable(() =>
  import(/* webpackChunkName: "WholesaleOrdersBulk" */ "./wholesale/bulk").then(
    (exports) => exports.WholesaleOrdersBulkJobsList
  )
);

export const WholesaleSpsIntegration = createLoadable(() =>
  import(/* webpackChunkName: "WholsaleOrdersList" */ "./wholesale/list").then(
    (exports) => exports.WholesaleSpsIntegration
  )
);

export const Settings = createLoadable(() => import(/* webpackChunkName: "Settings" */ "./settings/Settings"));

export const FlexportPlus = createLoadable(
  () => import(/* webpackChunkName: "FlexportPlus" */ "./settings/accountInfo/FlexportPlus/FlexportPlus")
);

export const FastTags = createLoadable(() => import(/* webpackChunkName: "FastTags" */ "./tags/FastTags"));

export const FastTagDetail = createLoadable(
  () => import(/* webpackChunkName: "FastTagDetail" */ "./tags/detail/FastTagDetail")
);

export const FastTagConnect = createLoadable(
  () => import(/* webpackChunkName: "FastTagConnect" */ "./tags/connect/FastTagConnect")
);

export const ShopifyApp = createLoadable(() => import(/* webpackChunkName: "ShopifyApp" */ "./shopifyApp/ShopifyApp"));

export const Dashboard = createLoadable(() => import(/* webpackChunkName: "Dashboard" */ "./dashboard/Dashboard"));

export const FreightQuoteTool = createLoadable(
  () => import(/* webpackChunkName: "FreightQuoteTool" */ "./FreightQuote/FreightQuoteTool/FreightQuoteTool")
);

export const AuthenticatedFreightQuoteSummary = createLoadable(() =>
  import(
    /* webpackChunkName: "AuthenticatedFreightQuoteSummary" */ "./FreightQuote/FreightQuoteSummary/AuthenticatedFreightQuoteSummary"
  ).then((exports) => exports.AuthenticatedFreightQuoteSummary)
);

export const UnauthenticatedFreightQuoteSummary = createLoadable(() =>
  import(
    /* webpackChunkName: "UnauthenticatedFreightQuoteSummary" */ "./FreightQuote/FreightQuoteSummary/UnauthenticatedFreightQuoteSummary"
  ).then((exports) => exports.UnauthenticatedFreightQuoteSummary)
);

export const ParcelDashboard = createLoadable(
  () => import(/* webpackChunkName: "ParcelDashboard" */ "parcel/screens/integrations/dashboard/Dashboard")
);
export const ParcelList = createLoadable(
  () => import(/* webpackChunkName: "ParcelListTable" */ "parcel/screens/list/ParcelList")
);
export const ParcelDetails = createLoadable(
  () => import(/* webpackChunkName: "ParcelDetailsPage" */ "./parcel/screens/details/ParcelDetails")
);
export const ParcelClaim = createLoadable(
  () => import(/* webpackChunkName: "ParcelClaimPage" */ "./parcel/screens/claims/ParcelClaim")
);

export const Financing = createLoadable(() => import(/* webpackChunkName: "Financing" */ "./financing/Financing"));

export const FinancingSettings = createLoadable(
  () => import(/* webpackChunkName: "FinancingSettings" */ "./financing/FinancingSettings")
);

export const FinancingRequestForm = createLoadable(
  () => import(/* webpackChunkName: "FinancingRequestForm" */ "./financing/FinancingRequestForm")
);
