import { InboundClient, SellerProblemsViewClient } from "@deliverr/legacy-inbound-client";
import { NonComplianceClient, NonComplianceClientConfig } from "@deliverr/commons-clients";
import { PrepClient, PrepClientConfig } from "@deliverr/prep-service-client";
import { ProductClient, ProductClientConfig } from "@deliverr/product-client";
import { ReplenishmentClient, ReplenishmentClientConfig } from "@deliverr/replenishment-client";

import { AddressClient } from "@deliverr/address-client";
import { AuthEngine } from "@deliverr/commons-objects/lib/Auth";
import { BillingClient } from "@deliverr/billing-client";
import { CarrierNetworkClient } from "@deliverr/carrier-network-client";
import { ClaimClient } from "@deliverr/claim-client/lib/packages/claim-client/src";
import { FreightClient } from "@deliverr/freight-client";
import { FulfillmentOrderClient } from "@deliverr/fulfillment-order-client";
import { InternationalClient } from "@deliverr/international-client";
import { LotTrackingFefoClient } from "@deliverr/lot-tracking-fefo-client";
import { ParcelClient } from "@deliverr/parcel-client";
import { PricerClient } from "@deliverr/billing-pricer-client";
import { ReturnsClient } from "@deliverr/returns-client";
import { WarehousePublicClient } from "@deliverr/warehouse-client";
import { WholesaleBulkClient } from "@deliverr/wholesale-bulk-client";
import { WholesaleClient } from "@deliverr/wholesale-client";
import { WholesaleClientConfig } from "@deliverr/wholesale-client/.build/src/WholesaleClient";

const isDebugServer = typeof window !== "undefined" && Boolean((window as any).webpackHotUpdate);
const shouldDisableCORS = process.env.DISABLE_CORS === "true";
const inboundBaseURL =
  !isDebugServer && shouldDisableCORS
    ? `${window.location.origin}/api/inbound`
    : process.env.INBOUND_SERVICE_URL
    ? process.env.INBOUND_SERVICE_URL
    : "";

const inboundConfig = {
  timeout: 1000 * 60 * 5,
  baseURL: inboundBaseURL,
  // TODO(pgao1): delete "as any" after inbound servce update their commons-objects package.
  authEngine: AuthEngine.COGNITO as any,
};

const prepConfig: PrepClientConfig = {
  baseURL: process.env.PREP_SERVICE_API_URL,
  authEngine: AuthEngine.COGNITO,
};

const replenishmentConfig: ReplenishmentClientConfig = {
  baseURL: process.env.REPLENISHMENT_SERVICE_URL,
  authEngine: AuthEngine.COGNITO,
};

const wholesaleConfig: WholesaleClientConfig = {
  baseURL: process.env.WHOLESALE_API_URL,
  authEngine: AuthEngine.COGNITO,
};

const nonComplianceConfig: NonComplianceClientConfig = {
  baseURL: process.env.NON_COMPLIANCE_SERVICE_URL,
  authEngine: AuthEngine.COGNITO,
};

const productClientConfig: ProductClientConfig = {
  baseURL: process.env.PRODUCT_SERVICE_URL,
  authEngine: AuthEngine.COGNITO,
};

export const prepClient = new PrepClient(prepConfig);
export const inboundClient = new InboundClient(inboundConfig);
export const sellerProblemsViewClient = new SellerProblemsViewClient(inboundConfig);
export const nonComplianceClient = new NonComplianceClient(nonComplianceConfig);
export const productClient = new ProductClient(productClientConfig);
export const replenishmentClient = new ReplenishmentClient(replenishmentConfig);

export const carrierNetworkClient = new CarrierNetworkClient(
  {
    baseURL: process.env.CARRIER_NETWORK_SERVICE_URL,
    authEngine: AuthEngine.COGNITO,
  },
  {
    directCallerServiceName: "sellerPortal",
    deploymentEnv: process.env.ENV,
  }
);
export const warehouseClient = new WarehousePublicClient({ baseURL: process.env.WAREHOUSE_SERVICE_URL });
export const wholesaleClient = new WholesaleClient(wholesaleConfig);
export const wholesaleBulkClient = new WholesaleBulkClient({ baseURL: process.env.WHOLESALE_BULK_API_URL });
export const freightClient = new FreightClient({
  baseURL: process.env.FREIGHT_SERVICE_URL,
  authEngine: AuthEngine.COGNITO,
} as ConstructorParameters<typeof FreightClient>[0]);
export const lotTrackingFefoClient = new LotTrackingFefoClient({
  baseURL: process.env.LOT_TRACKING_FEFO_CLIENT_URL,
  authEngine: AuthEngine.COGNITO,
});
export const internationalClient = new InternationalClient({
  baseURL: process.env.INTERNATIONAL_BASE_URL,
  authEngine: AuthEngine.COGNITO,
});
export const fulfillmentOrderClient = new FulfillmentOrderClient({
  baseURL: process.env.FULFILLMENT_ORDER_SERVICE_URL,
});
export const addressClient = new AddressClient({
  baseURL: process.env.ADDRESS_SERVICE_BASE_URL,
});
export const parcelClient = new ParcelClient({
  baseURL: process.env.PARCEL_SERVICE_URL,
  authEngine: AuthEngine.COGNITO,
} as ConstructorParameters<typeof ParcelClient>[0]);
export const returnsClient = new ReturnsClient({
  baseURL: process.env.RETURNS_SERVICE_URL,
  authEngine: AuthEngine.COGNITO,
});
export const billingClientV2 = new BillingClient({
  baseURL: process.env.BILLING_SERVICE_URL,
  authEngine: AuthEngine.COGNITO,
});

export const pricerClient = new PricerClient({
  baseURL: process.env.PRICER_SERVICE_URL,
  authEngine: AuthEngine.COGNITO,
});

export const claimClient = new ClaimClient({
  baseURL: process.env.CLAIMS_SERVICE_URL,
  authEngine: AuthEngine.COGNITO,
});
