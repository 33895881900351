import { SelectedProductData } from "common/components/ProductChooser/SelectedProductData";
import { isEmpty, isInteger, some } from "lodash/fp";
import { batch, useDispatch, useSelector } from "react-redux";
import { CreateTransferLoader } from "transfers/create/CreateTransferLoader.types";
import { selectTransferSelectedProductsQty } from "transfers/create/store/selectTransferSelectedProductsQty";
import {
  transferCreateRemoveProduct,
  transferCreateSetIsEachPickingOrderFlag,
  transferCreateUpdateProductCaseQty,
  transferCreateUpdateProductLotNumber,
  transferCreateUpdateProductNumberOfCases,
  transferCreateUpdateProductQty,
} from "transfers/create/actions";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";
import { useCallback, useEffect, useMemo, useState } from "react";
import { TransferCreationTypes } from "transfers/create/transferCreationTypes";
import { FeatureName, useFeatureOn } from "common/Split";
import { BoxInfoWithCount, estimatePallets } from "@deliverr/commons-utils";
import { selectAnyIsLoading } from "common/store/selectors/selectAnyIsLoading";
import { ATPDetails } from "common/clients/inventory/ATP/ATPDetails";

const MAX_PALLET_LIMIT = 26;

export const useTransferSelectProductQuantityStep = () => {
  const dispatch = useDispatch();
  const {
    originStorageWarehouse,
    errorType,
    destinationType,
    replenishmentError,
    selectedProductsLotNumbers,
    isEachPickingOrder,
  } = useSelector(selectTransferCreate);

  const selectedProductsLotNumbersList = originStorageWarehouse?.dskuQuantities?.reduce<{
    [dsku: string]: ATPDetails[] | undefined;
  }>((acc, { dsku, atpDetails }) => {
    acc[dsku] = atpDetails ?? [];
    return acc;
  }, {});

  const selectedProductsQty = useSelector(selectTransferSelectedProductsQty);
  const [isPalletLimitReached, setIsPalletLimitReached] = useState(false);
  const [currentPalletCount, setCurrentPalletCount] = useState(0);
  const isNextDisabled =
    !selectedProductsQty.length ||
    some((selectedProduct: SelectedProductData) => !selectedProduct.qty, selectedProductsQty) ||
    isPalletLimitReached;
  const isLoading = useSelector(selectAnyIsLoading)(CreateTransferLoader.FetchStorageToEcomRatesLoader);
  const isEcomTransfer = useMemo(() => destinationType === TransferCreationTypes.Ecom, [destinationType]);
  const isMultiCasePackEnabled = useFeatureOn(FeatureName.StorageMultiCasePack);
  const isWholesaleOrderEachPickingOn = useFeatureOn(FeatureName.WholesaleOrderEachPicking);
  const isEachPickingEnabled = destinationType === TransferCreationTypes.Wholesale && isWholesaleOrderEachPickingOn;

  const updateNumberOfCases = useCallback(
    (dsku: string, numberOfCases: number) => {
      return dispatch(transferCreateUpdateProductNumberOfCases(dsku, isInteger(numberOfCases) ? numberOfCases : 0));
    },
    [dispatch]
  );

  const updateQty = useCallback(
    (dsku: string, totalQuantity: number) => {
      return dispatch(transferCreateUpdateProductQty(dsku, isInteger(totalQuantity) ? totalQuantity : 0));
    },
    [dispatch]
  );

  const handleEachPickingToggle = () => {
    dispatch(transferCreateSetIsEachPickingOrderFlag(!isEachPickingOrder));
  };

  const updateCaseQty = useCallback(
    (dsku: string, caseQty: number) => {
      if (isEcomTransfer) {
        dispatch(transferCreateUpdateProductCaseQty(dsku, caseQty));
      }
    },
    [dispatch, isEcomTransfer]
  );

  const updateLotNumber = useCallback(
    (dsku: string, lotNumber?: string) => {
      dispatch(
        transferCreateUpdateProductLotNumber(
          dsku,
          isEmpty(lotNumber)
            ? undefined
            : selectedProductsLotNumbersList?.[dsku]?.find((lot) => lot.lotNumber === lotNumber)
        )
      );
      return updateNumberOfCases(dsku, 0);
    },
    [dispatch, selectedProductsLotNumbersList, updateNumberOfCases]
  );

  const removeProduct = (dsku: string) => {
    return dispatch(transferCreateRemoveProduct(dsku));
  };

  useEffect(() => {
    if (selectedProductsQty.length > 0) {
      const boxInfoWithCount: BoxInfoWithCount[] = selectedProductsQty.map((selectedProduct) => {
        return {
          qty: selectedProduct.qty / selectedProduct.caseQty!,
          length: selectedProduct.length!,
          width: selectedProduct.width!,
          weight: selectedProduct.weight!,
          height: selectedProduct.height!,
          lengthUnit: selectedProduct.lengthUnit!,
          weightUnit: selectedProduct.weightUnit!,
        };
      });
      if (isEcomTransfer) {
        const numOfPalletsRequired = estimatePallets(boxInfoWithCount);
        setIsPalletLimitReached(numOfPalletsRequired > MAX_PALLET_LIMIT && isEcomTransfer);
        setCurrentPalletCount(numOfPalletsRequired);
      }
    } else {
      setIsPalletLimitReached(false);
      setCurrentPalletCount(0);
    }
  }, [isEcomTransfer, selectedProductsQty]);

  useEffect(() => {
    batch(() => {
      selectedProductsQty.forEach((selectedProduct) => {
        if (selectedProduct.qty === undefined) {
          // set initial number of cases to maximum available
          const caseQty = selectedProduct.caseQty ?? 1;
          const availableQty = selectedProduct.storageOnHandQty ?? 0;
          const maxNumberOfCases = Math.floor(availableQty / caseQty);
          updateCaseQty(selectedProduct.dsku, caseQty);
          updateNumberOfCases(selectedProduct.dsku, maxNumberOfCases);
        }
      });
    });
  }, [selectedProductsQty, destinationType, updateCaseQty, updateNumberOfCases]);

  return {
    isEcomTransfer,
    selectedProductsQty,
    originStorageWarehouse,
    updateNumberOfCases,
    isNextDisabled,
    isLoading,
    errorType,
    destinationType,
    updateCaseQty,
    removeProduct,
    isMultiCasePackEnabled,
    replenishmentError,
    isPalletLimitReached,
    currentPalletCount,
    selectedProductsLotNumbers,
    updateLotNumber,
    selectedProductsLotNumbersList,
    isEachPickingEnabled,
    isEachPickingOrder,
    handleEachPickingToggle,
    updateQty,
  };
};
