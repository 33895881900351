import { ContainerSize, PalletSize, ShipmentType } from "@deliverr/storage-client";
import { getStorageInboundRates } from "./getStorageInboundRates";

export const getStorageInboundCostByShipmentType = (
  cargoType: ShipmentType,
  numOfPallets = 1,
  containerSize = ContainerSize.GENERAL_40FT
): number => {
  const { containerUnloadingFeeBySize, palletInboundFeeBySize, storageFeePerMonth } = getStorageInboundRates();
  return (
    storageFeePerMonth * numOfPallets +
    (cargoType === ShipmentType.PALLETIZED
      ? palletInboundFeeBySize[PalletSize.STANDARD_60IN]?.fee ?? 0 * numOfPallets
      : containerUnloadingFeeBySize[containerSize]?.fee ?? 0)
  );
};
