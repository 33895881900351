import { replenishmentClient } from "Clients";
import { OrderResponse } from "@deliverr/replenishment-client";

export const getReplenishmentOrder = async (orderId: string): Promise<OrderResponse> => {
  const response = (
    await replenishmentClient.getOrder(orderId, {
      includeShipments: true,
      includeShipmentTrackings: true,
      includeInvalidOrderItems: true,
      includeNonCompliances: true,
    })
  ).value;
  return response;
};
