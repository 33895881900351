import React from "react";
import { ReturnsCancel } from "./ReturnsCancel";
import { Card, DefaultTheme, Stack, Title } from "common/components/ui";
import { ReturnsCost } from "returns/cost/ReturnsCost";
import { ShareReturnLabelButton } from "./ShareReturnLabelButton";
import styled from "@emotion/styled";
import { useReturnsDetailHeader } from "./useReturnsDetailHeader";
import { getEstimatedLabelCost } from "../../utils/returnsCost";

const returnPadding = "40px";
const returnTitleSize = "28px";

const Container = styled(Card)<{}, DefaultTheme>(
  ({ theme }) => `
  padding: ${returnPadding};
  display: flex;
  justify-content: space-between;
  border-bottom: ${theme.border.width.B1} ${theme.border.type} ${theme.colors.NEUTRAL[80]};
`
);

const ReturnTitle = styled(Title)`
  margin: 0;
  font-size: ${returnTitleSize};
`;

const Details = styled.p`
  margin-bottom: 0;
`;

export const ReturnsDetailHeader = () => {
  const { title, returnsDetail } = useReturnsDetailHeader();
  const estimatedLabelCost = getEstimatedLabelCost(returnsDetail.items, returnsDetail.estimatedTotalCost);
  return (
    <Container>
      <div>
        <ReturnTitle as="h2" displayAs="h1">
          {title}
        </ReturnTitle>
        <Details>
          <ReturnsCost
            inspectionFeeCharged={returnsDetail.inspectionFeeCharged}
            estimatedTotalCost={returnsDetail.estimatedTotalCost}
            shippingFeeCharged={returnsDetail.shippingFeeCharged}
            qcId={returnsDetail.qcId}
            rmtId={returnsDetail.rmtId}
            returnItems={returnsDetail.items}
            estimatedLabelCost={estimatedLabelCost}
          />
        </Details>
      </div>
      <Stack direction="HORIZONTAL">
        <ReturnsCancel />
        {returnsDetail.packages?.[0]?.label?.labelUrl && <ShareReturnLabelButton />}
      </Stack>
    </Container>
  );
};
