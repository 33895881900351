import React, { FC } from "react";
import { OptionProps as SelectOptionProps } from "@deliverr/react-select-v1";
import { Box, Select } from "common/components/ui";
import { FormattedMessage } from "common/components/ui/FormattedMessage";
import { wholesaleCreateLabels } from "wholesale/create/wholesaleCreate.labels";
import { CarrierAccount } from "@deliverr/replenishment-client";
import { useCarrierAccountSelect } from "../../hooks/useCarrierAccountSelect";
import { CarrierAccountSelectOption } from "./CarrierAccountSelectOption";
import { CarrierAccountSelectDetails } from "./CarrierAccountSelectDetails";
import { CarrierAccountModalMode } from "../modals/CarrierAccountModal";

interface CarrierAccountSelectProps {
  carrierAccounts: CarrierAccount[];
  selectedCarrierAccount: CarrierAccount | undefined;
  onOpenModal: (modalMode: CarrierAccountModalMode, carrierAccount?: CarrierAccount) => void;
}

export const CarrierAccountSelectLabel: FC<{ carrierAccount: CarrierAccount }> = ({ carrierAccount }) => (
  <Box paddingTop="S1" paddingBottom="S1">
    <CarrierAccountSelectDetails carrierAccount={carrierAccount} />
  </Box>
);

const formatOptionLabel = (option) => <CarrierAccountSelectLabel carrierAccount={option.value} />;

const customComponents = (
  onOpenModal: (modalMode: CarrierAccountModalMode, carrierAccount?: CarrierAccount) => void
) => ({
  Option: (props: SelectOptionProps) => <CarrierAccountSelectOption {...props} onOpenModal={onOpenModal} />,
});

export const CarrierAccountSelect: FC<CarrierAccountSelectProps> = ({
  carrierAccounts,
  selectedCarrierAccount,
  onOpenModal,
}) => {
  const { options, value, onChange } = useCarrierAccountSelect(carrierAccounts, selectedCarrierAccount);

  return (
    <Box marginTop="S1" data-testid="carrier-account-select">
      <Select
        flexWidth
        placeholder={<FormattedMessage {...wholesaleCreateLabels.steps.shipping.labelProvider.selectCarrier} />}
        maxMenuHeight={200}
        options={options}
        value={value}
        formatOptionLabel={formatOptionLabel}
        components={customComponents(onOpenModal)}
        onChange={onChange}
      />
    </Box>
  );
};
