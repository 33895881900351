export enum OrderExceptions {
  NOT_FOUND = "NOT_FOUND",
  STOCKOUT = "STOCKOUT",
  INVALID_ADDRESS = "INVALID_ADDRESS",
  INVALID_ORDER = "INVALID_ORDER",
  DUPLICATE_ORDER = "DUPLICATE_ORDER",
  UNKNOWN_SKU = "UNKNOWN_SKU",
  CANNOT_MEET_GUARANTEE = "CANNOT_MEET_GUARANTEE",
  INVALID_ORDER_TRACKING_ID = "INVALID_ORDER_TRACKING_ID",
  INVALID_QUERY_PARAMETER = "INVALID_QUERY_PARAMETER",
  ORDER_SHIPPED = "ORDER_SHIPPED",
  FAILED_CANCEL_AT_WAREHOUSE = "FAILED_CANCEL_AT_WAREHOUSE",
}
