import produce from "immer";
import { merge } from "lodash/fp";
import { Product } from "@deliverr/commons-clients";
import { OpenShipment } from "@deliverr/legacy-inbound-client";
import { CountryCode, DeliverrAddress } from "@deliverr/commons-objects";
import { KitInstructionResponse } from "@deliverr/commons-clients/lib/product/KitInstructionResponse";

import { CostEstimatorModalActionTypes } from "common/components/EstimatedCosts/CostEstimatorModalActions";
import { createReducer, handleSimpleReducerUpdates, ReducerDictionary } from "common/ReduxUtils";
import { InventoryDetailActionTypes } from "./InventoryDetailActionTypes";
import { ServiceLevel, ItemizedPrice } from "@deliverr/billing-pricer-client";

export interface InventoryDetailState {
  product?: Product;
  feePreview: number;
  currency: string;
  inventoryByWarehouse: Array<{
    name: string;
    address: DeliverrAddress;
    onHandQty: number;
    lng?: number;
    lat?: number;
  }>;
  estimatedCosts?: ItemizedPrice;
  warehouseMarkers: Array<{ coordinates: [number, number]; units: number }>;
  incomingShipments: OpenShipment[];
  selectedQty: number;
  serviceLevel: ServiceLevel;
  countryCode: CountryCode;
  kitInstructions?: KitInstructionResponse;
  kitInstructionsLoading?: boolean;
}

export const inventoryDetailInitialState: InventoryDetailState = {
  currency: "",
  feePreview: 0,
  inventoryByWarehouse: [],
  warehouseMarkers: [],
  incomingShipments: [],
  selectedQty: 1,
  serviceLevel: ServiceLevel.StandardNonFastTag,
  countryCode: "CA",
};

const reducers: ReducerDictionary<InventoryDetailState> = {
  ...handleSimpleReducerUpdates([
    InventoryDetailActionTypes.GET_PRODUCT_DETAIL_SUCCESS,
    InventoryDetailActionTypes.GET_INVENTORY_BY_WAREHOUSE_SUCCESS,
    InventoryDetailActionTypes.UPDATE_ESTIMATED_COSTS,
    InventoryDetailActionTypes.SET_SELECTED_UNITS,
    InventoryDetailActionTypes.SET_SELECTED_SERVICE_LEVEL,
    InventoryDetailActionTypes.SET_SELECTED_COUNTRY_CODE,
    InventoryDetailActionTypes.SET_KIT_INSTRUCTIONS,
    InventoryDetailActionTypes.KIT_INSTRUCTIONS_LOADING,
  ]),
  [InventoryDetailActionTypes.GET_INVENTORY_BY_WAREHOUSE_START]: (state) => ({
    ...state,
    inventoryByWarehouse: [],
  }),
  [CostEstimatorModalActionTypes.ESTIMATE_COSTS_DETAIL]: (state, { dimensions, estimatedCosts }) => ({
    ...state,
    product: {
      ...state.product,
      ...dimensions,
    },
    estimatedCosts,
  }),
  [InventoryDetailActionTypes.UPDATE_MSKU_SUCCESS]: (state, { product, msku }) => ({
    ...state,
    product: {
      // Atm msku is readonly and cannot use ...state.product a change has been requested
      ...product,
      msku,
    },
  }),
  [InventoryDetailActionTypes.UPDATE_IS_FEFO_ENABLED]: (state, { isFefoEnabled }) => {
    if (!state.product) {
      return state;
    }
    return {
      ...state,
      product: {
        ...state.product,
        isFefoEnabled,
      },
    };
  },
  [InventoryDetailActionTypes.UPDATE_IS_RECALL_MANAGEMENT_ENABLED]: (state, { isRecallManagementEnabled }) => {
    if (!state.product) {
      return state;
    }
    return {
      ...state,
      product: {
        ...state.product,
        isLotTrackingEnabled: isRecallManagementEnabled,
      },
    };
  },
  [InventoryDetailActionTypes.CREATE_WAREHOUSE_MARKERS]: (state, { warehouseMarkers }) => ({
    ...state,
    warehouseMarkers,
  }),
  [InventoryDetailActionTypes.RESET_INVENTORY_DETAIL]: () => inventoryDetailInitialState,
  [InventoryDetailActionTypes.UPDATE_HAZMAT_SUCCESS]: (state, { hazmatInformation }) =>
    produce(state, (draft) => {
      draft.product!.hazmatInformation = merge(draft.product?.hazmatInformation, hazmatInformation);
    }),
  [InventoryDetailActionTypes.SET_KIT_TEXT_INSTRUCTIONS]: (state, { kitTextInstructions }) =>
    produce(state, (draft) => {
      if (!draft.kitInstructions) {
        draft.kitInstructions = {
          textInstruction: "",
          files: {},
        };
      }
      draft.kitInstructions!.textInstruction = kitTextInstructions;
    }),
};

export const inventoryDetailReducer = createReducer<InventoryDetailState>(inventoryDetailInitialState, reducers);
