import React from "react";
import {
  Box,
  BoxReset,
  Text,
  Stack,
  Checkbox,
  FormGroup,
  Input,
  ThemeProps,
  Title,
  PhoneNumberInput,
} from "common/components/ui";
import styled from "@emotion/styled";
import { Controller, FormProvider } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { AnyAction } from "redux";

import { isHolidayOrWeekend } from "common/date/isHolidayOrWeekend";
import { DatePicker } from "common/components/DatePicker";

// stylesheets needed for react-timepicker component
import "react-datepicker/dist/react-datepicker.css";
import { useStorageAddPickupDetails } from "./useStorageAddPickupDetails";
import { EARLIEST_PICKUP_DATE, LATEST_PICKUP_DATE } from "freight/constants/cargoPickupConstants";
import { FacilityOperatingHours } from "common/components/FacilityOperatingHours/FacilityOperatingHours";
import { StyledCard } from "common/components/StepContainer";
import { InboundFooter } from "inbounds/steps/InboundFooter";
import { TextAlign } from "common/components/ui/shared";
import { TimeWindow } from "@deliverr/commons-objects";
import { addLocalTimeZoneOffset } from "common/date/addLocalTimeZoneOffset";
import { useSPDispatch } from "common/ReduxUtils";

const Container = styled.div`
  flex: 1;
`;

const Inputs = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const FormSection = styled.div<ThemeProps>(
  ({ theme }) => `
  display: flex;
  width: 20rem;

  &:first-of-type {
    margin-right: ${theme.spacing.S5};
  }

   > :first-of-type {
    width: 100%;
  }
`
);

const StyledText = styled(Text)<ThemeProps>(
  ({ theme }) => `
  color: ${theme.colors.NEUTRAL[500]};
`
);

const FormRow = styled.div<ThemeProps>(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  margin-bottom: ${theme.spacing.S4};
`
);

const Picker = styled(DatePicker)<ThemeProps>(
  ({ theme }) => `
  background: ${theme.colors.NEUTRAL["00"]};
  border: ${theme.border.width.B1} ${theme.colors.NEUTRAL[80]} ${theme.border.type};
  border-radius: ${theme.border.radius.R2};
  height: ${theme.spacing.S7};
  width: 100%;
`
);

export const StorageAddPickupDetails: React.FC = () => {
  const {
    handleFacilityOperatingHoursInputProps,
    phoneNumberInputProps,
    firstNameInputProps,
    lastNameInputProps,
    emailAddressInputProps,
    formHandler,
    onNextClick,
    onPreviousClick,
    isNextDisabled,
    pickupDateInputProps,
    customerReferenceNumberInputProps,
    liftgateRequiredCheckboxProps,
  } = useStorageAddPickupDetails();

  const dispatch = useSPDispatch();

  return (
    <Box width="62.5rem">
      <StyledCard>
        <BoxReset marginBottom="S7" width="100%">
          <Stack>
            <BoxReset marginBottom={"S3"}>
              <Title textAlign={TextAlign.center} displayAs="h4">
                <FormattedMessage
                  id="storage.inbounds.create.confirmShipment.title"
                  defaultMessage="Add booking details"
                />
              </Title>
            </BoxReset>

            <FormProvider {...formHandler}>
              <Container>
                <Inputs>
                  <FormRow>
                    <BoxReset marginBottom={"S1"}>
                      <Text as="header" bold>
                        <FormattedMessage
                          defaultMessage="Add freight pickup details"
                          id="storage.inbounds.create.freight.pickup.decription"
                        />
                      </Text>
                    </BoxReset>
                  </FormRow>
                  <FormRow>
                    <FormSection>
                      <FormGroup
                        label={pickupDateInputProps.label}
                        helpText={
                          <FormattedMessage
                            defaultMessage="Business days only"
                            id="storage.inbounds.create.freight.pickupDate.helpText"
                          />
                        }
                      >
                        <Controller
                          {...pickupDateInputProps}
                          render={({ field }) => (
                            <Picker
                              hasError={pickupDateInputProps.hasError}
                              date={field.value ? addLocalTimeZoneOffset(field.value) : undefined}
                              getIsDateDisabled={isHolidayOrWeekend}
                              minDate={EARLIEST_PICKUP_DATE}
                              maxDate={LATEST_PICKUP_DATE}
                              onDateChange={field.onChange}
                              nextYearButton={null}
                              prevYearButton={null}
                            />
                          )}
                        />
                      </FormGroup>
                    </FormSection>
                    <FormSection>
                      <Controller
                        {...handleFacilityOperatingHoursInputProps}
                        render={({ field }) => (
                          <FacilityOperatingHours
                            hasMinRange={handleFacilityOperatingHoursInputProps.hasMinRange}
                            facilityOperatingHours={field.value}
                            updateHours={(time: TimeWindow) => dispatch(field.onChange(time) as unknown as AnyAction)}
                          />
                        )}
                      />
                    </FormSection>
                  </FormRow>
                  <FormRow>
                    <FormSection>
                      <FormGroup>
                        <Input {...customerReferenceNumberInputProps} />
                      </FormGroup>
                    </FormSection>
                  </FormRow>
                  <FormRow>
                    <FormGroup>
                      <Controller
                        {...liftgateRequiredCheckboxProps}
                        render={({ field }) => (
                          <Checkbox
                            label={<StyledText>{liftgateRequiredCheckboxProps.label}</StyledText>}
                            checked={field.value}
                            onChange={() => {
                              field.onChange(!field.value);
                            }}
                          />
                        )}
                      />
                    </FormGroup>
                  </FormRow>
                  <FormRow>
                    <BoxReset marginBottom={"S2"} marginTop={"S1"}>
                      <Text as="header" bold>
                        <FormattedMessage
                          defaultMessage="Add freight scheduling contact details"
                          id="storage.inbounds.create.freight.pickup.contact.decription"
                        />
                      </Text>
                    </BoxReset>
                  </FormRow>
                  <FormRow>
                    <FormSection>
                      <FormGroup>
                        <Input {...firstNameInputProps} />
                      </FormGroup>
                    </FormSection>
                    <FormSection>
                      <FormGroup>
                        <Input {...lastNameInputProps} />
                      </FormGroup>
                    </FormSection>
                  </FormRow>
                  <FormRow>
                    <FormSection>
                      <Controller
                        {...phoneNumberInputProps}
                        render={({ field }) => (
                          <PhoneNumberInput
                            hasError={phoneNumberInputProps.hasError}
                            errorMessage={phoneNumberInputProps.errorMessage}
                            initialCountry={phoneNumberInputProps.initialCountry}
                            label={phoneNumberInputProps.label}
                            value={field.value}
                            onChange={field.onChange}
                          />
                        )}
                      />
                    </FormSection>
                    <FormSection>
                      <FormGroup>
                        <Input {...emailAddressInputProps} />
                      </FormGroup>
                    </FormSection>
                  </FormRow>
                </Inputs>
              </Container>
            </FormProvider>
          </Stack>
        </BoxReset>
      </StyledCard>
      <InboundFooter
        onButtonClick={onNextClick}
        onPreviousButtonClick={onPreviousClick}
        disabled={isNextDisabled}
        manualLoader
      />
    </Box>
  );
};
