import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { ManagedStep, useStepManager } from "common/components/StepContainer";
import { SimplePrepPage } from "prep/create/SimplePrepPage/SimplePrepPage";
import { PrepCreationSource } from "prep/types/PrepCreationSource";
import { transfersShipmentCreateLabels } from "transfers/create/transfersShipmentCreate.labels";
import { transferCreateSetPrepType } from "transfers/create/actions";
import { PrepOption } from "@deliverr/business-types";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";
import { SimpleSellerSelfServePrepType } from "prep/types";

export const TransferSelectPrep = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { stepManager } = useStepManager();
  const { prepOption } = useSelector(selectTransferCreate);

  const handleSkipPrepClick = () => {
    dispatch(transferCreateSetPrepType(PrepOption.SHIPPING_ONLY));
    stepManager.next();
  };

  return (
    <ManagedStep
      isNextDisabled={!prepOption}
      data-testid="transfers-select-prep"
      secondaryButton={formatMessage(transfersShipmentCreateLabels.steps.prepServices.skip)}
      onClickSecondary={handleSkipPrepClick}
      isSecondaryButtonDisabled={!!prepOption}
    >
      <SimplePrepPage
        source={PrepCreationSource.REPLENISHMENT}
        defaultSelectedPrepType={prepOption as SimpleSellerSelfServePrepType | undefined}
      />
    </ManagedStep>
  );
};
