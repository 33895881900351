import React, { FC } from "react";
import { OptionProps as SelectOptionProps } from "@deliverr/react-select-v1";
import styled from "@emotion/styled";
import { DefaultTheme, SelectComponentOption } from "common/components/ui";
import { AddCarrierAccountButton } from "../AddCarrierAccountButton";
import { CarrierAccountSelectDetails } from "./CarrierAccountSelectDetails";
import { CarrierAccountSelectActions } from "./CarrierAccountSelectActions";
import { CarrierAccount } from "@deliverr/replenishment-client";
import { FlexDiv } from "common/components/FlexDiv";

const ButtonWrapper = styled.div<{ theme: DefaultTheme }>(
  ({ theme }) => `
    button {
      width: 100%;
      border: none;
      background-color: transparent;
      padding: ${theme.spacing.S4};
      margin: 0;
      cursor: pointer;
      border-bottom: ${theme.border.width.B1} solid ${theme.colors.NEUTRAL[80]};
      &:hover {
        background-color: ${theme.colors.NEUTRAL[60]};
      }
    }
  `
);

export const CarrierAccountSelectOption: FC<SelectOptionProps> = (props) => {
  const carrierAccount: CarrierAccount = props.data.value;
  return (
    <>
      {props.data.isAddCarrierAccountButton ? (
        <ButtonWrapper>
          <AddCarrierAccountButton onClick={props.onOpenModal} />
        </ButtonWrapper>
      ) : (
        <SelectComponentOption {...props}>
          <FlexDiv>
            <CarrierAccountSelectDetails carrierAccount={carrierAccount} />
            <CarrierAccountSelectActions onOpenModal={props.onOpenModal} carrierAccount={carrierAccount} />
          </FlexDiv>
        </SelectComponentOption>
      )}
    </>
  );
};
