import { BRANDED_PACKAGING_FILTER, MULTI_CASE_PACK_FILTER } from "./InventoryListActions";
import { FeatureName, isFeatureOn } from "common/Split";

import { InventoryListTab } from "./InventoryListReducer";
import { InventoryLocationFilterOptions } from "./InventoryListTableControls/InventoryListFilters/InventoryLocationFilter";
import { NumericFilter } from "common/list/AlgoliaService";
import { QueryDslQueryContainer } from "@deliverr/logistics-search-client/lib/src/entities/QueryDslQueryContainer";
import { constructOpenSearchFilters } from "common/search/services/InventorySearchUtils";

interface InventoryListFilterParams {
  searchTerm?: string;
  isActive: boolean;
  inventoryLocation?: InventoryLocationFilterOptions;
  currentTab?: InventoryListTab;
}

interface InventoryListFilters {
  filters: string[];
  numericFilter?: NumericFilter;
  customizedOpenSearchFilters: QueryDslQueryContainer[];
}

export const getInventoryListFilters = (params: InventoryListFilterParams): InventoryListFilters => {
  if (isFeatureOn(FeatureName.LogisticsSearchProductsReadEnabled)) {
    return getInventoryListOpenSearchFilters(params);
  }

  return getInventoryListAlgoliaFilters(params);
};

function getInventoryListAlgoliaFilters({
  isActive,
  inventoryLocation,
  currentTab,
}: InventoryListFilterParams): InventoryListFilters {
  const isActiveFilter = isActive ? "NOT isHiddenOnSellerPortal=1" : "isHiddenOnSellerPortal=1";
  const numericFilter = getNumericFilterByInventoryLocation(inventoryLocation);
  const filters = [isActiveFilter, BRANDED_PACKAGING_FILTER, MULTI_CASE_PACK_FILTER];

  if (isFeatureOn(FeatureName.PrepKittingEnabled) && (currentTab === "PRODUCTS" || currentTab === "KITS")) {
    filters.push(currentTab === "KITS" ? "isKit=1" : "NOT isKit=1");
  }

  const customizedOpenSearchFilters: QueryDslQueryContainer[] = [];
  if (currentTab === "ELIGIBLE_FOR_REMOVAL") {
    const removalFilters = getAlgoliaFiltersForRemoval();
    filters.push(...removalFilters);
  }

  if (inventoryLocation === InventoryLocationFilterOptions.RESERVE_STORAGE) {
    filters.push(`hasInventoryOnPacks:true`);
  }

  return { filters, numericFilter, customizedOpenSearchFilters };
}

function getNumericFilterByInventoryLocation(
  inventoryLocation: InventoryLocationFilterOptions | undefined
): NumericFilter | undefined {
  if (inventoryLocation === InventoryLocationFilterOptions.ECOM_FULFILLMENT) {
    return ["onHandQty", ">=", 1];
  } else if (inventoryLocation === InventoryLocationFilterOptions.AMAZON_FBA) {
    return ["fbaInventory.fulfillableQuantity", ">=", 1];
  } else if (inventoryLocation === InventoryLocationFilterOptions.WALMART_WFS) {
    return ["wfsInventory.fulfillableQuantity", ">=", 1];
  }

  return undefined;
}

function getInventoryListOpenSearchFilters({
  searchTerm,
  isActive,
  inventoryLocation,
  currentTab,
}: InventoryListFilterParams): InventoryListFilters {
  const customizedOpenSearchFilters = constructOpenSearchFilters({
    searchTerm,
    isHiddenOnSellerPortal: !isActive,
    inventoryLocation,
    currentTab,
  });

  const numericFilter = getNumericFilterByInventoryLocation(inventoryLocation);

  return { filters: [], numericFilter, customizedOpenSearchFilters };
}

function getAlgoliaFiltersForRemoval(): string[] {
  const VALID_DIMS_FILTER =
    "(dimSourceType:warehouse OR dimSourceType:manual OR dimSourceType:seller OR dimSourceType:averageregression OR dimSourceType:crossdock OR dimSourceType:amazonscraped)";
  const HAZMAT_INFORMATION_FILTER =
    "(NOT hazmatInformation.status:INCOMPLETE AND NOT hazmatInformation.status:PENDING AND NOT hazmatInformation.status:REJECTED)";

  return [VALID_DIMS_FILTER, HAZMAT_INFORMATION_FILTER];
}
