import { FormattedMessage, MessageDescriptor } from "react-intl";
import React, { FC } from "react";

import { ReturnStatus } from "@deliverr/returns-client";
import { Title } from "common/components/ui";
import styled from "@emotion/styled";
import { toStartCase } from "../../../utils/stringUtil";

const StyledTitle = styled(Title)`
  margin: 0;
`;

const createdMessage = {
  id: "returns.returnsDetail.statusTitle.created",
  defaultMessage: "Not shipped yet",
};

const carrierShippedMessage = {
  id: "returns.returnsDetail.statusTitle.carrierShipped",
  defaultMessage: "In Transit",
};

const carrierDeliveredMessage = {
  id: "returns.returnsDetail.statusTitle.carrierDelivered",
  defaultMessage: "Delivered",
};

const arrivedMessage = {
  id: "returns.returnsDetail.statusTitle.arrived",
  defaultMessage: "Inspecting",
};

const processedMessage = {
  id: "returns.returnsDetail.statusTitle.processed",
  defaultMessage: "Processed",
};

const cancelledMessage = {
  id: "returns.returnsDetail.statusTitle.cancelled",
  defaultMessage: "Canceled",
};

const closedMessage = {
  id: "returns.returnsDetail.statusTitle.closed",
  defaultMessage: "Closed",
};

const returnedToCustomerMessage = {
  id: "returns.returnsDetail.statusTitle.returnedToCustomer",
  defaultMessage: "Returned to customer",
};

export const returnTitles: Record<ReturnStatus, MessageDescriptor> = {
  [ReturnStatus.CREATED]: createdMessage,
  [ReturnStatus.CARRIER_SHIPPED]: carrierShippedMessage,
  [ReturnStatus.CARRIER_DELIVERED]: carrierDeliveredMessage,
  [ReturnStatus.ARRIVED]: arrivedMessage,
  [ReturnStatus.PROCESSED]: processedMessage,
  [ReturnStatus.RETURNED_TO_CUSTOMER]: returnedToCustomerMessage,
  [ReturnStatus.CANCELLED]: cancelledMessage,
  [ReturnStatus.CLOSED]: closedMessage,
  [ReturnStatus.AUTO_CANCELLED]: cancelledMessage,
};

export const ReturnStatusTitle: FC<{ status?: ReturnStatus }> = ({ status }) => {
  const getTitleConfig = (): MessageDescriptor => {
    let statusTitle = status ? returnTitles[status] : createdMessage;
    if (!statusTitle) {
      statusTitle = {
        id: "returns.returnsDetail.statusTitle.unknown",
        defaultMessage: toStartCase(String(status)),
      };
    }
    return statusTitle;
  };
  const { id, defaultMessage } = getTitleConfig();

  return (
    <StyledTitle as="h3" displayAs="h2" data-testid="return_status_title">
      <FormattedMessage id={id} defaultMessage={defaultMessage} />
    </StyledTitle>
  );
};
