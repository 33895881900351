import { defineMessages } from "react-intl";
import { ReplenishmentPickProductErrorCodes } from "./ReplenishmentPickProductErrorCodes";
import { ReplenishmentUnknownErrorCode } from "../ReplenishmentErrorCodes";

export const replenishmentPickProductErrorMessage = defineMessages({
  [ReplenishmentPickProductErrorCodes.PRODUCTS_NOT_AVAILABLE_AT_SAME_WAREHOUSE]: {
    title: {
      id: "replenishment.pickProduct.error.title.productsNotAvailableAtSameWarehouse",
      defaultMessage: "All products are not available at the same location",
    },
    description: {
      id: "replenishment.pickProduct.error.description.productsNotAvailableAtSameWarehouse",
      defaultMessage:
        "To create transfers for products available at different locations, please create separate transfers.",
    },
  },
  [ReplenishmentPickProductErrorCodes.PRODUCT_NOT_AVAILABLE_IN_ANY_WAREHOUSE]: {
    title: {
      id: "replenishment.pickProduct.error.title.productsNotAvailableAtSameWarehouse",
      defaultMessage: "Product is not available in any location",
    },
    description: {
      id: "replenishment.pickProduct.error.description.productsNotAvailableAtSameWarehouse",
      defaultMessage: "Please remove this product from the transfer. If you need help, contact support.",
    },
  },
  [ReplenishmentPickProductErrorCodes.WAREHOUSE_WITH_INVENTORY_LESS_THAN_CASE_QTY]: {
    title: {
      id: "replenishment.pickProduct.error.title.warehouseWithInventoryLessThanCaseQty",
      defaultMessage: "Some products have partial case quantity",
    },
    description: {
      id: "replenishment.pickProduct.error.description.warehouseWithInventoryLessThanCaseQty",
      defaultMessage:
        "The available inventory is less than a case pack for certain products. Contact support for resolution.",
    },
  },
  [ReplenishmentUnknownErrorCode]: {
    title: {
      id: "replenishment.pickProduct.error.title.unknown",
      defaultMessage: "Unknown error",
    },
    description: {
      id: "replenishment.pickProduct.error.description.unknown",
      defaultMessage: "Unexpected error, please contact support.",
    },
  },
});
