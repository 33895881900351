import { useIntl } from "react-intl";
import { WeightUnit } from "@deliverr/commons-objects";
import { useCallback } from "react";

/**
 * Returns a function to get the label for a passed-in weight value
 * with plurals handled.
 * @param weightUnit unit of weight (lb or kg)
 */
export const useWeightUnitLabel = (weightUnit: WeightUnit = "lb") => {
  const { formatMessage } = useIntl();

  return useCallback(
    (weightValue?: number) =>
      formatMessage(
        weightUnit === "lb"
          ? {
              id: "weightLbs",
              defaultMessage: `{weightValue, plural,
                one {# lb}
                other {# lbs}
              }`,
            }
          : {
              id: "weightKgs",
              defaultMessage: `{weightValue, plural,
                one {# kg}
                other {# kgs}
              }`,
            },
        {
          weightValue: weightValue ?? 0,
        }
      ),
    [weightUnit, formatMessage]
  );
};
