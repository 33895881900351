import { useDispatch, useSelector } from "react-redux";
import { transferCreateSetPrepType } from "transfers/create/actions";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";
import { sumBy } from "lodash/fp";
import { PrepOption } from "@deliverr/business-types";
import { getIsLoading } from "common/components/WithLoader/LoadingSelectors";
import { CreateTransferLoader } from "transfers/create/CreateTransferLoader.types";
import { useMount } from "react-use";

export const useTransferSelectUnitPrepStep = () => {
  const dispatch = useDispatch();
  const { prepOption, selectedProducts, destinationType } = useSelector(selectTransferCreate);
  const isNextDisabled = !prepOption;
  const numOfUnits = sumBy(({ qty }) => qty, Object.values(selectedProducts));
  const numOfCases = sumBy(({ qty, caseQty }) => qty / (caseQty ?? 1), Object.values(selectedProducts));
  const isLoading = useSelector(getIsLoading)(CreateTransferLoader.CreateTransferOrderLoader);

  useMount(() => {
    if (!prepOption) {
      dispatch(transferCreateSetPrepType(PrepOption.SHIPPING_ONLY));
    }
  });

  const onPrepTypeChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const prepTypeValue = ev.target.value as PrepOption;
    return dispatch(transferCreateSetPrepType(prepTypeValue));
  };
  const boxHandlingFee = 2.3;
  const palletHandlingFee = 14;
  const shippingPrepFee = 0.5;
  const unitPrepFee = 0.95;
  const boxLabelingDiscount = 0.1;
  const unitLabelingDiscount = 0.1;

  return {
    isNextDisabled,
    isLoading,
    prepOption,
    destinationType,
    onPrepTypeChange,
    numOfCases,
    numOfUnits,
    boxHandlingFee,
    palletHandlingFee,
    shippingPrepFee,
    unitPrepFee,
    boxLabelingDiscount,
    unitLabelingDiscount,
  };
};
