import { defineMessages } from "react-intl";

export const transfersShipmentCreateLabels = {
  steps: {
    destination: {
      ...defineMessages({
        title: {
          id: "transfers.create.steps.destination.title",
          defaultMessage: "Select a destination",
        },
        subtitle: {
          id: "transfers.create.steps.destination.subtitle",
          defaultMessage: "Select one.",
        },
        connectedPill: {
          id: "transfers.create.steps.destination.option.integration.connectedPill",
          defaultMessage: "Connected",
        },
      }),
      options: {
        ecom: defineMessages({
          label: {
            id: "transfers.create.steps.destination.option.ecom.label",
            defaultMessage: "Flexport Ecom Fulfillment Network",
          },
          description: {
            id: "transfers.create.steps.destination.option.ecom.description",
            defaultMessage: "Make inventory sellable through your sales channels",
          },
        }),
        fbaIntegration: defineMessages({
          label: {
            id: "transfers.create.steps.destination.option.fbaIntegration.label",
            defaultMessage: "Amazon FBA",
          },
          description: {
            id: "transfers.create.steps.destination.option.fbaIntegration.description",
            defaultMessage: "Send inventory to Amazon’s Prime-ready fulfillment centers",
          },
          comment: {
            id: "transfers.create.steps.destination.option.fbaIntegration.comment",
            defaultMessage:
              "Note: Amazon Freight is not currently supported from the integration. To use Amazon Freight, <manualFbaStepLink>create a transfer manually instead</manualFbaStepLink>.",
          },
          notRecommended: {
            id: "transfers.create.steps.destination.option.fbaIntegration.notRecommended",
            defaultMessage:
              "<manualFbaStepLink>Create an Amazon FBA transfer manually instead (not recommended)</manualFbaStepLink>.",
          },
        }),
        wfs: defineMessages({
          label: {
            id: "transfers.create.steps.destination.option.wfs.label",
            defaultMessage: "Walmart WFS",
          },
          description: {
            id: "transfers.create.steps.destination.option.wfs.description",
            defaultMessage: "Fulfillment through Walmart's Fulfillment Services (WFS).",
          },
        }),
      },
    },
    facility: defineMessages({
      title: {
        id: "transfers.create.steps.facility.title",
        defaultMessage: "Select Amazon Facility",
      },
    }),
    product: defineMessages({
      title: {
        id: "transfers.create.steps.product.title",
        defaultMessage: "Select products",
      },
      subtitle: {
        id: "transfers.create.steps.product.subtitle",
        defaultMessage: "Select products with available inventory in Flexport Storage.",
      },
      subtitleLearnMore: {
        id: "transfers.create.steps.product.subtitleLearnMore",
        defaultMessage: "{learnMore} about Amazon's prep requirements.",
      },
      errorNotificationTitle: {
        id: "transfers.create.steps.product.errorNotification",
        defaultMessage: "All products are not available at the same location",
      },
      errorNotificationDescription: {
        id: "transfers.create.steps.product.errorNotificationDescription",
        defaultMessage:
          "To create transfers for products available at different locations, please create separate transfers.",
      },
      eachPicking: {
        disabled: {
          id: "transfers.create.steps.product.eachPicking.disabled",
          defaultMessage: "Enable each picking (toggle before template download)",
        },
        enabled: {
          id: "transfers.create.steps.product.eachPicking.enabled",
          defaultMessage: "Each picking enabled for this order (download updated template) ",
        },
      },
    }),
    origin: defineMessages({
      title: {
        id: "transfers.create.steps.origin.title",
        defaultMessage: "Select origin",
      },
      subtitle: {
        id: "transfers.create.steps.origin.subtitle",
        defaultMessage: "Select a Reserve Storage location with available inventory for your selected products.",
      },
      options: {
        productAvailable: {
          id: "transfers.create.steps.origin.option.available",
          defaultMessage: "{qty} units available for selected products",
        },
        productUnavailable: {
          id: "transfers.create.steps.origin.option.available",
          defaultMessage: "All selected products are not available at this location",
        },
      },
    }),
    productQuantity: defineMessages({
      title: {
        id: "transfers.create.steps.productQuantity.title",
        defaultMessage: "Select cases",
      },
      subtitle: {
        id: "transfers.create.steps.productQuantity.subtitle",
        defaultMessage: "Select from available inventory in Reserve Storage in {storageWarehouseLocation}",
      },
      subtitleWithAddress: {
        id: "transfers.create.steps.productQuantity.subtitleWithAddress",
        defaultMessage: "The selected products will ship from:{br}{fullAddress}",
      },
      eachPicking: {
        disabled: {
          id: "transfers.create.steps.productQuantity.eachPicking.disabled",
          defaultMessage: "Enable each picking for this order",
        },
        enabled: {
          id: "transfers.create.steps.productQuantity.eachPicking.enabled",
          defaultMessage: "Each picking enabled for this order",
        },
      },
    }),
    fbaPrepServices: defineMessages({
      title: {
        id: "transfers.create.steps.fbaPrepServices.title",
        defaultMessage: "Prepare products for Amazon",
      },
      subtitle: {
        id: "transfers.create.steps.fbaPrepServices.subtitle",
        defaultMessage: "Learn more about <a>Flexport Prep</a>.",
      },
    }),
    prepServices: defineMessages({
      skip: {
        id: "transfers.create.steps.prepServices.title",
        defaultMessage: "Skip, I don't need Prep",
      },
      title: {
        id: "transfers.create.steps.prepServices.title",
        defaultMessage: "Prepare products for {destination}",
      },
      subtitle: {
        id: "transfers.create.steps.prepServices.subtitle",
        defaultMessage: "Learn more about <a>Flexport Prep</a>. {additionalPrep}",
      },
      requestAdditionalPrep: {
        id: "transfers.create.steps.prepServices.requestAdditionalPrep",
        defaultMessage: "Need more customized Prep services? <a>Request additional Prep</a>.",
      },
    }),
    addAmazonBarcodeUnitPrep: defineMessages({
      title: {
        id: "transfers.create.steps.addAmazonBarcodeUnitPrep.title",
        defaultMessage: "Add Amazon barcodes for labeling",
      },
      subtitle: {
        id: "transfers.create.steps.addAmazonBarcodeUnitPrep.subtitle",
        defaultMessage: "Provide product barcode information so Prep associates can prepare your products.",
      },
      infoMessage: {
        id: "transfers.create.steps.addAmazonBarcodeUnitPrep.infoMessage",
        defaultMessage:
          "Ensure barcodes are accurate before proceeding, they cannot be removed or edited once submitted.",
      },
    }),
    shipping: {
      ...defineMessages({
        title: {
          id: "transfers.create.steps.shipping.title",
          defaultMessage: "Select a shipping method",
        },
        shipTo: {
          id: "transfers.create.steps.shipping.shipTo",
          defaultMessage: "Ship to:",
        },
        subtitle: {
          id: "transfers.create.steps.shipping.subtitle",
          defaultMessage: "Select one.",
        },
        selectCarrier: {
          id: "transfersShipmentCreateLabels.steps.shipping.selectCarrier",
          defaultMessage: "Select carrier",
        },
        selectCargoType: {
          id: "transfersShipmentCreateLabels.steps.shipping.selectCargoType",
          defaultMessage: "Select cargo type",
        },
        selectShippingMethod: {
          id: "transfers.create.steps.shipping.selectShippingMethod",
          defaultMessage: "Select shipping method",
        },
        amazonEstimationError: {
          id: "transfers.create.steps.shipping.amazonEstimationError",
          defaultMessage: "A quote could not be generated. Please try creating a transfer to FBA manually.",
        },
        deliverrEstimationError: {
          id: "transfers.create.steps.shipping.deliverrEstimationError",
          defaultMessage: "Our support team will contact you with a final quote once you confirm your transfer.",
        },
        referenceIdNotificationTitle: {
          id: "transfers.create.steps.shipping.referenceIdNotificationTitle",
          defaultMessage: "Important",
        },
        referenceIdNotification: {
          id: "transfers.create.steps.shipping.referenceIdNotification",
          defaultMessage:
            "After confirming the transfer, you will need to go to your Seller Central account and share the shipment’s “Amazon reference ID” before Flexport can process the shipment.",
        },
        learnMore: {
          id: "transfers.create.steps.shipping.learnMore",
          defaultMessage: "Learn more",
        },
        estimationInProgress: {
          id: "transfers.create.steps.shipping.estimationInProgress",
          defaultMessage: "Estimation in progress...",
        },
        palletNoEstimate: {
          id: "transfers.create.steps.shipping.palletNoEstimate",
          defaultMessage: "Our support team will contact you with a final quote once you confirm the order.",
        },
        parcelNotAvailable: {
          id: "transfers.create.steps.shipping.parcelNotAvailable",
          defaultMessage: "Small parcel option is not available for your selected dims and weights.",
        },
        shipmentItemDesc: {
          id: "transfers.create.steps.shipping.shipmentItemDesc",
          defaultMessage:
            "{numOfSkus, plural, one {# SKU} other {# SKUs}} · {numOfCases, plural, one {# box} other {# boxes}} · {numOfUnits, plural, one {# unit} other {# units}}",
        },
        parcelSizeEstimationError: {
          id: "transfers.create.steps.shipping.cargoOptions.parcel.sizeEstimationError",
          defaultMessage: "Some selected products do not meet Amazon's requirements for small parcel. {learnMore}",
        },
      }),
      options: {
        deliverr: defineMessages({
          label: {
            id: "transfers.create.steps.shipping.options.deliverr.label",
            defaultMessage: "Flexport partner",
          },
          description: {
            id: "transfers.create.steps.shipping.options.deliverr.description",
            defaultMessage: "We'll handle everything for you. {learnMore}{br}{amount} estimated total",
          },
          descriptionLoading: {
            id: "transfers.create.steps.shipping.options.deliverr.descriptionLoading",
            defaultMessage: "We'll handle everything for you. {learnMore}{br}{amount} estimation in progress...",
          },
          descriptionWithoutEstimate: {
            id: "transfers.create.steps.shipping.options.deliverr.descriptionWithoutEstimate",
            defaultMessage: "We'll handle everything for you. {learnMore}",
          },
          fbaIntegratedDescription: {
            id: "transfers.create.steps.shipping.options.deliverr.fbaIntegratedDescription",
            defaultMessage: "Hassle free, transparent transportation",
          },
        }),
        yourCarrier: defineMessages({
          label: {
            id: "transfers.create.steps.shipping.options.yourCarrier.label",
            defaultMessage: "Your carrier",
          },
          description: {
            id: "transfers.create.steps.shipping.options.yourCarrier.description",
            defaultMessage: "Use your own Amazon-approved carrier.",
          },
        }),
        amazon: defineMessages({
          label: {
            id: "transfers.create.steps.shipping.options.amazon.label",
            defaultMessage: "Amazon partner",
          },
          description: {
            id: "transfers.create.steps.shipping.options.amazon.description",
            defaultMessage: "Use Amazon's transportation",
          },
        }),
        seller: defineMessages({
          label: {
            id: "transfers.create.steps.shipping.options.seller.label",
            defaultMessage: "Your carrier",
          },
          description: {
            id: "transfers.create.steps.shipping.options.seller.description",
            defaultMessage: "Use your own Amazon-approved carrier.",
          },
        }),
        fbaWithOptions: defineMessages({
          label: {
            id: "transfers.create.steps.shipping.options.fbaWithOptions.label",
            defaultMessage: "{shippingMethod} with {partnerType} partner",
          },
          freight: {
            id: "transfers.create.steps.shipping.options.fbaWithOptions.freight",
            defaultMessage: "LTL/FTL",
          },
          parcel: {
            id: "transfers.create.steps.shipping.options.fbaWithOptions.parcel",
            defaultMessage: "Small parcel",
          },
          deliverr: {
            id: "transfers.create.steps.shipping.options.fbaWithOptions.deliverr",
            defaultMessage: "Flexport",
          },
          amazon: {
            id: "transfers.create.steps.shipping.options.fbaWithOptions.amazon",
            defaultMessage: "Amazon",
          },
        }),
      },
      cargoOptions: defineMessages({
        generatingQuote: {
          id: "transfers.create.steps.shipping.cargoOptions.generatingQuote",
          defaultMessage: "Generating your quote...",
        },
        errorGeneratingEstimation: {
          id: "transfers.create.steps.shipping.estimationError",
          defaultMessage: "A estimation could not be generated.",
        },
        estimating: {
          id: "transfers.create.steps.shipping.cargoOptions.estimating",
          defaultMessage: "Estimating...",
        },
        pallet: {
          label: {
            id: "transfers.create.steps.shipping.cargoOptions.pallet.label",
            defaultMessage: "Palletized",
          },
          description: {
            id: "transfers.create.steps.shipping.cargoOptions.pallet.description",
            defaultMessage: "LTL/FTL",
          },
          fbaAutoLabel: {
            id: "transfers.create.steps.shipping.cargoOptions.pallet.fbaAutoLabel",
            defaultMessage: "LTL/FTL (Palletized)",
          },
          comingSoon: {
            id: "transfers.create.steps.shipping.cargoOptions.pallet.comingSoon",
            defaultMessage: "Coming soon",
          },
          descriptionXEstimatedTotal: {
            id: "transfers.create.steps.shipping.cargoOptions.pallet.descriptionXEstimatedTotal",
            defaultMessage: "{estimatedTotal} estimated total",
          },
          amazonFreightEstimatedDescriptionMessage: {
            id: "transfers.create.steps.shipping.cargoOptions.pallet.amazonFreightEstimatedDescriptionMessage",
            defaultMessage:
              "You'll be able to see an estimated cost in Seller Central after your shipment is prepared.",
          },
          amazonFreightEstimatedCaptionMessage: {
            id: "transfers.create.steps.shipping.cargoOptions.pallet.amazonFreightEstimatedCaptionMessage",
            defaultMessage:
              "You will be billed by Amazon for shipping. Your final invoice may change due to additional carrier pickup fees or unexpected cargo quantities, weights, and dimensions.",
          },
          estimatedPalletShippingCost: {
            id: "transfers.create.steps.shipping.cargoOptions.pallet.estimatedPalletShippingCost",
            defaultMessage: "{formattedAmount} for estimated {numOfPallets, plural, one {# pallet} other {# pallets}}",
          },
        },
        parcel: {
          label: {
            id: "transfers.create.steps.shipping.cargoOptions.parcel.label",
            defaultMessage: "Small Parcel",
          },
          description: {
            id: "transfers.create.steps.shipping.cargoOptions.parcel.description",
            defaultMessage: "Individual boxes",
          },
          fbaAutoLabel: {
            id: "transfers.create.steps.shipping.cargoOptions.parcel.fbaAutoLabel",
            defaultMessage: "Small parcel (Individual boxes)",
          },
          descriptionXEstimatedTotal: {
            id: "transfers.create.steps.shipping.cargoOptions.parcel.descriptionXEstimatedTotal",
            defaultMessage: "{estimatedTotal} estimated total",
          },
          estimatedParcelShippingCost: {
            id: "transfers.create.steps.shipping.cargoOptions.parcel.estimatedParcelShippingCost",
            defaultMessage: "{formattedAmount} for estimated {numOfBoxes, plural, one {# box} other {# boxes}}",
          },
        },
        estimatedShippingCost: {
          id: "transfers.create.steps.shipping.cargoOptions.estimatedShippingCost",
          defaultMessage: "{formattedAmount} estimated shipping cost",
        },
      }),
      contactInfo: defineMessages({
        title: {
          id: "transfers.create.steps.shipping.contactInfo.title",
          defaultMessage: "Confirm your contact information",
        },
        subtitle: {
          id: "transfers.create.steps.shipping.contactInfo.subtitle",
          defaultMessage: "We'll contact you when the shipment is ready for pickup.",
        },
        email: {
          id: "transfers.create.steps.shipping.contactInfo.email",
          defaultMessage: "Email",
        },
        phoneNumber: {
          id: "transfers.create.steps.shipping.contactInfo.phoneNumber",
          defaultMessage: "Phone number",
        },
      }),
    },
    shipmentDetails: defineMessages({
      title: {
        id: "transfers.create.steps.shipmentDetails.title",
        defaultMessage: "Add your shipment details",
      },
      subtitle: {
        id: "transfers.create.steps.shipmentDetails.subtitle",
        defaultMessage: "Add the details from the shipment you created in Seller Central.",
      },
      optional: {
        id: "transfers.create.steps.shipmentDetails.optional",
        defaultMessage: "(Optional)",
      },
      shipmentId: {
        label: {
          id: "transfers.create.steps.shipmentDetails.shipmentId.label",
          defaultMessage: "FBA Shipment ID",
        },
        placeholder: {
          id: "transfers.create.steps.shipmentDetails.shipmentId.placeholder",
          defaultMessage: "FBA0000000000",
        },
        validateAttribute: {
          id: "transfers.create.steps.shipmentDetails.shipmentId.validateAttribute",
          defaultMessage:
            "Shipment ID should start with either 'FBA' followed by exactly 9 characters or 'STAR-' followed by exactly 13 characters",
        },
      },
      referenceId: {
        label: {
          id: "transfers.create.steps.shipmentDetails.referenceId.label",
          defaultMessage: "FBA Reference ID",
        },
        validateAttribute: {
          id: "transfers.create.steps.shipmentDetails.referenceId.validateAttribute",
          defaultMessage: "FBA Reference ID should include exactly 8 characters of numbers and letters only",
        },
        placeholder: {
          id: "transfers.create.steps.shipmentDetails.referenceId.placeholder",
          defaultMessage: "0000000000",
        },
      },
      boxFile: {
        label: {
          id: "transfers.create.steps.shipmentDetails.boxFile.label",
          defaultMessage: "Box label PDF",
        },
        requiredHint: {
          id: "transfers.create.steps.shipmentDetails.boxFile.requiredHint",
          defaultMessage: "Box label PDF is required",
        },
      },
      palletFile: {
        label: {
          id: "transfers.create.steps.shipmentDetails.palletFile.label",
          defaultMessage: "Pallet label PDF",
        },
      },
      shippingFile: {
        label: {
          id: "transfers.create.steps.shipmentDetails.shippingFile.label",
          defaultMessage: "Shipping label PDF",
        },
      },
      commercialInvoice: {
        label: {
          id: "transfers.create.steps.shipmentDetails.commercialInvoiceFile.label",
          defaultMessage: "Commercial Invoice PDF",
        },
        requiredHint: {
          id: "transfers.create.steps.shipmentDetails.commercialInvoice.requiredHint",
          defaultMessage: "Commercial Invoice PDF is required",
        },
      },
      labelFormat: {
        id: "transfers.create.steps.shipmentDetails.labelsFormat",
        defaultMessage: "Upload labels in 4x6 format only",
      },
      amazonAddThisLater: {
        id: "transfers.create.steps.shipmentDetails.amazonAddThisLater",
        defaultMessage: "If you are using Amazon's rates, you can add this later.",
      },
    }),
    confirm: defineMessages({
      title: {
        id: "transfers.create.steps.confirm.title",
        defaultMessage: "Confirm your transfer",
      },
      nextButtonCaption: {
        id: "transfers.create.steps.confirm.nextButtonCaption",
        defaultMessage: "Confirm",
      },
      nonCDConfirmationText: {
        id: "transfers.create.steps.confirm.nonCDConfirmationText",
        defaultMessage:
          "I understand that I must arrange my own transportation for this transfer by emailing {freightEmail}.",
      },
      pricingSummary: {
        id: "transfers.create.steps.confirm.pricingSummary",
        defaultMessage: "Pricing summary",
      },
      boxLabelling: {
        id: "transfers.create.steps.confirm.boxLabelling",
        defaultMessage: "Box labelling",
      },
      boxLabellingFeeTooltip: {
        id: "transfers.create.steps.confirm.boxLabellingFeeTooltip",
        defaultMessage: "This includes the cost of labels and labor",
      },
      forwarding: {
        id: "transfers.create.steps.confirm.forwarding",
        defaultMessage: "Forwarding",
      },
      forwardingFeeTooltip: {
        id: "transfers.create.steps.confirm.forwardingFeeTooltip",
        defaultMessage: "This includes processing and handling the shipment",
      },
      freight: {
        id: "transfers.create.steps.confirm.freight",
        defaultMessage: "Flexport transportation",
      },
      tbd: {
        id: "transfers.create.steps.confirm.tbd",
        defaultMessage: "TBD",
      },
      noEstimationTotalDesc: {
        id: "transfers.create.steps.confirm.noEstimationTotalDesc",
        defaultMessage:
          "Our support team will contact you shortly with a final quote.{br}You will not be charged until your order is shipped",
      },
      totalRowLabel: {
        id: "transfers.create.steps.confirm.totalRowLabel",
        defaultMessage: "Estimated total",
      },
      fromDescription: {
        id: "transfers.create.steps.confirm.ecom.fromDescription",
        defaultMessage: "Flexport Reserve Storage",
      },
      toDescription: {
        id: "transfers.create.steps.confirm.ecom.toDescription",
        defaultMessage: "Flexport Ecom",
      },
      fbaFromWarehouseDescription: {
        id: "transfers.create.steps.confirm.fba.fromDescription",
        defaultMessage: "Reserve Storage",
      },
      aggregationDescription: {
        id: "transfers.create.steps.confirm.fba.aggregationDescription",
        defaultMessage: "This includes all shipments in this transfer.",
      },
      aggregationDescriptionOneShipment: {
        id: "transfers.create.steps.confirm.fba.aggregationDescription",
        defaultMessage: "This includes current shipment only.",
      },
    }),
    confirmed: defineMessages({
      title: {
        id: "transfers.create.steps.confirmed.title",
        defaultMessage: "Transfer Confirmed",
      },
      subtitle: {
        id: "transfers.create.steps.confirmed.subtitle",
        defaultMessage: "You're all set. We'll keep you updated on the status of your transfer.",
      },
    }),
    fbaIntegration: {
      oauth: defineMessages({
        title: {
          id: "transfers.create.steps.fbaIntegration.oauth.title",
          defaultMessage: "Connect Amazon",
        },
        subtitle: {
          id: "transfers.create.steps.fbaIntegration.oauth.subtitle",
          defaultMessage:
            "Deliverr will create inbound shipments to FBA in Seller Central on your behalf. You will be redirected to Amazon to grant permissions. {learnMore}",
        },
        amazonFreight: {
          id: "transfers.create.steps.fbaIntegration.oauth.amazonFreight",
          defaultMessage:
            "Note: Amazon Freight is not currently supported from the integration. To use Amazon Freight, <manualFbaStepLink>create a transfer manually instead</manualFbaStepLink>.",
        },
      }),
      skip: {
        id: "transfers.create.steps.fbaIntegration.skip",
        defaultMessage: "Skip",
      },
      connect: {
        id: "transfers.create.steps.fbaIntegration.connect",
        defaultMessage: "Connect",
      },

      waitForShipmentCreation: defineMessages({
        title: {
          id: "transfers.create.steps.fbaIntegration.waitForShipmentCreation.title",
          defaultMessage: "Generating your shipping plan in Amazon",
        },
        subtitle: {
          id: "transfers.create.steps.fbaIntegration.waitForShipmentCreation.subtitle",
          defaultMessage: "It may take up to one minute",
        },
      }),

      shipmentCreationErrors: defineMessages({
        title: {
          id: "transfers.create.steps.fbaIntegration.shipmentCreationError.title",
          defaultMessage: "Shipment Generation Error",
        },
        subtitle: {
          id: "transfers.create.steps.fbaIntegration.shipmentCreationError.subtitle",
          defaultMessage: "A shipment could not be generated by Amazon because {errorMessage}",
        },
      }),

      waitForShipmentConfirmation: defineMessages({
        title: {
          id: "transfers.create.steps.fbaIntegration.waitForShipmentConfirmation.title",
          defaultMessage: "Transfer confirmed. Syncing with Amazon",
        },
        subtitle: {
          id: "transfers.create.steps.fbaIntegration.waitForShipmentConfirmation.subtitle",
          defaultMessage: "This could take up to two minutes",
        },
        warningTitle: {
          id: "transfers.create.steps.fbaIntegration.waitForShipmentConfirmation.warningTitle",
          defaultMessage: "Important",
        },
        warningSubtitleParcel: {
          id: "transfers.create.steps.fbaIntegration.waitForShipmentConfirmation.warningSubtitleParcel",
          defaultMessage:
            "Do not try to view the shipment on Seller Central for at least two minutes or until the sync with Amazon is completed.",
        },
        warningSubtitle1Freight: {
          id: "transfers.create.steps.fbaIntegration.waitForShipmentConfirmation.warningSubtitle1Freight",
          defaultMessage:
            "Before Flexport can prepare the shipment, you must provide the Amazon reference ID for the shipment. This can be found on the shipment details page in your Seller Central dashboard.",
        },
        warningSubtitle2Freight: {
          id: "transfers.create.steps.fbaIntegration.waitForShipmentConfirmation.warningSubtitle2Freight",
          defaultMessage:
            "Do not try to view the shipment on Seller Central until the sync with Amazon is completed. You can leave this page and come back later to check on the status of the sync.",
        },
      }),
      confirmed: defineMessages({
        title: {
          id: "transfers.create.steps.fbaIntegration.confirmed.title",
          defaultMessage: "Transfer confirmed & synced with Amazon",
        },
        subtitleSingleShipment: {
          id: "transfers.create.steps.fbaIntegration.confirmed.subtitleSingleShipment",
          defaultMessage: "Your FBA Shipment ID is {fbaShipmentId}.",
        },
        subtitleMultiShipment: {
          id: "transfers.create.steps.fbaIntegration.confirmed.subtitleMultiShipment",
          defaultMessage: "Your FBA Shipment IDs are {shipmentIdList}.",
        },
        confirmButtonCaption: {
          id: "transfers.create.steps.fbaIntegration.confirmed.confirmButtonCaption",
          defaultMessage: "Done",
        },
      }),
    },

    wfsIntegration: {
      oauth: defineMessages({
        title: {
          id: "transfers.create.steps.wfsIntegration.oauth.title",
          defaultMessage: "Connect Walmart",
        },
        subtitle1: {
          id: "transfers.create.steps.wfsIntegration.oauth.subtitle1",
          defaultMessage:
            "Flexport will create inbound shipments to Walmart Seller Center on your behalf. You will need to enter API Keys from Walmart developer portal. {learnMore}{br}",
        },
      }),
      skip: {
        id: "transfers.create.steps.wfsIntegration.skip",
        defaultMessage: "Skip",
      },
      connect: {
        id: "transfers.create.steps.wfsIntegration.connect",
        defaultMessage: "Connect",
      },
      next: {
        id: "transfers.create.steps.wfsIntegration.next",
        defaultMessage: "Next",
      },

      oauthComplete: defineMessages({
        titleSyncComplete: {
          id: "transfers.create.steps.wfsIntegration.oauthComplete.titleSyncComplete",
          defaultMessage: "You're connected to Walmart",
        },
        titleSyncIncomplete: {
          id: "transfers.create.steps.wfsIntegration.oauthComplete.titleSyncIncomplete",
          defaultMessage: "You're almost ready to send to Walmart",
        },
        subtitle: {
          id: "transfers.create.steps.wfsIntegration.oauthComplete.subtitle",
          defaultMessage:
            "Deliverr will create inbound shipments to WFS in Seller Central on your behalf.{br}{learnMore}",
        },
        catalogWarning: {
          id: "transfers.create.steps.wfsIntegration.oauthComplete.catalogWarning",
          defaultMessage:
            "To allow Deliverr to add your products to an inbound shipment on your behalf in Walmart, visit <a> Integrations > Walmart </a> and sync your WFS catalog.",
        },
      }),

      waitForShipmentCreation: defineMessages({
        title: {
          id: "transfers.create.steps.wfsIntegration.waitForShipmentCreation.title",
          defaultMessage: "Generating your shipping plan in Walmart",
        },
        subtitle: {
          id: "transfers.create.steps.wfsIntegration.waitForShipmentCreation.subtitle",
          defaultMessage: "It may take up to one minute",
        },
      }),

      shipmentCreationErrors: defineMessages({
        title: {
          id: "transfers.create.steps.wfsIntegration.shipmentCreationError.title",
          defaultMessage: "Shipment Generation Error",
        },
        subtitle: {
          id: "transfers.create.steps.wfsIntegration.shipmentCreationError.subtitle",
          defaultMessage: "A shipment could not be generated by Walmart because {errorMessage}",
        },
      }),

      waitForShipmentConfirmation: defineMessages({
        title: {
          id: "transfers.create.steps.wfsIntegration.waitForShipmentConfirmation.title",
          defaultMessage: "Transfer confirmed. Syncing with Walmart",
        },
        subtitle: {
          id: "transfers.create.steps.wfsIntegration.waitForShipmentConfirmation.subtitle",
          defaultMessage: "This could take up to two minutes",
        },
        warningTitle: {
          id: "transfers.create.steps.wfsIntegration.waitForShipmentConfirmation.warningTitle",
          defaultMessage: "Important",
        },
        warningSubtitleParcel: {
          id: "transfers.create.steps.wfsIntegration.waitForShipmentConfirmation.warningSubtitleParcel",
          defaultMessage:
            "Do not try to view the shipment on Seller Central for at least two minutes or until the sync with Walmart is completed.",
        },
        warningSubtitle1Freight: {
          id: "transfers.create.steps.wfsIntegration.waitForShipmentConfirmation.warningSubtitle1Freight",
          defaultMessage:
            "Before Deliverr can prepare the shipment, you must provide the Walmart reference ID for the shipment. This can be found on the shipment details page in your Seller Central dashboard.",
        },
        warningSubtitle2Freight: {
          id: "transfers.create.steps.wfsIntegration.waitForShipmentConfirmation.warningSubtitle2Freight",
          defaultMessage:
            "Do not try to view the shipment on Seller Central until the sync with Walmart is completed. You can leave this page and come back later to check on the status of the sync.",
        },
      }),

      confirmed: defineMessages({
        title: {
          id: "transfers.create.steps.wfsIntegration.confirmed.title",
          defaultMessage: "Transfer confirmed & synced with Walmart",
        },
        subtitleSingleShipment: {
          id: "transfers.create.steps.wfsIntegration.confirmed.subtitleSingleShipment",
          defaultMessage: "Your WFS Shipment ID is {wfsShipmentId}.",
        },
        subtitleMultiShipment: {
          id: "transfers.create.steps.wfsIntegration.confirmed.subtitleMultiShipment",
          defaultMessage: "Your WFS Shipment IDs are {shipmentIdList}.",
        },
        confirmButtonCaption: {
          id: "transfers.create.steps.wfsIntegration.confirmed.confirmButtonCaption",
          defaultMessage: "Done",
        },
      }),
    },
  },
};
