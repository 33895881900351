import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { CARGO_TYPE_MESSAGES } from "./CargoTypeMessages";
import { FormattedMessage } from "react-intl";
import InboundLoaderId from "inbounds/InboundLoaderId";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { getStorageInboundCreate } from "../store/selector/getStorageInboundCreate";
import { useStorageInboundNavigation } from "../navigation/useStorageInboundNavigation";
import { useMount } from "react-use";
import { getEstimatedNumberOfPallets } from "./actions/getEstimatedNumberOfPallets";
import { setCargoType } from "./actions/setCargoType";
import { getStorageInboundCostByShipmentType } from "storage/inbounds/utils/getStorageInboundCostByShipmentType";
import { getIsCargoTypeNextButtonDisabled } from "storage/inbounds/utils/getIsCargoTypeNextButtonDisabled";
import { updateStorageInboundOrder } from "../store/actions/updateStorageInboundOrder";
import { CarrierType, ShipmentType } from "@deliverr/storage-client";

export const FREIGHT_LTL_PALLET_LIMIT = 6;

export const useStorageCargoType = () => {
  const dispatch = useDispatch();
  const { goToNextStep, goToPreviousStep } = useStorageInboundNavigation();
  const {
    cargoType: selectedCargoType,
    destinationStorageWarehouse,
    numOfPallets,
    containerSize,
    estimatedNumOfPallets,
  } = useSelector(getStorageInboundCreate);

  const { carrierType } = useSelector(getStorageInboundCreate);

  useMount(() => {
    dispatch(getEstimatedNumberOfPallets());
  });

  const isNextDisabled = getIsCargoTypeNextButtonDisabled(
    selectedCargoType ?? ShipmentType.PALLETIZED,
    containerSize,
    numOfPallets,
    destinationStorageWarehouse
  );

  const cargoTypeOptions = React.useMemo(
    () =>
      Object.values(ShipmentType).map((shipmentType) => {
        return {
          disabled: shipmentType === ShipmentType.FLOOR_LOADED_CONTAINER && carrierType === CarrierType.DELIVERR,
          value: shipmentType,
          label: <FormattedMessage {...CARGO_TYPE_MESSAGES[shipmentType][carrierType!].label} />,
          content: (
            <FormattedMessage
              {...CARGO_TYPE_MESSAGES[shipmentType][carrierType!].content}
              values={{
                price: getStorageInboundCostByShipmentType(
                  shipmentType,
                  shipmentType === ShipmentType.PALLETIZED ? numOfPallets : estimatedNumOfPallets,
                  containerSize
                ),
                br: <br />,
              }}
            />
          ),
        };
      }),
    [numOfPallets, containerSize, estimatedNumOfPallets, carrierType]
  );

  const onCargoTypeChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setCargoType(ev.target.value as ShipmentType));
  };
  const onNextClick = async () => {
    dispatch(addLoader(InboundLoaderId.transition));
    if (await dispatch(updateStorageInboundOrder())) {
      await goToNextStep();
    }
    dispatch(clearLoader(InboundLoaderId.transition));
  };

  const onPreviousClick = () => {
    goToPreviousStep();
  };

  return {
    carrierType,
    onNextClick,
    onPreviousClick,
    cargoTypeOptions,
    selectedCargoType,
    onCargoTypeChange,
    destinationStorageWarehouse,
    isNextDisabled,
  };
};
