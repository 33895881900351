import { ATPDetails } from "common/clients/inventory/ATP/ATPDetails";

export const calculateReserveStorageAvailableQuantity = (
  product: {
    raw?: {
      storageOnHandQty?: number;
      storageUnfilledOrderQty?: number;
      storageUnavailableQty?: number;
    };
    storageOnHandQty?: number;
    storageUnfilledOrderQty?: number;
    storageUnavailableQty?: number;
  },
  selectedProductLotNumber?: ATPDetails
): number => {
  const { storageOnHandQty = 0, storageUnfilledOrderQty = 0, storageUnavailableQty = 0 } = product.raw ?? product ?? {};

  const result = storageOnHandQty - storageUnfilledOrderQty - storageUnavailableQty;
  return selectedProductLotNumber ? Math.max(selectedProductLotNumber.totalPickableQty ?? 0, 0) : Math.max(result, 0);
};
