import { usePrepCostConfigs } from "prep/hooks";
import { selectConsolidatedPrepTypes } from "prep/store";
import { SimpleSellerSelfServePrepType } from "prep/types";
import { ChangeEventHandler, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ORDERED_SELF_SERVE_PREP_TYPES } from "../PrepTileGroup/orderedPrepTypes.const";
import { useBuildPrepTileOptionByPrepType } from "../PrepTileGroup/useBuildPrepTileOptionByPrepType";
import { PrepTileGroupProps } from "../PrepTileGroup";
import { fetchTotalUnits } from "prep/store/selectors/fetchTotalUnits";
import { PrepCreationSource } from "prep/types/PrepCreationSource";
import { setCreationSource } from "prep/store/actions/setCreationSource";
import { transferCreateSetPrepType } from "transfers/create/actions";
import { PrepOption } from "@deliverr/business-types";
import { FeatureName, useFeatureOn } from "common/Split";
import { getIsKittedProductSelected, getIsNonKittedProductSelected } from "inbounds/steps/InboundStepSelectors";
import { PrepType } from "@deliverr/prep-service-client";
import { KittingDisabledTooltip } from "./KittingDisabledTooltip";
import { CheckableTileGroupOptionProps } from "common/components/ui";

export interface SimplePrepPageProps extends PrepTileGroupProps {
  totalEstimatedCost: number;
  selectedKitting?: SimpleSellerSelfServePrepType[];
  handleSelectKitting?: ChangeEventHandler<HTMLInputElement>;
  shouldShowKittingService?: boolean;
  isKittingServiceDisabled?: boolean;
}

export const useSimplePrepPage = (
  source: PrepCreationSource,
  defaultSelectedPrepType?: SimpleSellerSelfServePrepType
): SimplePrepPageProps => {
  const isPrepKittingInboundsEnabled: boolean = useFeatureOn(FeatureName.PrepKittingInbounds);
  const hasKittedProductSelected = useSelector(getIsKittedProductSelected);
  const isKittingServiceDisabled: boolean = useSelector(getIsNonKittedProductSelected);
  const shouldShowKittingService: boolean =
    isPrepKittingInboundsEnabled && hasKittedProductSelected && source === PrepCreationSource.INBOUNDS;

  const dispatch = useDispatch();
  const consolidatedPrepTypes = useSelector(selectConsolidatedPrepTypes);
  const itemCount = useSelector(fetchTotalUnits(source));
  const { getTotalPrepCost } = usePrepCostConfigs();

  /**
   * The implementation below attempts to enforce single selection while still being easily refactorable
   * for future requirements that will allow multiple selections.
   */
  const [selectedPrepTypes, setSelectedPrepTypes] = useState<SimpleSellerSelfServePrepType[]>([]);
  const [selectedKitting, setSelectedKitting] = useState<SimpleSellerSelfServePrepType[]>([]);

  const { buildPrepTileOptionByPrepType } = useBuildPrepTileOptionByPrepType();

  const [options, setOptions] = useState<CheckableTileGroupOptionProps[]>([]);

  const updateKittingOption = (tileOptions: CheckableTileGroupOptionProps[]): CheckableTileGroupOptionProps[] => {
    return tileOptions.map((tileOption) => {
      if (tileOption?.value === PrepType.KITTING) {
        return {
          ...tileOption,
          tooltipProps: {
            trigger: ["hover"],
            placement: "top",
            defaultVisible: true,
            overlay: KittingDisabledTooltip,
            align: { offset: [-300, 10] },
            getTooltipContainer: () => document.getElementById("outerContainer") as Element,
          },
        };
      }
      return tileOption;
    });
  };

  useEffect(() => {
    let allPrepOptions = ORDERED_SELF_SERVE_PREP_TYPES.map((prepType) =>
      buildPrepTileOptionByPrepType({ prepType, itemCount })
    );
    if (isKittingServiceDisabled) {
      allPrepOptions = updateKittingOption(allPrepOptions);
    }
    setOptions(allPrepOptions);
  }, [isKittingServiceDisabled, buildPrepTileOptionByPrepType, itemCount]);

  useEffect(() => {
    if (consolidatedPrepTypes.length === 0 && source === PrepCreationSource.REPLENISHMENT) {
      return;
    }

    const prepTypesSet = new Set(ORDERED_SELF_SERVE_PREP_TYPES);

    const filteredPrepTypes: SimpleSellerSelfServePrepType[] = consolidatedPrepTypes.filter((prepType) =>
      prepTypesSet.has(prepType as SimpleSellerSelfServePrepType)
    ) as SimpleSellerSelfServePrepType[];

    const filteredSimplePrepTypes = filteredPrepTypes.filter((prepType) => prepType !== PrepType.KITTING);
    const filteredKittingType = filteredPrepTypes.filter((prepType) => prepType === PrepType.KITTING);
    // This should only ever have one element based on back-end constraints
    setSelectedPrepTypes(filteredSimplePrepTypes.slice(0, 1));

    // To handle draft state when kitting was previously enabled
    setSelectedKitting(isKittingServiceDisabled ? [] : filteredKittingType.slice(0, 1));
  }, [consolidatedPrepTypes, isKittingServiceDisabled, source]);

  useEffect(() => {
    if (source === PrepCreationSource.REPLENISHMENT && defaultSelectedPrepType) {
      setSelectedPrepTypes([defaultSelectedPrepType]);
    }
  }, [defaultSelectedPrepType, source]);

  useEffect(() => {
    dispatch(setCreationSource(source));
  }, [dispatch, source]);

  const totalEstimatedCost = getTotalPrepCost([...selectedPrepTypes, ...selectedKitting]);

  // currently only supports a single selection
  const handleSelectPrepType: ChangeEventHandler<HTMLInputElement> = (event) => {
    const isChecked = event.target.checked;
    const updatedPrepTypes = isChecked ? [event.target.value as SimpleSellerSelfServePrepType] : [];

    setSelectedPrepTypes(updatedPrepTypes);
    if (source === PrepCreationSource.REPLENISHMENT) {
      dispatch(transferCreateSetPrepType(isChecked ? (event.target.value as PrepOption) : undefined));
    }
  };

  const handleSelectKitting: ChangeEventHandler<HTMLInputElement> = (event) => {
    const isChecked = event.target.checked;
    const updatedPrepTypes = isChecked ? [event.target.value as SimpleSellerSelfServePrepType] : [];
    setSelectedKitting(updatedPrepTypes);
  };

  return {
    handleSelectPrepType,
    handleSelectKitting,
    options,
    totalEstimatedCost,
    selectedPrepTypes,
    selectedKitting,
    itemCount,
    shouldShowKittingService,
    isKittingServiceDisabled,
  };
};
