import { OrderEstimateRequest, OrderEstimateResponse, OutboundEntity } from "@deliverr/replenishment-client";
import { logError } from "Logger";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { WholesaleErrorType } from "common/wholesale-common/utils/WholesaleErrorTypes";
import { chain, sumBy } from "lodash";
import { batch } from "react-redux";
import { CreateTransferLoader } from "transfers/create/CreateTransferLoader.types";
import {
  transferCreateSetError,
  transferCreateSetReplenishmentOrderQuotes,
  transferCreateSetReplenishmentError,
} from "../../TransferCreateActions";
import { ChargeRow } from "./ChargeRow";
import { feeDescriptionMessages } from "./feeDescriptionMessages";
import { replenishmentClient } from "Clients";
import { SPThunkAction } from "common/ReduxUtils";

export const getShippingQuotes =
  (quoteRequest: OrderEstimateRequest, orderId: string): SPThunkAction<Promise<boolean>> =>
  async (dispatch, getState) => {
    batch(() => {
      dispatch(addLoader(CreateTransferLoader.CreateTransferOrderLoader));
      dispatch(transferCreateSetError(WholesaleErrorType.NONE));
      dispatch(transferCreateSetReplenishmentError({ errors: [] }));
    });
    let chargeRows: ChargeRow[] = [];

    try {
      const res: OrderEstimateResponse = (await replenishmentClient.getOrderEstimatesV2(orderId, quoteRequest)).value;
      chargeRows = chain(res.shipmentEstimates)
        .flatMap((quotes) => quotes.chargeBreakdown)
        .groupBy((breakdown) => breakdown.chargeType)
        .map((val, key) => {
          const isShippingFee = (val[0].chargeType as any) === "SHIPPING_FEE";
          const totalCost = sumBy(val, (chargesRecord) => chargesRecord.totalCost);
          const row: ChargeRow = {
            feeDescription: feeDescriptionMessages[key],
            chargeType: val[0].chargeType,
            unitCost: isShippingFee ? totalCost : val[0].unitCost,
            unitOfMeasure: isShippingFee ? OutboundEntity.ORDER : val[0].unitOfMeasure,
            unitCount: isShippingFee ? 1 : sumBy(val, (chargesRecord) => chargesRecord.unitCount),
            totalCost,
          };

          return row ?? [];
        })
        .value();

      dispatch(transferCreateSetReplenishmentOrderQuotes({ charges: chargeRows }));
      dispatch(clearLoader(CreateTransferLoader.CreateTransferOrderLoader));
      return true;
    } catch (err) {
      batch(() => {
        dispatch(transferCreateSetError(WholesaleErrorType.UNTYPED));
      });
      dispatch(clearLoader(CreateTransferLoader.CreateTransferOrderLoader));
      logError({ fn: "getShippingQuotes" }, err);
      return false;
    }
  };
