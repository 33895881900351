export enum ElevioArticleId {
  Acenda = "403",
  AddNewSkus = "3",
  Amazon = "667",
  AmazonBoxLabeling = "788",
  AmazonDirect = "791",
  AmazonFBA = "790",
  AmazonFBAOrderErrors = "902",
  AmazonFBM = "561",
  AmazonFBM2 = "667",
  AmazonShipping = "450",
  AmazonIntegratedTransfers = "701",
  BatteryRequirements = "501",
  BigCommerce = "443",
  BoxContentsLabelingReqs = "557",
  BundleFAQ = "632",
  BundleHowToSetup = "629",
  BundleWhatIs = "630",
  CasePack = "97",
  CargoInsuranceAnnualPolicy = "836",
  CargoInsuranceOverview = "806",
  CargoTotalInsuranceValue = "799",
  CargoTotalValue = "802",
  ChannelAdvisor = "108",
  ClaimsSubmitted = "573",
  CommercialInvoicePackingList = "786",
  CustomsBond = "781",
  CustomsClearanceService = "784",
  CustomsContact = "785",
  CustomsDuties = "794",
  DamagedUnits = "654",
  DangerousGoods = "801",
  DeliverrAPI = "648",
  DeliverrDistribution = "154",
  DeliverrFees = "127",
  DeliverrFreight = "360",
  DimensionalRequirements = "59",
  DoubleChannelConnection = "475",
  Ebay = "401",
  Ecomdash = "163",
  EgdRateTables = "86",
  EIN = "796",
  EstimatedReceivingTimings = "893",
  Etsy = "572",
  Exceptions = "793",
  Facebook = "511",
  FastShippingTags = "85",
  FastTagCoverage = "150",
  FlexportPlus = "841",
  Flieber = "570",
  FreeInbound = "330",
  FreeLTLWeek = "357",
  FreightFullfilmentInbound = "825",
  FulfillmentFees = "673",
  GeekSeller = "124",
  GeneralRateIncrease = "922",
  GoogleShopping = "489",
  GoogleSupportedSalesChannels = "402",
  GoogleTermConditions = "331",
  HowBarcodeProducts = "33",
  HowDoesShipmentForwardingWork = "344",
  InboundingToDeliverr = "119",
  InboundPerformanceCategory = "472",
  InboundsImportProducts = "599",
  InboundsPackages = "514",
  InboundsShipments = "513",
  InternationalOriginPickup = "782",
  InternationalShipping = "560",
  InventoryEcommerceMonthlyReconciliation = "750",
  InventoryEcommerceMonthlyReconciliationUsingLedger = "959",
  InventoryEcommerceTransactionHistory = "751",
  InventoryLevels = "512",
  InventoryLevelsUsingLedger = "512", // replace with actual article id
  InventoryLongTermStorage = "521",
  InventoryNewReportsAvailable = "960",
  Mirakl = "645",
  InventoryMonthlyReconciliation = "510",
  InventoryReconciliation = "562",
  linnworks = "365",
  LiquidsRequirements = "128",
  ListingMirror = "183",
  ListOfSupportedOriginCountries = "891",
  LotTracking = "647",
  InventoryLotTrackingFEFOAllProducts = "883",
  InventoryLotTrackingFEFOAllProductsUsingLedger = "883", // replace with actual article id
  InventoryLotTrackingFEFOSingleProduct = "884",
  InventoryLotTrackingFEFOSingleProductUsingLedger = "884", // replace with actual article id
  InventoryDetailReserveStorageUsingLedger = "884", // replace with actual article id
  MobilityeCommerce = "497",
  MultiFactorAuthentication = "619",
  NCFees = "473",
  NewProduct = "547",
  NonCompliance = "417",
  NonCompliantProduct = "84",
  OrderIngestion = "543",
  OrderNotInDeliverr = "504",
  OrdersAll = "531",
  OrdersShipments = "532",
  PackingInstructions = "461",
  Pallets = "800",
  ParcelCreateBulkCSV = "921",
  ParcelTracking = "935",
  Pipe17 = "419",
  PreparingInstructions = "468",
  PrepBcl = "699",
  PrepKitting = "753",
  PrepFLCUnloading = "911",
  PrepServicesOverview = "553",
  PrepPricingAndBilling = "747",
  ProductCategories = "551",
  ProductHandling = "498",
  ProductPackagingOverView = "541",
  ProductsAllSkusWithAlias = "520",
  ProductsAvailableSkusWithAlias = "519",
  ProductsAvailableSkusWithNoAlias = "518",
  ProductsSKUsWithLinkedAliases = "590",
  ReportingHowTo = "137",
  RateValidityPeriod = "845",
  ReserveStorage = "621",
  ReserveStorageOrdersAndTransfers = "756",
  ReserveStorageOrdersAndTransfersWithSkus = "952",
  RestrictedProducts = "14",
  RestrictedProductsFreight = "843",
  ReturnsAll = "594",
  ReturnsGetStarted = "656",
  ReturnsCreateOrder = "598",
  ReturnsUpdateRMTAddress = "657",
  ReturnsAllBySKU = "593",
  ReturnsClosedBoxSopDetails = "676",
  ReturnsFAQ = "604",
  ReturnsPricingPolicy = "635",
  ReturnsRmtConnectLoop = "660",
  ReturnsRmtConnectReturnly = "664",
  ReturnsRmtConnectAmazon = "665",
  ReturnsRmtConnectReturnGo = "666",
  SafetyStock = "502",
  SalesChannelsWeSupport = "63",
  Sellbrite = "64",
  SellerActive = "121",
  SellerChamp = "421",
  SellerCloud = "145",
  ShipFromMistakes = "345",
  ShippingFreight = "38",
  ShippingPlanReconciliation = "634",
  Shopify = "89",
  ShopifyAutomaticFulfillment = "102",
  ShopifyConfigurations = "492",
  ShopifyMultipleLocations = "493",
  ShortShippedBoxes = "555",
  ShouldIWalmartDirect = "133",
  SimplePrepOld = "708",
  SimplePrep = "910",
  SigningPowerOfAttorney = "779",
  Skubana = "153",
  Skupreme = "571",
  SKUSpecialChars = "537",
  StorageFees = "88",
  StorageJIT = "678",
  StorageInboundsImportProducts = "696",
  StoreAutomator = "595",
  TransfersAll = "679",
  TransferCreateAmazonPreRequirements = "670",
  TransferCreateShippingDeliverrPartner = "670",
  TransferCreateShippingYourCarrier = "670",
  TransferFromReserveStorage = "733",
  StorageTransportationToCrossdock = "694",
  UnavailableForSale = "618",
  UserContactAreas = "658",
  UserManagement = "476",
  WalmartDirect = "132",
  WalmartDirectHow = "129",
  WalmartKeys = "95",
  WalmartMultinodeFaq = "335",
  WfsReplenishmentSettings = "896",
  WhichItemsWalmart2Day = "92",
  Wish = "361",
  WishExpressFAQ = "362",
  WooCommerce = "525",
  Zentail = "116",
  BadgeAvailability = "596",
  FastTagBoost = "592",
  BillingCredits = "681",
  FbaReferenceId = "727",
  FbaIntegratedShipments = "701",
  ReplenishmentTransfersWholesale = "734",
  ReplenishmentNonCompliance = "950",
  ReserveStoragePalletInventory = "741",
  ReserveStorageInboundPackSelection = "755",
  ShippingRestrictionsForFbaInbounds = "760",
  ReserveStorageNonCompliance = "761",
  ReplenishmentOverview = "736",
  ArticleOfIncoporation = "897",
  CreditCardFees = "919",
  SupplyChainFinancing = "885",
}

export enum ElevioModuleId {
  SupportTicket = "8",
}
