import { mustBeDefined } from "common/utils/mustBeDefined";
import { ApiClient } from "../core/ApiClient";
import { ApiClientConfig } from "../core/ApiClientConfig";
import { isAuthenticated } from "../core/authentication";
import { DeliverrOrder } from "@deliverr/fulfillment-order-client";
import {
  ItemizedPriceForMultipleItemsRequest,
  ItemizedPriceForMultipleOrdersRequest,
  ItemizedPriceForOrderRequest,
  TotalRemovalPriceRequest,
  ServiceLevel,
  ItemizedPrice,
  OrderItemizedPriceList,
} from "@deliverr/billing-pricer-client";
import { AccountPricePlanAssignmentResponse } from "./PriceResponseModels/AccountPricePlanAssignmentResponse";

export type PricerClientConfig = ApiClientConfig;

export class PricerClient {
  private apiClient: ApiClient;

  constructor({ baseURL }: PricerClientConfig = { baseURL: mustBeDefined(process.env.PRICER_SERVICE_URL) }) {
    this.apiClient = new ApiClient({
      baseURL,
    });
  }

  async getItemizedPriceForOrderPerUnitDetail(
    itemizedPriceForOrderRequest: ItemizedPriceForOrderRequest
  ): Promise<{ orderItems: ItemizedPrice[] }> {
    return await this.apiClient.post({
      url: `/pricer/getItemizedPriceForOrderPerUnitDetail`,
      body: itemizedPriceForOrderRequest,
      authentication: isAuthenticated,
    });
  }

  async getItemizedPriceForMultipleItems(
    itemizedPriceForMultipleItemsRequest: ItemizedPriceForMultipleItemsRequest
  ): Promise<(ItemizedPrice | null)[]> {
    return await this.apiClient.post({
      url: `/pricer/getItemizedPriceForMultipleItems`,
      body: itemizedPriceForMultipleItemsRequest,
      authentication: isAuthenticated,
    });
  }

  async getItemizedPriceForMultipleOrders(
    itemizedPriceForMultipleOrdersRequest: ItemizedPriceForMultipleOrdersRequest
  ): Promise<(ItemizedPrice | null)[]> {
    return await this.apiClient.post({
      url: `/pricer/getItemizedPriceForMultipleOrders`,
      body: itemizedPriceForMultipleOrdersRequest,
      authentication: isAuthenticated,
    });
  }

  async getItemizedPriceForMultipleOrdersPerUnitDetail(
    itemizedPriceForMultipleOrdersRequest: ItemizedPriceForMultipleOrdersRequest
  ): Promise<OrderItemizedPriceList[]> {
    return await this.apiClient.post({
      url: `/pricer/getItemizedPriceForMultipleOrdersPerUnitDetail`,
      body: itemizedPriceForMultipleOrdersRequest,
      authentication: isAuthenticated,
    });
  }

  async getTotalRemovalPrice(totalRemovalPriceRequest: TotalRemovalPriceRequest): Promise<number> {
    return await this.apiClient.post({
      url: `/pricer/getTotalRemovalPrice`,
      body: totalRemovalPriceRequest,
      authentication: isAuthenticated,
    });
  }

  async getServiceLevelFromOrders(orders: DeliverrOrder[]): Promise<ServiceLevel[]> {
    return await this.apiClient.post({
      url: `/pricer/getServiceLevelFromOrders`,
      body: orders,
      authentication: isAuthenticated,
    });
  }

  async getServiceLevelFromOrder(order: DeliverrOrder): Promise<ServiceLevel> {
    return await this.apiClient.post({
      url: `/pricer/getServiceLevelFromOrder`,
      body: order,
      authentication: isAuthenticated,
    });
  }

  async getAccountPricePlanAssignments(
    sellerId: string,
    cutoffInDays?: number,
    isRateChangeNotificationRequired?: boolean
  ): Promise<AccountPricePlanAssignmentResponse> {
    return await this.apiClient.get({
      url: `/pricePlan/v1/assignments`,
      params: { sellerId, cutoffInDays, rateChangeNotificationRequired: isRateChangeNotificationRequired },
      authentication: isAuthenticated,
    });
  }

  async getRateCardUrl(sellerId: string, referenceDate?: Date): Promise<{ url: string }> {
    return await this.apiClient.get({
      url: `/pricePlan/v1/rateCard`,
      params: { sellerId, referenceDate },
      authentication: isAuthenticated,
    });
  }
}
