import { CountryCode, DeliverrAddress } from "@deliverr/commons-objects";

import log from "Logger";
import { conditionalPricingRegistry } from "./utils/ConditionalPricing";
import { verifyProductProps } from "./utils/product/verifyProductProps";
import { getDestinationParams } from "./utils/product/getDestinationParams";
import { destinationByService } from "./utils/serviceLevels/destinationByService";
import { pricerClient } from "common/clients/instances";
import { verifyMultipleProductProps } from "./utils/product/verifyMultipleProductProps";
import {
  ItemizedPrice,
  OrderItemizedPriceList,
  PriceItem,
  ServiceLevel,
  RemovalItemType,
} from "@deliverr/billing-pricer-client";
import { isInternationalServiceLevel } from "./utils/serviceLevels/serviceLevels";

interface GetEstimatedCostsProps {
  items: PriceItem[];
  sellerId: string;
  showNewPrices?: boolean;
  address?: DeliverrAddress;
  serviceLevel: ServiceLevel;
  countryCode?: CountryCode;
  orderShipTime?: Date;
}

export const getEstimatedCosts = async ({
  items,
  showNewPrices = false,
  address,
  serviceLevel,
  countryCode,
  sellerId,
  orderShipTime,
}: GetEstimatedCostsProps) => {
  if (isInternationalServiceLevel(serviceLevel) && !countryCode) {
    return undefined;
  }

  const referenceDate = getReferenceDateForEstimate(showNewPrices);
  const itemSpec: PriceItem[] | undefined = verifyProductProps(items);

  if (!itemSpec || itemSpec.length === 0) {
    return undefined;
  }

  try {
    const priceRes = await pricerClient.getItemizedPriceForOrderPerUnitDetail({
      items: itemSpec,
      serviceLevel,
      referenceDate: orderShipTime ?? referenceDate ?? new Date(),
      destination: address ? getDestinationParams(address) : destinationByService(serviceLevel),
      isRemoval: false,
      countryCode: isInternationalServiceLevel(serviceLevel) ? countryCode : undefined,
      sellerId,
    });

    // combine prices to avoid regressions with FE
    const priceResConverted =
      priceRes.orderItems.reduce(
        (acc, curr) => {
          return {
            basePrice: acc.basePrice + curr.basePrice,
            surcharges: [...acc.surcharges, ...curr.surcharges],
            totalPrice: acc.totalPrice + curr.totalPrice,
          };
        },
        { basePrice: 0, surcharges: [], totalPrice: 0 }
      ) ?? [];

    return priceResConverted;
  } catch (err) {
    log.warn({ err, itemSpec }, "error getting unit price");
    return;
  }
};

export const getMultipleEstimatedCosts = async ({
  items,
  showNewPrices = false,
  address,
  serviceLevel,
  countryCode,
  sellerId,
}: GetEstimatedCostsProps) => {
  if (isInternationalServiceLevel(serviceLevel) && !countryCode) {
    return undefined;
  }

  const referenceDate = getReferenceDateForEstimate(showNewPrices);
  const priceItems = verifyMultipleProductProps(items);
  const validPriceItems = priceItems.filter((item): item is PriceItem => item !== undefined);

  try {
    const shouldMakeRequest = validPriceItems.length > 0;
    const priceRes = shouldMakeRequest
      ? await pricerClient.getItemizedPriceForMultipleItems({
          items: validPriceItems,
          serviceLevel,
          referenceDate: referenceDate ?? new Date(),
          destination: address ? getDestinationParams(address) : destinationByService(serviceLevel),
          isRemoval: false,
          countryCode: isInternationalServiceLevel(serviceLevel) ? countryCode : undefined,
          sellerId,
        })
      : [];

    const dskuToItemizedPrice = priceRes.reduce<{ [dsku: string]: ItemizedPrice | null }>((acc, item, i) => {
      acc[validPriceItems[i].dsku] = item;
      return acc;
    }, {});

    return priceItems.map((item) => (item !== undefined ? dskuToItemizedPrice[item.dsku] : null));
  } catch (err) {
    log.warn({ err, priceItems, validPriceItems }, "error getting price for multiple items");
    return;
  }
};

interface GetMultipleBundleEstimatedCostsProps {
  items: PriceItem[][];
  showNewPrices?: boolean;
  address?: DeliverrAddress;
  serviceLevel: ServiceLevel;
  countryCode?: CountryCode;
  sellerId?: string;
}

export const getMultipleBundleEstimatedCosts = async ({
  items,
  showNewPrices = false,
  address,
  serviceLevel,
  countryCode,
  sellerId,
}: GetMultipleBundleEstimatedCostsProps) => {
  if (isInternationalServiceLevel(serviceLevel) && !countryCode) {
    return undefined;
  }

  const referenceDate = getReferenceDateForEstimate(showNewPrices);
  const itemSpec: PriceItem[][] = [];
  const nullIndices: number[] = [];
  items.forEach((item, index) => {
    const verifiedItem = verifyProductProps(item);
    if (verifiedItem) {
      itemSpec.push(verifiedItem);
    } else {
      nullIndices.push(index);
    }
  });

  try {
    const priceRes = await pricerClient.getItemizedPriceForMultipleOrdersPerUnitDetail({
      orders: itemSpec,
      serviceLevel,
      referenceDate: referenceDate ?? new Date(),
      destination: address ? getDestinationParams(address) : destinationByService(serviceLevel),
      isRemoval: false,
      countryCode: isInternationalServiceLevel(serviceLevel) ? countryCode : undefined,
      sellerId,
    });
    const prices: (OrderItemizedPriceList | null)[] = priceRes;
    nullIndices.forEach((index) => {
      prices?.splice(index, 0, null);
    });
    return prices;
  } catch (err) {
    log.warn({ err, itemSpec }, "error getting price for bundle list");
    return;
  }
};
interface GetRemovalCostProps {
  priceItems: PriceItem[];
  address: DeliverrAddress;
  removalType: RemovalItemType;
  showNewPrices?: boolean;
  sellerId: string;
}

export const getRemovalCosts = async ({
  priceItems,
  address,
  removalType,
  showNewPrices,
  sellerId,
}: GetRemovalCostProps) => {
  const destination = getDestinationParams(address);
  const referenceDate = getReferenceDateForEstimate(showNewPrices);
  const itemSpec: PriceItem[] | undefined = verifyProductProps(priceItems);
  if (!itemSpec) {
    return undefined;
  }
  try {
    const removalCost = await pricerClient.getTotalRemovalPrice({
      priceItems: itemSpec,
      type: removalType,
      referenceDate: referenceDate ?? new Date(),
      destination,
      sellerId,
    });
    return {
      basePrice: removalCost,
      surcharges: [],
      totalPrice: removalCost,
    };
  } catch (err) {
    log.warn({ err }, "error getting removal price");
    return;
  }
};

const getReferenceDateForEstimate = (showNewPrices?: boolean): Date | undefined => {
  // if the seller has conditional pricing, we should use the comparison date to fetch the "old" rates
  if (conditionalPricingRegistry.hasActivePricing()) {
    return showNewPrices
      ? conditionalPricingRegistry.getActivePricingReferenceDate()
      : conditionalPricingRegistry.getActivePricingComparisonDate();
  }
  return new Date();
};
