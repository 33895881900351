import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { multiply } from "lodash/fp";
import { useMemo } from "react";
import { getStorageInboundDetail } from "../store";
import { STORAGE_INBOUNDS_RATE } from "storage/inbounds/StorageInboundRatesConstant";
import { InboundShipmentStatus } from "common/clients/inbound/InboundShipment/InboundShipmentStatus";
import { BasicTableColumn, useRenderMoneyCell } from "common/components/BasicTable";
import { CostTableSubTableConfig } from "inbounds/steps/ship/finalize/CostTable";
import { Column } from "react-table";
import { getStorageInboundRates } from "storage/inbounds/utils/getStorageInboundRates";
import { CarrierType, PalletSize, ShipmentType } from "@deliverr/storage-client";
import { StorageShipmentConfirmationMessages } from "./StorageShipmentConfirmationMessages";
import { usePricingSummaryLabels } from "storage/inbounds/create/confirmShipment/pricingSummary/usePricingSummaryLabels";
import { ContainerSizeToDisplayValue } from "storage/inbounds/create/confirmShipment/ContainerSizeToDisplayValue";

export const useShipmentConfirmationCostTable = () => {
  const { formatMessage } = useIntl();
  const { storageInboundOrder } = useSelector(getStorageInboundDetail);
  const { storageFeePerMonth, containerUnloadingFeeBySize, palletInboundFeeBySize } = getStorageInboundRates();
  const isShipmentCompleted = InboundShipmentStatus.COMPLETED === storageInboundOrder.status;
  const containerSizeValue =
    storageInboundOrder.containerSize && ContainerSizeToDisplayValue[storageInboundOrder.containerSize];
  const labels = usePricingSummaryLabels(containerSizeValue, storageInboundOrder?.freightOrderDetails?.type);

  const costTitle = formatMessage(
    isShipmentCompleted
      ? StorageShipmentConfirmationMessages.TOTAL_COST_TITLE
      : StorageShipmentConfirmationMessages.ESTIMATED_TOTAL_COST_TITLE
  );

  const freightEstimatedCost =
    storageInboundOrder?.carrierType === CarrierType.DELIVERR
      ? Number(storageInboundOrder?.freightOrderDetails?.estimatedCost ?? 0)
      : 0;

  const estimatedTotalCost =
    freightEstimatedCost +
    Number(storageInboundOrder.estimatedInboundFee) +
    (Number(storageInboundOrder.estimatedStorageFee) ||
      multiply(STORAGE_INBOUNDS_RATE.storageFeePerMonth, storageInboundOrder.palletCount ?? 1));

  // TODO assign totalCost to cost based on status
  const cost = estimatedTotalCost;

  const renderMoneyCell = useRenderMoneyCell();

  const columns: Column<BasicTableColumn>[] = [
    {
      Header: labels.tableTitle,
      accessor: "rowLabel",
    },
    {
      Header: formatMessage(StorageShipmentConfirmationMessages.PRICING_SUMMARY_TABLE_UNIT_COST_TITLE),
      accessor: "unitCost",
      Cell: renderMoneyCell,
    },
    {
      Header: formatMessage(StorageShipmentConfirmationMessages.PRICING_SUMMARY_TABLE_AMOUNT_TITLE),
      accessor: "amount",
      Cell: renderMoneyCell,
    },
  ];

  const subTables: CostTableSubTableConfig[] = [];

  const data: BasicTableColumn[] = useMemo(() => {
    let rows: BasicTableColumn[];
    if (storageInboundOrder.shipmentType === ShipmentType.FLOOR_LOADED_CONTAINER) {
      rows = [
        {
          rowLabel: labels.inboundHandlingFloorLoadRowLabel,
          unitCost: containerUnloadingFeeBySize[storageInboundOrder.containerSize!]?.fee,
          amount: containerUnloadingFeeBySize[storageInboundOrder.containerSize!]?.fee,
        },
      ];
    } else {
      rows = [
        {
          rowLabel: labels.inboundHandlingRowLabel,
          unitCost: palletInboundFeeBySize[PalletSize.STANDARD_60IN]?.fee,
          amount: multiply(
            storageInboundOrder.palletCount!,
            palletInboundFeeBySize[PalletSize.STANDARD_60IN]?.fee ?? 0
          ),
        },
      ];
    }

    if (storageInboundOrder?.carrierType === CarrierType.DELIVERR) {
      rows.push({
        rowLabel: labels.deliverrFreightLabel,
        unitCost: "-",
        amount: storageInboundOrder.freightOrderDetails?.estimatedCost ?? 0,
      });
    }

    rows.push({
      rowLabel: labels.palletStorageRowLabel,
      unitCost: storageFeePerMonth,
      amount:
        storageInboundOrder.estimatedStorageFee ??
        multiply(storageInboundOrder.palletCount! ?? 1, storageFeePerMonth ?? 0),
    });
    rows.push({
      rowLabel: costTitle,
      unitCost: undefined,
      amount: cost,
    });
    return rows;
  }, [
    containerUnloadingFeeBySize,
    cost,
    costTitle,
    labels.inboundHandlingFloorLoadRowLabel,
    labels.inboundHandlingRowLabel,
    labels.palletStorageRowLabel,
    labels.deliverrFreightLabel,
    palletInboundFeeBySize,
    storageFeePerMonth,
    storageInboundOrder.containerSize,
    storageInboundOrder.estimatedStorageFee,
    storageInboundOrder.palletCount,
    storageInboundOrder.shipmentType,
  ]);
  const aggregatorOptions = { totalRowLabel: costTitle };

  subTables.push({ data, columns, aggregatorOptions });

  return {
    cost,
    costTitle,
    subTables,
  };
};
