/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { FocusEvent, useCallback, useEffect, useState } from "react";

import { MIN_NATIONAL_CASE_PER_SKU, MIN_NATIONAL_QTY_PER_SKU } from "inbounds/InboundValidationUtils";
import { SelectedProductProps } from "./SelectedProduct";
import { calculateAvailableQuantity } from "common/utils/product/calculateAvailableQuantity";
import { calculateReserveStorageAvailableQuantity } from "common/utils/product/calculateReserveStorageAvailableQuantity";
import { isNil, orderBy } from "lodash";
import { useMount } from "react-use";
import { calculateECOMReplenishmentRecommendation } from "common/utils/product/calculateECOMReplenishmentRecommendation";
import { isSellerSendingExcessInventoryToEcom } from "../../utils/product/isSellerSendingExcessInventoryToEcom";
import { SIOC_DIMENSIONS_LIMITS } from "../../constants/inbounds/inbounds.const";

const shouldInboundAsSIOCHelper = (dimensions: (number | undefined)[], weight: number | undefined, dimensionsLimit) => {
  if (!dimensions || dimensions.length !== 3 || !weight) {
    return true;
  }

  const [length, width, height] = dimensions;

  return (
    (weight && weight > dimensionsLimit.weight) ||
    (length && length > dimensionsLimit.length) ||
    (width && width > dimensionsLimit.width) ||
    (height && height > dimensionsLimit.height)
  );
};

export const useSelectedProduct = (props: SelectedProductProps) => {
  const {
    productIndex,
    isInbound,
    useCasePack,
    toggleIsUnapprovedBattery,
    product,
    isReserveStorage,
    showCasePackSelectionDropdown,
    selectedProductCasePack,
    disabled,
    selectedProductLotNumber,
    onQtyChange,
    onNumberOfCasesChange,
  } = props;
  const {
    caseQty,
    dsku,
    numberOfCases,
    qty: selectedQty,
    actualCaseQty,
    arePacksLoading,
    weight,
    length,
    width,
    height,
  } = product;

  const [replenishmentRecommendation, setReplenishmentRecommendation] = useState<number | undefined>(undefined);
  const [shouldShowInputTooltip, setShowInputTooltip] = useState<boolean>(productIndex === 0);

  const invalidQty = Boolean(isInbound && !useCasePack && selectedQty < MIN_NATIONAL_QTY_PER_SKU);
  const invalidCasePackQty = Boolean(
    isInbound && useCasePack && numberOfCases && caseQty && numberOfCases < MIN_NATIONAL_CASE_PER_SKU
  );

  const showAlreadyExcessInventory = replenishmentRecommendation === 0;
  const showExcessInventory = isSellerSendingExcessInventoryToEcom(
    showAlreadyExcessInventory,
    replenishmentRecommendation,
    selectedQty
  );

  const anyStorageRecommendationVisible = showAlreadyExcessInventory || showExcessInventory;

  const handleInputFocus = useCallback((event: FocusEvent<HTMLInputElement>) => {
    // selects entire input value on focus
    event.target?.select();
  }, []);

  const handleInputChange = (event: React.FormEvent<HTMLInputElement>) => {
    const isInvalidInput = !event.currentTarget.value || Number(event.currentTarget.value) === 0;
    setShowInputTooltip(isInvalidInput);
    if (useCasePack) {
      onNumberOfCasesChange?.(event);
    } else {
      onQtyChange?.(event);
    }
  };

  useMount(async () => {
    if (props.showStorageRecommendation) {
      const ecomReplenishmentRecommendation = await calculateECOMReplenishmentRecommendation(product.dsku);
      setReplenishmentRecommendation(ecomReplenishmentRecommendation);
    }
  });

  const { showStorageRecommendationAlert } = props;

  useEffect(() => {
    if (anyStorageRecommendationVisible && showStorageRecommendationAlert) {
      showStorageRecommendationAlert();
    }
  }, [anyStorageRecommendationVisible, showStorageRecommendationAlert]);

  const handleBatteryToggle = (isUnapprovedBattery: boolean) => toggleIsUnapprovedBattery?.(dsku, isUnapprovedBattery);

  const isBrandedPackaging = product.brandedPackaging;
  const [showUpdateDimensionsModal, setShowUpdateDimensionsModal] = useState(false);

  const isPackTemplateUnselected = showCasePackSelectionDropdown && isNil(selectedProductCasePack);

  const canToggleBattery = !!toggleIsUnapprovedBattery && !isBrandedPackaging;

  const isCaseCountInputDisabled = disabled || isPackTemplateUnselected;

  const isCaseQtyInputDisabled = disabled || arePacksLoading;

  const dimensions = orderBy([length, width, height], (a) => a, "desc");

  const shouldInboundAsSIOC = shouldInboundAsSIOCHelper(dimensions, weight, SIOC_DIMENSIONS_LIMITS);

  const onSelectLotNumber = (lotNumber?: string) => {
    props.onLotNumberChange?.(dsku, lotNumber);
  };
  return {
    canToggleBattery,
    handleBatteryToggle,
    handleInputFocus,
    invalidQty,
    invalidCasePackQty,
    actualCaseQty,
    availableQty: isReserveStorage
      ? calculateReserveStorageAvailableQuantity(product, selectedProductLotNumber)
      : calculateAvailableQuantity(product),
    showUpdateDimensionsModal,
    setShowUpdateDimensionsModal,
    isPackTemplateUnselected,
    isCaseCountInputDisabled,
    isBrandedPackaging,
    showAlreadyExcessInventory,
    showExcessInventory,
    isCaseQtyInputDisabled,
    onSelectLotNumber,
    shouldShowInputTooltip,
    handleInputChange,
    shouldInboundAsSIOC,
    SIOC_DIMENSIONS_LIMITS,
  };
};
