import {
  packingOptionsInitialState,
  packingOptionsReducer,
  PackingOptionsState,
} from "../packingOptions/store/PackingOptionsState";
import { combineReducers } from "redux";
import { FbaV3ActionTypes } from "./FbaV3Actions";

export interface FbaV3CreateState {
  packingOptionsState: PackingOptionsState;
}

export const fbaV3CreateInitialState: FbaV3CreateState = {
  packingOptionsState: packingOptionsInitialState,
};

export const fbaV3CreateStateReducer = (state, action) => {
  if (action.type === FbaV3ActionTypes.RESET_FBA_V3_STATE) {
    state = fbaV3CreateInitialState;
  }

  return stateSpecificReducers(state, action);
};

const stateSpecificReducers = combineReducers<FbaV3CreateState>({
  packingOptionsState: packingOptionsReducer,
});
