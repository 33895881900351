import { defineMessages, MessageDescriptor } from "react-intl";

export const UnexpectedSkuBladeMessages: Record<string, MessageDescriptor> = defineMessages({
  STATUS: {
    id: "inbounds.unexpectedSkuBlade.status",
    defaultMessage: "Units received",
  },
  STATUS_MESSAGE: {
    id: "inbounds.unexpectedSkuBlade.statusMessage",
    defaultMessage:
      "Deliverr detected an unexpected product in your shipping plan. This SKU was identified and received.",
  },
  DETAILS_TITLE: {
    id: "inbounds.unexpectedSkuBlade.detailsTitle",
    defaultMessage: "Details",
  },
  DETAILS: {
    id: "inbounds.unexpectedSkuBlade.details",
    defaultMessage: "Deliverr identified the unexpected product on this shipping plan as the following SKU.",
  },
  BARCODE: {
    id: "inbounds.unexpectedSkuBlade.barcode",
    defaultMessage: "Barcode",
  },
  VERIFIED_SKU: {
    id: "inbounds.unexpectedSkuBlade.verifiedSku",
    defaultMessage: "SKU",
  },
  NONCOMPLIANT_PRODUCT: {
    id: "inbounds.unexpectedSkuBlade.nonCompliantProduct",
    defaultMessage: "Product",
  },
  CONTACT_SUPPORT: {
    id: "inbounds.unexpectedSkuBlade.contactSupport",
    defaultMessage: "Not the right SKU? {contactSupport}",
  },
  CONTACT_SUPPORT_LINK: {
    id: "inbounds.unexpectedSkuBlade.contactSupportLink",
    defaultMessage: "Contact support",
  },
  LEARN_MORE: {
    id: "inbounds.unexpectedSkuBlade.learnMore",
    defaultMessage: "Learn more about {unexpectedBarcodes}.",
  },
  UNEXPECTED_BARCODES_LINK: {
    id: "inbounds.unexpectedSkuBlade.unexpectedBarcodesLink",
    defaultMessage: "unexpected products",
  },
  BARCODE_FOUND_IN_BOX: {
    id: "inbounds.unexpectedSkuBlade.barcodeFoundInBox",
    defaultMessage: "This barcode was found in box {cdsku}.",
  },
  QUANTITY_RECEIVED: {
    id: "inbounds.unexpectedSkuBlade.quantityReceived",
    defaultMessage: "Quantity received",
  },
  QUANTITY_RECEIVED_WITH_UNITS: {
    id: "inbounds.unexpectedSkuBlade.quantityReceivedWithUnits",
    defaultMessage: "{quantityReceived} {unitCount} {units}",
  },
});
