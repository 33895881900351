import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";
import { useState } from "react";
import { Carrier, CarrierAccount } from "@deliverr/replenishment-client";
import { WholesaleShipmentType, WholesaleShippingMethod } from "@deliverr/wholesale-client";
import { transferCreateChooseShippingConfig } from "transfers/create/actions";
import { CarrierAccountModalMode, ReplenishmentCarrierAccountModalId } from "../components/modals/CarrierAccountModal";
import { useModal } from "common/hooks/useModal";

const getInitalLabelProvider = (
  selectedCarrierAccount: CarrierAccount | undefined,
  shippingMethod: WholesaleShippingMethod | undefined
) => {
  return selectedCarrierAccount && shippingMethod === WholesaleShippingMethod.COLLECT
    ? WholesaleShippingMethod.COLLECT
    : WholesaleShippingMethod.SELLER;
};

export const getCarrierLabel = (carrier: Carrier) => {
  switch (carrier) {
    case Carrier.FEDEX:
      return "FedEx";
    case Carrier.UPS:
      return "UPS";
    default:
      return "Unknown Carrier";
  }
};

export const useSelectLabelProvider = () => {
  const dispatch = useDispatch();
  const { carrierAccounts, selectedCarrierAccount, shippingMethod } = useSelector(selectTransferCreate);
  const [labelProvider, setLabelProvider] = useState<WholesaleShippingMethod>(
    getInitalLabelProvider(selectedCarrierAccount, shippingMethod)
  );
  const [modalMode, setModalMode] = useState(CarrierAccountModalMode.CREATE);
  const [targetCarrierAccount, setTargetCarrierAccount] = useState<CarrierAccount | undefined>(undefined);
  const { showModal, hideModal, isActive: isCarrierAccountModalVisible } = useModal(ReplenishmentCarrierAccountModalId);

  const onOpenModal = (currentModalMode: CarrierAccountModalMode, carrierAccount?: CarrierAccount) => {
    setModalMode(currentModalMode);
    setTargetCarrierAccount(carrierAccount);
    showModal();
  };

  const onCloseModal = () => {
    setTargetCarrierAccount(undefined);
    hideModal();
  };

  const hasCarrierAccounts = !isEmpty(carrierAccounts);
  const onChangeLabelProvider = (value: WholesaleShippingMethod) => {
    setLabelProvider(value);
    dispatch(
      transferCreateChooseShippingConfig({
        shippingMethod: value,
        shipmentType: WholesaleShipmentType.PARCEL,
      })
    );
  };

  return {
    isCarrierAccountModalVisible,
    carrierAccounts,
    targetCarrierAccount,
    selectedCarrierAccount,
    hasCarrierAccounts,
    labelProvider,
    modalMode,
    onOpenModal,
    onCloseModal,
    onChangeLabelProvider,
  };
};
