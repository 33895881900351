import classNames from "classnames/dedupe";
import { Anchor, Box, Icon, Switch, Text, ThemeProps } from "common/components/ui";
import Tooltip from "rc-tooltip";
import React, { FC } from "react";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";

import { ProductCasePack, ProductCasePackData } from "@deliverr/commons-clients/lib/product/ProductCasePack";
import styled from "@emotion/styled";
import cx from "classnames";
import { Dash } from "common/components/Dash";
import { InputError } from "common/components/InputError";
import { ProductCategoryLabel } from "common/components/ProductCategoryLabel";
import { ProductLink } from "common/components/ProductLink";
import { Appearance, TextAlign } from "common/components/ui/shared";
import { RESERVE_STORAGE_CONTACT_EMAIL } from "inbounds/constants/contactConstants";
import { getProductSku } from "inbounds/steps/ship/utils/getProductSku";
import { UpdateDimensionsModal } from "inventory/UpdateDimensionsModal/UpdateDimensionsModal";
import { values } from "lodash";
import { Currency, CurrencyFormat } from "../Currency";
import { BoxDimensions } from "../EstimatedCosts/BoxDimensions";
import { allDimensionsValid } from "../EstimatedCosts/CostEstimatorModalReducer";
import { ProductCasePackSelect } from "../ProductCasePackSelect";
import { PackInfo } from "../ProductSearch/Packs/components/PackInfo";
import cls from "./ProductChooser.less";
import { SelectedProductData } from "./SelectedProductData";
import { ShowWarningForProductFn } from "./ShowWarningForProductFn";
import { useSelectedProduct } from "./useSelectedProduct";
import { NumberInput } from "../NumberInput";
import { ProductEcomDetails } from "../ProductSearch/Packs/components/ProductEcomDetails";
import { ProductLotNumberSelect } from "../ProductLotNumberSelect";
import { ATPDetails } from "common/clients/inventory/ATP/ATPDetails";
import { ElevioArticleId, ElevioBorderlessButton } from "common/elevio";

export interface SelectedProductProps {
  productIndex: number;
  product: SelectedProductData;
  canRemove?: boolean;
  disabled?: boolean;
  hasMaxValue?: boolean;
  invalidDskus?: string[];
  isInbound?: boolean;
  isOrder?: boolean;
  isPromotional?: boolean;
  isUnapprovedBattery?: boolean;
  minUnits?: number;
  onCaseQtyChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  onNumberOfCasesChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  onQtyChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  onRemoveItemClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  ShowWarningForProductFn?: ShowWarningForProductFn;
  showTooltip?: boolean;
  toggleIsUnapprovedBattery?: (dsku: string, isUnapprovedBattery: boolean) => void;
  useCasePack: boolean;
  isCaseQtyReadonly?: boolean;
  showEstimatedCosts?: boolean;
  shouldShowAvailableQty?: boolean;
  updateProductDims?: (dsku: string, dims: BoxDimensions) => void;
  isReserveStorage?: boolean;
  hideUnitsColumn?: boolean;
  shouldHideBatteryColumn?: boolean;
  showCasePackSelectionDropdown?: boolean;
  selectedProductCasePack?: ProductCasePack;
  onChangeSelectedProductCasePack?: (productCasePack: ProductCasePack) => void;
  onNewCasePackFormSubmit?: (productCasePackData: ProductCasePackData) => void;
  showOnlyPacks?: boolean;
  checkCaseConfigValidation?: boolean;
  shouldHideCasepackSingleUnitWarning?: boolean;
  shouldShowBrandedPackagingAlert?: boolean;
  showStorageRecommendation?: boolean;
  showStorageRecommendationAlert?: () => void;
  shouldShowEcomDetailsForStorageProducts?: boolean;
  shouldShowLotNumberDropdown?: boolean;
  selectedProductLotNumberList?: ATPDetails[];
  onLotNumberChange?: (dsku: string, lotNumber?: string) => void;
  selectedProductLotNumber?: ATPDetails;
  shouldShowSIOCWarning?: boolean;
  shouldShowKitHint?: boolean;
}

const StyledDash = styled(Dash)<ThemeProps>(
  ({ theme }) => `
    color: ${theme.colors.NEUTRAL[200]};
  `
);

const ContactUsLink = styled(Anchor)`
  font-size: inherit;
  cursor: pointer;
`;

const StyledNumberInput = styled(NumberInput)<ThemeProps>(
  ({ theme }) => `
  margin-top: ${theme.spacing.S1};
`
);

const SelectedProduct: FC<SelectedProductProps> = (props) => {
  const { formatMessage } = useIntl();
  const {
    product,
    canRemove,
    disabled,
    hasMaxValue,
    invalidDskus,
    isPromotional,
    isUnapprovedBattery,
    minUnits,
    onCaseQtyChange,
    onRemoveItemClick,
    showTooltip,
    useCasePack: shouldUseCasePack,
    showEstimatedCosts,
    shouldShowAvailableQty = true,
    updateProductDims,
    hideUnitsColumn,
    isCaseQtyReadonly,
    shouldHideBatteryColumn,
    showCasePackSelectionDropdown,
    selectedProductCasePack,
    onChangeSelectedProductCasePack,
    onNewCasePackFormSubmit,
    showOnlyPacks,
    checkCaseConfigValidation,
    shouldHideCasepackSingleUnitWarning,
    shouldShowBrandedPackagingAlert,
    shouldShowEcomDetailsForStorageProducts,
    shouldShowLotNumberDropdown,
    selectedProductLotNumber,
    selectedProductLotNumberList,
    shouldShowSIOCWarning,
    shouldShowKitHint,
  } = props;
  const { caseQty, dsku, hazmatInformation, numberOfCases, name: productName, qty: selectedQty, isKit } = product;
  const {
    availableQty,
    canToggleBattery,
    shouldShowInputTooltip,
    handleBatteryToggle,
    handleInputFocus,
    handleInputChange,
    // TODO: uncomment when re-enabling below messages once national coverage is available again
    // https://app.asana.com/0/1117755068495017/1202407205989859/f
    // invalidQty,
    // invalidCasePackQty,
    actualCaseQty,
    showUpdateDimensionsModal,
    setShowUpdateDimensionsModal,
    isPackTemplateUnselected,
    isCaseCountInputDisabled,
    isBrandedPackaging,
    showAlreadyExcessInventory,
    showExcessInventory,
    isCaseQtyInputDisabled,
    onSelectLotNumber,
    shouldInboundAsSIOC,
    SIOC_DIMENSIONS_LIMITS,
  } = useSelectedProduct(props);

  const errorMessageForProduct = props.ShowWarningForProductFn?.(product);
  return (
    <div>
      {/*
      Promotional inbounds currently disable remove item button. Extra margin is required to align inputs
      If this changes in the future, remove the promotionalInbound class
      */}
      <div className={classNames(cls.selectedProductRow, { [cls.promotionalInbound]: isPromotional })} role="row">
        <div className={cls.productColumn}>
          <div data-testid="product-name" className={cls.productName}>
            <ProductLink product={product} shouldOpenInNewTab>
              <Text bold appearance="PRIMARY" as="span">
                {productName}
              </Text>
            </ProductLink>
          </div>
          <div className={cls.productDetails}>
            <span className={cls.selectedProductSku}>{getProductSku(product)}</span>
            {shouldShowAvailableQty && (
              <span className={cls.selectedProductQty} data-testid="availableQty">
                <FormattedMessage
                  id="inbounds.selectedProduct.availableQty"
                  defaultMessage="{qty} available"
                  values={{ qty: <FormattedNumber value={availableQty} /> }}
                />
              </span>
            )}
            <ProductCategoryLabel product={product} />
          </div>
          {shouldShowKitHint && isKit ? (
            <div className={cls.productDetails}>
              <span className={cls.selectedProductSku}>
                <FormattedMessage id="inbounds.selectedProduct.isKit" defaultMessage="Kitted product" />
              </span>
            </div>
          ) : null}
          {showOnlyPacks && (
            <div className={cls.productDetails}>
              {" "}
              <PackInfo product={product} checkCaseConfigValidation={checkCaseConfigValidation} />
            </div>
          )}
          {shouldShowEcomDetailsForStorageProducts && <ProductEcomDetails product={product} />}
        </div>

        {!shouldHideBatteryColumn && (
          <div className={cls.batteryColumn}>
            {canToggleBattery ? (
              <Switch
                checked={!!hazmatInformation || !!isUnapprovedBattery}
                disabled={!!hazmatInformation}
                onChange={handleBatteryToggle}
                label={
                  !!hazmatInformation || isUnapprovedBattery ? (
                    <FormattedMessage id="yes" defaultMessage="Yes" />
                  ) : (
                    <FormattedMessage id="no" defaultMessage="No" />
                  )
                }
              />
            ) : (
              <StyledDash />
            )}
          </div>
        )}
        {shouldShowLotNumberDropdown && (
          <Box marginTop={"S1"}>
            <ProductLotNumberSelect
              productLotNumbers={selectedProductLotNumberList ?? []}
              onSelectLotNumber={onSelectLotNumber}
              selectedLotNumber={selectedProductLotNumber}
            />
          </Box>
        )}

        {shouldUseCasePack && (
          <>
            {showCasePackSelectionDropdown ? (
              <Box marginTop={"S1"}>
                <ProductCasePackSelect
                  product={product}
                  productCasePacks={values(product.productCasePackOptions)}
                  selectedCasePack={selectedProductCasePack}
                  onSelectCasePack={onChangeSelectedProductCasePack}
                  onNewCasePackFormSubmit={onNewCasePackFormSubmit}
                  includeNewCasePackOption
                />
              </Box>
            ) : (
              <div className={cls.caseQtyColumn}>
                <Tooltip
                  placement="bottom"
                  visible={caseQty === 0 && showTooltip}
                  overlay={
                    <FormattedMessage
                      id="inbounds.selectedProduct.noneUnitPerCase"
                      defaultMessage={"You must add at least 1 unit"}
                    />
                  }
                >
                  <StyledNumberInput
                    readOnly={isCaseQtyReadonly}
                    data-sku={dsku}
                    disabled={isCaseQtyInputDisabled}
                    formNoValidate
                    min={1}
                    decimalScale={0}
                    onChange={onCaseQtyChange}
                    onFocus={handleInputFocus}
                    value={caseQty}
                    aria-label={formatMessage({
                      id: "productChooser.selectedProduct.caseQuantity",
                      defaultMessage: "Case Quantity",
                    })}
                    data-testid={"selected-product-case-qty-input"}
                  />
                </Tooltip>
                <span className={cls.caseQtyTimes}>*</span>
              </div>
            )}
          </>
        )}

        {!hideUnitsColumn && (
          <div className={cls.qtyColumn}>
            <Tooltip
              placement="bottom"
              visible={(shouldUseCasePack ? numberOfCases : selectedQty) === 0 && showTooltip && shouldShowInputTooltip}
              overlay={
                <FormattedMessage
                  id="inbounds.selectedProduct.noQuantity"
                  defaultMessage={"You must add at least 1 {type}"}
                  values={{
                    type: shouldUseCasePack ? "case" : "unit",
                  }}
                />
              }
            >
              <StyledNumberInput
                data-sku={dsku}
                disabled={isCaseCountInputDisabled}
                formNoValidate
                min={minUnits}
                max={
                  hasMaxValue
                    ? shouldUseCasePack
                      ? Math.floor(availableQty / (caseQty ?? 1))
                      : availableQty
                    : undefined
                }
                decimalScale={0}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                value={shouldUseCasePack ? (isPackTemplateUnselected ? " - " : numberOfCases) : selectedQty ?? ""}
                aria-label={
                  shouldUseCasePack
                    ? formatMessage({
                        id: "productChooser.selectedProduct.caseCount",
                        defaultMessage: "Number of boxes",
                      })
                    : formatMessage({
                        id: "productChooser.selectedProduct.quantity",
                        defaultMessage: "Quantity",
                      })
                }
                data-testid={shouldUseCasePack ? "selected-product-case-count-input" : undefined}
              />
            </Tooltip>
          </div>
        )}

        {shouldUseCasePack && (
          <div className={cls.totalQtyColumn}>
            {!showCasePackSelectionDropdown ? <span className={cls.totalQtyEquals}>=</span> : null}
            <span className={cls.totalQtyValue} data-testid={`totalQty-${dsku}`}>
              {isPackTemplateUnselected ? "-" : selectedQty}
            </span>
          </div>
        )}

        {showEstimatedCosts && (
          <div className={cls.costEstimateColumn}>
            <Text appearance="INFO" textAlign={TextAlign.end}>
              {product.estimatedCosts && (
                <Currency currency={CurrencyFormat.USD} value={product.estimatedCosts?.totalPrice} />
              )}
              {!allDimensionsValid(product) && (
                <>
                  <UpdateDimensionsModal
                    dsku={product.dsku}
                    productName={product.name}
                    dimensions={product}
                    updateProductDims={updateProductDims!}
                    showUpdateDimensionsModal={showUpdateDimensionsModal}
                    setShowUpdateDimensionsModal={setShowUpdateDimensionsModal}
                  />
                  <a onClick={() => setShowUpdateDimensionsModal(true)}>
                    <FormattedMessage id={"UpdateDimensionsLink.text"} defaultMessage={"Add details"} />
                  </a>
                </>
              )}
              {!product.estimatedCosts && allDimensionsValid(product) && "..."}
            </Text>
          </div>
        )}

        <div className={cls.removeItemColumn}>
          <div
            className={classNames(cls.removeButton, {
              [cls.disabledRemoveButton]: disabled,
              [cls.hiddenButton]: !canRemove,
            })}
            role="button"
            aria-label={formatMessage({
              id: "productChooser.selectedProduct.removeItem",
              defaultMessage: "Remove Item",
            })}
            onClick={onRemoveItemClick}
            data-sku={dsku}
          />
        </div>
      </div>

      {!hazmatInformation && isUnapprovedBattery && (
        <div className={cls.qtyWarningMessage}>
          <Icon className={cls.icon} type="exclamation-triangle" />
          <FormattedMessage
            id="inbounds.selectedProduct.unapprovedBatteryMessage"
            defaultMessage="
            This SKU is not approved for batteries. If you send battery items without approval, you will be charged a removal fee. Remove this SKU from the shipping plan and apply for approval in Inventory Details by clicking the product link."
          />
        </div>
      )}

      {errorMessageForProduct?.showError && (
        <div className={cls.qtyWarningMessage}>
          <Icon className={cls.icon} type="exclamation-triangle" />
          {errorMessageForProduct.message}
        </div>
      )}

      {showAlreadyExcessInventory && (
        <div className={cx(cls.qtyWarningMessage, "storage-recommendation-already-excess")}>
          <Icon className={cls.icon} type="exclamation-triangle" />
          <FormattedMessage
            id={"inbounds.selectedProduct.alreadyExcessToEcomRecommendation"}
            defaultMessage={"This product already has over 3 months of supply in ecom fulfillment."}
          />
        </div>
      )}

      {showExcessInventory && (
        <div className={cx(cls.qtyWarningMessage, "storage-recommendation-excess")}>
          <Icon className={cls.icon} type="exclamation-triangle" />
          <FormattedMessage
            id={"inbounds.selectedProduct.excessToEcomRecommendation"}
            defaultMessage={"Sending this quantity will give you more than 3 months of supply in ecom fulfillment."}
          />
        </div>
      )}

      {/* TODO: re-enable these messages once national coverage is available again */}
      {/* https://app.asana.com/0/1117755068495017/1202407205989859/f */}
      {/* {invalidQty && (
        <div className={cls.qtyWarningMessage}>
          <Icon className={cls.icon} type="exclamation-triangle" />
          <FormattedMessage
            id="inbounds.selectedProduct.nationalQtyMessage"
            defaultMessage={`Inbound {qtyLeft} more {qtyLeft, plural,
              one {unit}
              other {units}
            } to get National Coverage.`}
            values={{
              qtyLeft: MIN_NATIONAL_QTY_PER_SKU - selectedQty,
            }}
          />
        </div>
      )} */}
      {/* {invalidCasePackQty && (
        <div className={cls.qtyWarningMessage}>
          <Icon className={cls.icon} type="exclamation-triangle" />
          <FormattedMessage
            id="inbounds.selectedProduct.nationalCasePackQtyMessage"
            defaultMessage={`Inbound {qtyLeft} more {qtyLeft, plural,
              one {case}
              other {cases}
            } to get National Coverage.`}
            values={{
              qtyLeft: MIN_NATIONAL_CASE_PER_SKU - (numberOfCases || 0),
            }}
          />
        </div>
      )} */}

      {invalidDskus?.includes(dsku) && (
        <div className={cls.noDimsErrorContainer}>
          <Icon color="red" type="exclamation-circle" className={cls.noDimsExclamationIcon} />
          <span>
            <FormattedMessage
              id="NewOrder.noDimsMessage"
              defaultMessage="You cannot create an order for this SKU because DIMs and weights haven't yet been confirmed by Flexport."
            />
          </span>
        </div>
      )}

      {actualCaseQty && !showCasePackSelectionDropdown && actualCaseQty !== caseQty && (
        <InputError
          appearance={Appearance.WARNING}
          message={
            <FormattedMessage
              id="inbounds.selectedProduct.casePackUnitUpdateWarning"
              defaultMessage="If you have multiple case pack configurations for this product, {contactUs}"
              values={{
                contactUs: (
                  <ContactUsLink href={`mailto:${RESERVE_STORAGE_CONTACT_EMAIL}`}>
                    <FormattedMessage
                      id="inbounds.selectedProduct.casePackUnitUpdateWarning.contactUs"
                      defaultMessage={"contact us"}
                    />
                  </ContactUsLink>
                ),
              }}
            />
          }
        />
      )}
      {showCasePackSelectionDropdown &&
        !shouldHideCasepackSingleUnitWarning &&
        (actualCaseQty === 1 || caseQty === 1) && (
          <InputError
            isBold={false}
            appearance={Appearance.WARNING}
            message={
              <FormattedMessage
                id="inbounds.selectedProduct.casePackSingleUnitWarning"
                defaultMessage="Make sure you are entering correct units per box. Incorrect values may lead to issues and delays."
              />
            }
          />
        )}
      {shouldShowSIOCWarning && shouldInboundAsSIOC && (
        <div className={cls.qtyWarningMessage}>
          <Icon className={cls.icon} type="exclamation-triangle" />
          <FormattedMessage
            id="inbounds.selectedProduct.shouldInboundAsSIOC"
            defaultMessage={`
              This product's dimensions exceeds our standard dimensions requirements of {length}" x {width}" x {height}" and {weight}lbs. Please ensure that this item is inbounded ready-to-ship in container/packaging to avoid SIOC non-compliance charges. {dimensionRequirements}`}
            values={{
              length: SIOC_DIMENSIONS_LIMITS.length,
              width: SIOC_DIMENSIONS_LIMITS.width,
              height: SIOC_DIMENSIONS_LIMITS.height,
              weight: SIOC_DIMENSIONS_LIMITS.weight,
              dimensionRequirements: (
                <ElevioBorderlessButton articleId={ElevioArticleId.DimensionalRequirements}>
                  <FormattedMessage
                    id="common.productChooser.dimensionRequirementsLinkForSIOC"
                    defaultMessage="Click here for more details"
                  />
                </ElevioBorderlessButton>
              ),
            }}
          />
        </div>
      )}
      {shouldShowBrandedPackagingAlert && isBrandedPackaging && (
        <InputError
          isBold={false}
          appearance={Appearance.DANGER}
          message={
            <FormattedMessage
              id="inbounds.selectedProduct.brandedPackagingAlert"
              defaultMessage="Branded packaging cannot be added to bundles."
            />
          }
        />
      )}
    </div>
  );
};

export default SelectedProduct;
