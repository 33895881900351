import { Appearance, Box, Notification } from "common/components/ui";
import { ElevioArticleId, ElevioLearnMoreButton } from "common/elevio";
import React from "react";
import { FormattedMessage } from "react-intl";

export const StorageNonCDLocationWarning = () => (
  <Box marginBottom={"S7"} width="70%">
    <Notification
      appearance={Appearance.WARNING}
      content={{
        title: (
          <FormattedMessage
            id="storage.inbounds.storageDestination.nonCrossdockWarningTitle"
            defaultMessage="Transportation must be arranged from this location."
          />
        ),
        description: (
          <FormattedMessage
            id="storage.inbounds.storageDestination.nonCrossdockWarningDescription"
            defaultMessage="For transfers to Ecom Fulfillment from this location, you must arrange transportation with a Deliverr partner or your own carrier."
          />
        ),
      }}
      append={<ElevioLearnMoreButton articleId={ElevioArticleId.StorageTransportationToCrossdock} />}
    />
  </Box>
);
