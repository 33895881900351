import { createSelector } from "reselect";
import { selectPlanItems } from "../plan/selectPlanItems";
import { getProductDetailsCache } from "../productDetailsCache";
import { BarcodeFormat, getBarcodeFormat } from "common/components/ui/utils/BarcodeUtils";
import { Product } from "@deliverr/commons-clients";
import { map } from "lodash/fp";

export const getShowBarcodeInput = createSelector(
  selectPlanItems,
  getProductDetailsCache,
  (planItems, productDetailsCache) => {
    const planItemDetails: Product[] = map(({ dsku }) => productDetailsCache[dsku], planItems);

    const hasNonDSKUBarcode = (product: Product) =>
      product.barcodes?.some((barcode) => getBarcodeFormat(barcode) !== BarcodeFormat.DSKU);

    const hasAllProductsNonDSKUBarCode = planItemDetails?.every((product) => hasNonDSKUBarcode(product));

    return !hasAllProductsNonDSKUBarCode;
  }
);
