import { BasicTableColumn } from "common/components/BasicTable";
import { usePrepCostConfigs } from "prep/hooks";
import { commonMsgs } from "inbounds/utils/commonMsgs";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useCostTableColumns } from "../useCostTableColumns";
import { KITTING_COST_TABLE_LABELS } from "./KittingCostTabel.label";
import { useCostTableAggregator } from "../useCostTableAggregator";
import { CostTableProps } from "../CostTable";
import { selectPlanKitProductDetails } from "inbounds/store/selectors/productDetailsCache/selectPlanKitProductDetails";

export const useKittingCostTable = (): CostTableProps => {
  const { formatMessage } = useIntl();
  const selectedKitProductDetails = useSelector(selectPlanKitProductDetails);
  const { getKittingPrepTypeCostValuesPerKit } = usePrepCostConfigs();

  const columns = useCostTableColumns({ tableTitle: formatMessage(KITTING_COST_TABLE_LABELS.tableTitle) });

  const data: BasicTableColumn[] = useMemo(() => {
    const rows: BasicTableColumn[] = selectedKitProductDetails.map((kitProduct) => {
      const relevantQuantity = kitProduct.qty;

      const { formattedItemQty, formattedItemCostWithGranularity, totalCost } = getKittingPrepTypeCostValuesPerKit(
        kitProduct.totalComponentQuantity,
        relevantQuantity
      )!;
      return {
        rowLabel: kitProduct.name,
        quantity: formattedItemQty,
        unitCost: formattedItemCostWithGranularity,
        amount: relevantQuantity > 0 ? totalCost : formatMessage(commonMsgs.valueToBeDetermined),
      };
    });

    return rows;
  }, [selectedKitProductDetails, getKittingPrepTypeCostValuesPerKit, formatMessage]);

  const kittingPrepSubTable = {
    columns,
    data,
    aggregatorOptions: { totalRowLabel: formatMessage(KITTING_COST_TABLE_LABELS.totalCostLabel) },
  };
  const costTableAggregated = useCostTableAggregator({ subTables: [kittingPrepSubTable], hideAggregatedRows: true });

  const aggregationDescription = formatMessage(KITTING_COST_TABLE_LABELS.kittingPrepClarification);

  return {
    ...costTableAggregated,
    aggregationDescription,
  };
};
