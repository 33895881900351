import { PlannedShipment } from "inbounds/InboundTypes";
import { getLoadedPlannedShipment } from "inbounds/steps/InboundStepSelectors";
import { getIsSpd } from "inbounds/utils/shippingMethodUtils";
import { createSelector } from "reselect";
import { selectIsForwardingPlan } from "inbounds/store/selectors/plan/selectIsForwardingPlan";
import { selectOneNodeFc } from "common/user/UserSelectors";
import { HUB_LOCATIONS } from "inbounds/ShipmentDetails/Milestones/constants/HubConstants";

export const selectIsBclPrepEnabledForFlow = createSelector(
  getLoadedPlannedShipment,
  selectIsForwardingPlan,
  selectOneNodeFc,
  (plannedShipment: PlannedShipment, isForwardingPlan: boolean, oneNodeFc: string) =>
    plannedShipment &&
    !getIsSpd(plannedShipment.shippingMethod) &&
    (isForwardingPlan || HUB_LOCATIONS.some((loc) => loc === oneNodeFc))
);
