import React, { FC } from "react";
import { ExternalLink } from "../ExternalLink";
import styled from "@emotion/styled";
import { ThemeProps } from "common/components/ui";
import { getHelpCenterNewRequestUrl } from "common/support/helpCenter";
import { getIsSupportChatLive } from "./getIsSupportChatLive";
import { useIntercomChatLauncher } from "./useIntercomChatLauncher";
import { useForethoughtChatLauncher } from "./useForethoughtChatLauncher";
import { FeatureName, useFeatureOn } from "common/Split";
import { useZendeskChatLauncher } from "./useZendeskChatLauncher";
import { ErrorBoundary } from "../ErrorBoundary/ErrorBoundary";

const UnstyledButton = styled.button<ThemeProps<{ renderAsLink: boolean }>>(
  ({ renderAsLink, theme }) => `
    appearance: none;
    margin: 0;
    padding: 0;
    border: 0;
    display: ${renderAsLink ? "inline" : "inline-block"};
    width: ${renderAsLink ? "auto" : "100%"};
    background: transparent;
    outline: none;
    box-shadow: none;
    color: ${renderAsLink ? theme.colors.BLUE["300"] : "inherit"};
    font-weight: ${renderAsLink ? theme.font.weight.BOLD : "inherit"};
  `
);

export interface SupportChatLauncherProps {
  onLaunch?: () => void;
  /**
   * By default, this will be rendered as a un-styled, black-text button.
   * This renders it as link style instead.
   **/
  renderAsLink?: boolean;
}

const IntercomChatLauncher: FC<SupportChatLauncherProps> = ({ onLaunch, renderAsLink = false, children }) => {
  const { onClick } = useIntercomChatLauncher(onLaunch);

  return (
    <UnstyledButton onClick={onClick} renderAsLink={renderAsLink}>
      {children}
    </UnstyledButton>
  );
};

const ForethoughtChatLauncher: FC<SupportChatLauncherProps> = ({ onLaunch, renderAsLink = false, children }) => {
  const { onClick } = useForethoughtChatLauncher(onLaunch);

  return (
    <UnstyledButton onClick={onClick} renderAsLink={renderAsLink}>
      {children}
    </UnstyledButton>
  );
};

const ZendeskChatLauncher: FC<SupportChatLauncherProps> = ({ onLaunch, renderAsLink = false, children }) => {
  const { onClick } = useZendeskChatLauncher(onLaunch);

  return (
    <UnstyledButton onClick={onClick} renderAsLink={renderAsLink}>
      {children}
    </UnstyledButton>
  );
};

export const SupportChatLauncher: FC<SupportChatLauncherProps> = ({ onLaunch, renderAsLink = false, children }) => {
  const isChatIntegrationFeatureOn = useFeatureOn(FeatureName.ChatIntegration);
  const isZenDeskChat = useFeatureOn(FeatureName.ZendeskChat);
  const isForethoughtFeatureOn = useFeatureOn(FeatureName.ForethoughtChatWidget);
  const isSupportChatLive = isChatIntegrationFeatureOn && getIsSupportChatLive();

  const ChatLauncher = isZenDeskChat
    ? ZendeskChatLauncher
    : isForethoughtFeatureOn
    ? ForethoughtChatLauncher
    : IntercomChatLauncher;

  return (
    <ErrorBoundary name="chat-launcher-error-boundary" errorDisplay={<></>}>
      {isSupportChatLive ? (
        <ChatLauncher onLaunch={onLaunch} renderAsLink={renderAsLink}>
          {children}
        </ChatLauncher>
      ) : (
        <ExternalLink onClick={onLaunch} href={getHelpCenterNewRequestUrl()}>
          {children}
        </ExternalLink>
      )}
    </ErrorBoundary>
  );
};
