import React, { FC } from "react";
import { FormattedMessage } from "react-intl";

import { Appearance, Notification, ThemeProps } from "common/components/ui";
import styled from "@emotion/styled";
import { ElevioArticleId, ElevioLearnMoreButton } from "common/elevio";

const StyledNotification = styled(Notification)<ThemeProps>(
  ({ theme }) => `
  margin-bottom: ${theme.spacing.S2};
`
);

export const PalletLimitError: FC = () => (
  <StyledNotification
    appearance={Appearance.DANGER}
    data-testId={"pallet-limit-error-test-id"}
    content={{
      title: (
        <FormattedMessage
          id="replenishment.pallet.limit.reached"
          defaultMessage="Estimated pallet count must be between 1-26. {learnMore}"
          values={{
            learnMore: <ElevioLearnMoreButton articleId={ElevioArticleId.TransferFromReserveStorage} />,
          }}
        />
      ),
    }}
  />
);
