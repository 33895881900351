import { FeatureName, isFeatureOn } from "../Split";
import {
  PartialSkuCaseQuantity,
  mapSkuCaseQtiesToSelectedProducts,
} from "transfers/create/store/mapSkuCaseQtiesToSelectedProducts";
import { keyBy, uniq, values } from "lodash";

import { ATPMap } from "common/clients/inventory/ATP/ATPMap";
import { ListType } from "../list";
import { Packs } from "common/clients/storage/Packs/Packs";
import { ProductListItem } from "inventory/list/InventoryListReducer";
import { fetchMultiPackProductATP } from "storage/packUtils/fetchMultiPackProductATP";
import { fetchMultiPackProductsByDskus } from "storage/utils/fetchMultiPackProductsByDskus";
import { inventoryClient } from "common/clients/instances";
import { productClient } from "Clients";
import { searchServiceFor } from "../search/Factory";

export const getItemProductAndInventoryDetails = async (
  skuQties: PartialSkuCaseQuantity[], // expects skuCaseQties to only contain pack dskus if multi pack is enabled
  dispatch,
  isMultiCasePackEnabled: boolean,
  fetchEcomInventory = false
) => {
  let draftProductInventory: ATPMap;
  let draftProducts: Packs;

  const skus = skuQties.map(({ dsku }) => dsku);

  if (isMultiCasePackEnabled) {
    // Fetch the product details ( including mapping child with parent details )
    draftProducts = await fetchMultiPackProductsByDskus(skus, dispatch);

    // Combine parent and default case pack DSKU inventory for displaying on UI.
    draftProductInventory = await fetchMultiPackProductATP(skus, draftProducts);
  } else {
    [draftProducts, draftProductInventory] = await Promise.all([
      productClient.getUnifiedProducts(skus),
      inventoryClient.getATP(skus, undefined, "STORAGE"),
    ]);
  }

  const productDskus = uniq(values(draftProducts).map((product) => product.packOf ?? product.dsku));

  let draftProductEcomInventory;
  if (fetchEcomInventory) {
    const totalProductDskus = productDskus.length;
    const inventorySearchService = searchServiceFor(
      isFeatureOn(FeatureName.LogisticsSearchProductsReadEnabled) ? ListType.InventoryV2 : ListType.Inventory
    );
    const productIndexRecords = (await inventorySearchService.searchByIds(productDskus, "", "dsku", totalProductDskus))
      .hits as ProductListItem[];
    draftProductEcomInventory = keyBy(productIndexRecords, "dsku");
  }

  const selectedProducts = mapSkuCaseQtiesToSelectedProducts(
    skuQties,
    draftProducts,
    draftProductInventory,
    draftProductEcomInventory
  );

  return { selectedProducts, draftProducts, draftProductInventory, draftProductEcomInventory };
};
