import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";
import { selectTransferSelectedProductsQty } from "transfers/create/store/selectTransferSelectedProductsQty";
import { getShipmentItems } from "./utils/getShipmentItems";
import { SPThunkAction } from "common/ReduxUtils";
import { RootState } from "RootReducer";
import { OrderItemDto, OrderResponse, UpdateOrderRequest } from "@deliverr/replenishment-client";
import { replenishmentClient } from "Clients";
import { isInvalidItemOrder } from "transfers/create/steps/common/replenishment/details/invalid-items/InvalidItemOrderUtils";

export const updateReplenishmentOrder =
  (orderId: string, submitDraft: boolean, createDraftOrderInChannel?: boolean): SPThunkAction<Promise<OrderResponse>> =>
  async (dispatch, getState: () => RootState) => {
    const state = getState();

    const { originStorageWarehouse, prepOption, selectedProductsLotNumbers, replenishmentOrder } =
      selectTransferCreate(state);

    let orderItems: OrderItemDto[] = [];

    if (isInvalidItemOrder(replenishmentOrder!)) {
      orderItems = replenishmentOrder?.orderItems ?? [];
    } else {
      const selectedProductsQty = selectTransferSelectedProductsQty(state);
      orderItems = await dispatch(
        getShipmentItems(selectedProductsQty, originStorageWarehouse, selectedProductsLotNumbers)
      );
    }

    const updateOrderRequest: UpdateOrderRequest = {
      fulfillStrategy: replenishmentOrder?.fulfillStrategy,
      originWarehouseId: originStorageWarehouse?.warehouse?.id,
      orderItems,
      submitDraft,
      prepOption,
      createDraftOrderInChannel,
    };

    await replenishmentClient.updateOrder(orderId, updateOrderRequest);
    const orderResponse: OrderResponse = (
      await replenishmentClient.getOrder(orderId, { includeShipments: true, includeInvalidOrderItems: true })
    ).value;
    return orderResponse;
  };
