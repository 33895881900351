import React, { FC } from "react";
import { Text, SelectComponentOption, Grid, DefaultTheme } from "common/components/ui";
import { OptionProps } from "@deliverr/react-select-v1";
import { useIntl } from "react-intl";
import styled from "@emotion/styled";
import { ProductSearchResultItem } from "../ProductSearchResult";
import { ProductErrorMessages } from "../ProductErrors";
import HtmlToReact from "../../HtmlToReact";
import { getBarcodeFormat } from "common/components/ui/utils/BarcodeUtils";
import { capitalize } from "lodash";
import { css } from "emotion";
import cx from "classnames";
import { PackInfo } from "../Packs/components/PackInfo";

const Description = styled.div`
  em {
    font-style: normal;
    font-weight: bold;
  }
`;

const Sku = styled.div`
  min-width: 5rem;
`;

const OptionContent = styled.div<{ isDisabled: boolean }, DefaultTheme>(
  ({ theme, isDisabled }) => `
    * {
      ${isDisabled ? `color: ${theme.colors.NEUTRAL[200]};` : ""}
    }
  `
);

const notAllowedCursor = css("cursor: not-allowed !important;"); // Override default react-select styling

const getBarcodeWithFormat = (barcode: string) => {
  const format = getBarcodeFormat(barcode);
  if (!format) {
    return null;
  }

  return format.toUpperCase() + " " + barcode;
};

export const ProductSearchOption: FC<OptionProps> = (props) => {
  const { formatMessage } = useIntl();
  const {
    isDisabled,
    data,
    selectProps: { showAvailability, isReserveStorage, showOnlyPacks, shouldShowKitHint },
  } = props;
  const { error, availableQty, storageAvailableQty, descriptionHtml, msku, barcode, hazmatInformation, isKit } =
    data as ProductSearchResultItem;
  const qtyOrError = error
    ? formatMessage(ProductErrorMessages[error], {
        status: capitalize(hazmatInformation?.status ?? ""),
      })
    : formatMessage(
        { id: "productSearch.availableQuantity", defaultMessage: "{qty} available" },
        { qty: isReserveStorage ? storageAvailableQty : availableQty }
      );

  const updatedDescriptionHtml = shouldShowKitHint && isKit ? `${descriptionHtml} (Kit)` : descriptionHtml;

  return (
    <SelectComponentOption {...props} className={cx({ [notAllowedCursor]: isDisabled })}>
      <OptionContent isDisabled={isDisabled}>
        <Grid columns="1fr auto">
          <Description>
            <Text as="span">
              <HtmlToReact html={updatedDescriptionHtml} />
            </Text>
            <Sku>
              <Text size="caption" as="span">
                <HtmlToReact html={msku} />
                {barcode && getBarcodeWithFormat(barcode)}
                {showOnlyPacks && <PackInfo product={data} />}
              </Text>
            </Sku>
          </Description>
          {showAvailability && (
            <div>
              <Text size="caption" as="span">
                {qtyOrError}
              </Text>
            </div>
          )}
        </Grid>
      </OptionContent>
    </SelectComponentOption>
  );
};
