// value should correspond exactly to what appears in splitio
// remember to remove references to and delete splits that have been rolled out to 100%
export enum FeatureName {
  BrandedPackaging = "branded_packaging",
  BrandedPackagingDskuSelection = "branded_packaging_dsku_selection",
  BrandedPackagingOmitPricing = "BRANDED_PACKAGING_OMIT_PRICING",
  BrandedPackagingInboundCreation = "branded_packaging_inbound_creation",
  BulkCreateProductsFeature = "BULK_CREATE_PRODUCTS_FEATURE",
  BulkUploadV2 = "bulk_upload_v2",
  B2BViaOpenSearch = "B2B_VIA_OPENSEARCH",
  ClaimsIncorrectMissingProducts = "claims_incorrect_missing_products",
  ClaimsUploadSearch = "claims_upload_search",
  ClaimsAppeal = "claims_appeal",
  CrossdockChargeExempt = "charge_for_crossdock", // on means exempt from forwarding fees
  CustomBarcodes = "SP_CREATE_CUSTOM_BARCODES",
  DashboardDiscrepantUnits = "dashboard_discrepant_units",
  DDPPassport = "DDP_Passport",
  EcomPrep = "ECOM_PREP_ENABLED",
  FbaBulkProductCSVUpload = "FBA_BULK_PRODUCT_CSV_UPLOAD",
  FefoExpirationDate = "fefo_expiration_date",
  GlobalStopShipByDays = "stop_ship_by_days",
  SkuStopShipByDays = "sku_stop_ship_by_days",
  ForethoughtChatWidget = "SP_FORETHOUGHT_CHAT_WIDGET",
  FreightConsolidatedLTL = "SP_FREIGHT_CONSOLIDATED_LTL",
  FreightDrayage = "SP_FREIGHT_DRAYAGE",
  FreightRatesPreview = "SP_FREIGHT_RATES_PREVIEW",
  FreightSelfServe = "SP_FREIGHT",
  Hazmat3480And3090Expansion = "hazmat_3480_and_3090_expansion_seller_portal",
  HideForwardingCost = "HIDE_FORWARDING_COST", // temporary split for hiding forwarding cost
  InboundExpectedQtyUneditable = "SP_INBOUND_EXPECTED_QTY_UNEDITABLE",
  InventoryAdjustmentHistory = "inventory_adjustment_history",
  InventoryLedger = "inventory_ledger",
  InventoryLedgerSourcedReports = "inventory_ledger_sourced_reports",
  InventoryCorrectTotalsCalculationEnabled = "INVENTORY_CORRECT_TOTALS_CALCULATION_ENABLED",
  LegacySfnOnboarding = "LEGACY_SFN_ONBOARDING",
  LogisticsSearchProductsReadEnabled = "LOGISTICS_SEARCH_PRODUCTS_READ_ENABLED",
  LogisticsSearchBundlesReadEnabled = "LOGISTICS_SEARCH_BUNDLES_READ_ENABLED",
  LogisticsSearchShipmentsReadEnabled = "LOGISTICS_SEARCH_SHIPMENTS_READ_ENABLED",
  LogisticsSearchOrdersReadEnabled = "LOGISTICS_SEARCH_ORDERS_READ_ENABLED",
  LogisticsSearchChannelEventsReadEnabled = "LOGISTICS_SEARCH_CHANNEL_EVENTS_READ_ENABLED",
  LogisticsSearchOrderListUpdatedSearch = "LOGISTICS_SEARCH_ORDER_LIST_UPDATED_SEARCH",
  LotBulkActions = "lot_bulk_actions",
  LotQuarantinedRemovalDisposal = "lot_quarantined_removal_disposal",
  LotTrackingTableV2 = "lot_tracking_table_v2",
  LotUnquarantinedRemovalDisposal = "lot_unquarantined_removal_disposal",
  ManualOrderCreation = "manual_order_creation",
  MetroDeliverr = "metro_deliverr",
  Notifications = "notifications",
  OneToManyViewing = "one_to_many_viewing",
  OrderWarehouseAlert = "order_warehouse_alert",
  PeakClaimsWindow = "peak_claims_window",
  PeakSeason2024Banner = "PEAK_SEASON_2024_BANNER",
  PeakSeason2024BillingBanner = "PEAK_SEASON_2024_BILLING_BANNER",
  PrepKittingEnabled = "prep_kitting_enabled",
  PrepKittingInbounds = "PREP_KITTING_INBOUNDS",
  PrepPalletLabelsEnabled = "PREP_PALLET_LABELS_ENABLED",
  PrepDetailsPageEnabled = "PREP_DETAILS_PAGE",
  PrimarySalesChannelBanner = "PRIMARY_SALES_CHANNEL_BANNER",
  ReserveStorageLotTracking = "RESERVE_STORAGE_LOT_TRACKING",
  ReturnsRTM = "returns_rtm",
  ReturnsRtv = "returns_rtv",
  SaveStorageInboundTrackingInfo = "SAVE_STORAGE_INBOUND_TRACKING_INFO",
  SelectAddressTransferOrderViaOpenSearch = "SELECT_ADDRESS_TRANSFER_ORDER_VIA_OPENSEARCH",
  ShopifyExperimentAbTest = "shopify_experiment_ab_test",
  ShopifyPaidInternationalBadges = "shopify_paid_international_badges",
  ShowActualCostInSummary = "SHOW_ACTUAL_COST_IN_SUMMARY",
  SkipAddingLotExpiry = "skip_adding_lot_expiry",
  BillingNewFulfillmentRates = "BILLING_NEW_FULFILLMENT_RATES",
  WholesaleViewV2 = "WHOLESALE_VIEW_V2",
  ZendeskChat = "ZENDESK_CHAT",
  ChatIntegration = "CHAT_INTEGRATION",
  AllowLargerProductsInternationally = "ALLOW_PRODUCTS_EXCEEDING_20_LBS_INTERNATIONALLY",
  /**
   * This is a special parent feature flag to group all Flexport SMB-related features.
   * It should always be used in conjunction with a child feature flag specific to a particular feature.
   */
  SmbAccelerate = "SP_SMB_ACCELERATE",
  SmbAccelerateOnboardingBannerFix = "SP_SMB_ACCELERATE_ONBOARDING_BANNER_FIX",
  SmbAccelerateIpb = "SP_SMB_ACCELERATE_IPB",
  SmbAccelerateShipEx = "SP_SMB_ACCELERATE_SHIPEX",
  SmbAccelerateIfo = "SP_SMB_ACCELERATE_IFO",
  SmbAccelerateNotifications = "SP_SMB_ACCELERATE_NOTIFICATIONS",
  SmbAccelerateFeedback = "SP_SMB_ACCELERATE_FEEDBACK",
  SmbAccelerateRelayTest = "SP_SMB_ACCELERATE_RELAY_TEST",
  SmbAccelerateFlexportPlus = "SP_SMB_ACCELERATE_FP_PLUS",
  SmbAccelerateFbaAutomated = "SP_SMB_ACCELERATE_FBA_AUTOMATED",
  SmbAccelerateFbaUnitPrep = "SP_SMB_ACCELERATE_FBA_UNIT_PREP",
  SmbAccelerateShowInsuranceInCreateShipment = "SP_SMB_SHOW_INSURANCE_IN_CREATE_SHIPMENT",
  SmbAccelerateCustomsClearanceInFreightCard = "SP_SMB_CUSTOMS_CLEARANCE_IN_FREIGHT_CARD",
  SmbAccelerateBookingStatus = "SP_SMB_BOOKING_STATUS",
  SmbAccelerateRevampedOriginSearch = "SP_SMB_ACCELERATE_REVAMPED_ORIGIN_SEARCH",
  SmbForeignImportOnboardingFlow = "SMB_FOREIGN_IMPORT_ONBOARDING_FLOW",
  SmbAccelerateAccountAutoLink = "SP_SMB_ACCELERATE_ACCOUNT_AUTO_LINK",
  SmbInsuranceProfile = "SP_SMB_INSURANCE_PROFILE",
  SmbIpbDevTools = "SMB_IPB_DEVTOOLS",
  SmbIpbDrafts = "SMB_IPB_DRAFTS",
  SmbAccelerateLithiumBatteriesWorkflow = "SP_SMB_LITHIUM_BATTERIES_WORKFLOW",
  SmbOfferingTrace = "SMB_OFFERING_TRACE",
  SmbOnboardingUpdateEinUi = "SP_SMB_ONBOARDING_UPDATE_EIN_UI",
  SmbPostBookingQuote = "SP_SMB_POST_BOOKING_QUOTES",
  SmbShipmentProblems = "SP_SMB_SHIPMENT_PROBLEMS",
  SmbCreditCardFee = "charge_credit_card_processing_fee",
  SmbPackagesBulkUpload = "SMB_PACKAGES_BULK_UPLOAD",
  StorageAdminBarcodeDownload = "storage_admin_barcode_download",
  StorageMultiCasePack = "reserve_storage_multi_case_pack",
  UnifiedPricingNewMerchant = "UNIFIED_PRICING_NEW_MERCHANT", // temporary split for new merchant pricing
  UnpaidInvoice = "unpaid_invoice",
  UserContactAreas = "user_contact_areas",
  WholesaleDuplicateOrder = "wholesale_duplicate_order",
  WholesaleForbid = "wholesale_forbid",
  WholesaleOrderHeldState = "WHOLESALE_ORDER_HELD_STATE",
  WholesaleOrderEachPicking = "WHOLESALE_ORDER_EACH_PICKING",
  WholesaleServiceSyncAPI = "WHOLESALE_SERVICE_SYNC_API",
  WholesaleCarrierAccount = "ENABLE_WHOLESALE_CARRIER_ACCOUNT",
  wfsReplenishmentSettings = "WFS_REPLENISHMENT_SETTINGS",
  FbaMultiShipmentShipping = "FBA_MULTI_SHIPMENT_SHIPPING",
  ParcelApiLimitedAccess = "PARCEL_API_LIMITED_ACCESS",
  ParcelLinkFlexportShipment = "PARCEL_LINK_FLEXPORT_SHIPMENT",
  ParcelValidateLinkFlexportShipment = "PARCEL_VALIDATE_LINK_FLEXPORT_SHIPMENT",
  TransferDetailsPageViaReplenishmentService = "transfer_details_page_via_replenishment_service",
  ReplenishmentsLotBasedOutbounds = "REPLENISHMENTS_LOT_BASED_OUTBOUNDS",
  FbaUnitPrep = "FBA_UNIT_PREP",
  SmbIpbIntlUnavailable = "SMB_IPB_INTL_UNAVAILABLE", // flag used to manually disable international IPB
  WarehouseRemoval = "SP_WAREHOUSE_REMOVAL",
  WholesaleEachPicking = "wholesale_each_picking",
  TellMeAboutYourBusinessRevamp = "TELL_ME_ABOUT_YOUR_BUSINESS_REVAMP",
  GriLaunch = "GRI_LAUNCH",
  ReserveStorageInboundReplenishmentRecommendations = "RESERVE_STORAGE_INBOUND_REPLENISHMENT_RECOMMENDATIONS",
  SmbIpbDtc = "SMB_IPB_DTC",
  WarehouseMoveBanner = "WAREHOUSE_MOVE_BANNER", // temporary split to display warehouse move banner, to be removed Apr 1, 2024
  SmbIpbBookingV2 = "SMB_IPB_BOOKING_V2",
  LtlQuoteImprovement = "LTL_QUOTE_IMPROVEMENT",
  UnifiedSFNMigration = "UNIFIED_SFN_MIGRATION",
  IntlInboundRsSelection = "INTL_INBOUND_RS_SELECTION",
  InboundRsDimRelaxation = "INBOUND_RS_DIM_RELAXATION",
  BulkCancelOrderFeature = "BULK_CANCEL_ORDER_FEATURE",
  DisableIntegrationEditNonAdmin = "DISABLE_INTEGRATION_EDIT_NON_ADMIN",
  BoxDimensionsRestrictionsRelaxed = "BOX_DIMENSIONS_RESTRICTIONS_RELAXED",
  LogisticsSearchUseCarrierSearch = "logistics_search_useCarrierSearch",
  SpGuidedTour = "SP_GUIDED_TOUR",
  GlobalSearch = "GLOBAL_SEARCH",
}

/**
 * Splits that should be treated as "on" if the split client is not available
 */
export const SPLITS_WITH_DEFAULT_ON_TREATMENT: FeatureName[] = [FeatureName.Notifications];
