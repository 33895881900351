import { CancelSource, DeliverrAddress } from "@deliverr/commons-objects";
import { ApiError } from "@deliverr/errors";
import { ApiClient } from "../core/ApiClient";
import { ApiClientConfig } from "../core/ApiClientConfig";
import { CreatedOrder } from "./CreatedOrder";
import { isAuthenticated } from "../core/authentication";
import { OrderExceptions } from "./OrderExceptions";
import { DeliverrOrderData } from "./DeliverrOrderData/DeliverrOrderData";
import { BulkOrderPreview } from "./BulkOrder/BulkOrderPreview";
import { BulkOrderCreationResponse } from "./BulkOrder/BulkOrderCreationResponse";
import { OrderSummary } from "./OrderSummary";

export type OrdersClientConfig = ApiClientConfig;

export const defaultOrdersClientConfig = (): OrdersClientConfig => {
  if (process.env.FULFILLMENT_SERVICE_URL) {
    return {
      baseURL: `${process.env.FULFILLMENT_SERVICE_URL}/v1/`,
    };
  } else {
    throw new Error(
      `Unable to get default orders client config, environment variable FULFILLMENT_SERVICE_URL could not be found`
    );
  }
};

export class OrdersClient {
  private apiClient: ApiClient;

  constructor({ baseURL }: OrdersClientConfig = defaultOrdersClientConfig()) {
    this.apiClient = new ApiClient({
      baseURL,
    });
  }

  /**
   * @deprecated as of August 8, 2023, use FulfillmentOrderClient instead
   */
  async cancelOrder(orderId: string, cancelSource?: CancelSource): Promise<void> {
    try {
      return await this.apiClient.delete({
        url: `/workflow/order/${orderId}`,
        body: {
          cancelSource,
        },
        authentication: isAuthenticated,
      });
    } catch (error) {
      if (error.response?.data?.error === OrderExceptions.FAILED_CANCEL_AT_WAREHOUSE) {
        throw new Error(OrderExceptions.FAILED_CANCEL_AT_WAREHOUSE);
      }

      switch (error.response?.status) {
        case 400:
          throw new Error(OrderExceptions.ORDER_SHIPPED);
        case 404:
          throw new Error(OrderExceptions.NOT_FOUND);
        case 504:
          throw new Error(ApiError.NETWORK_ERROR);
        default:
          throw new Error(ApiError.UNKNOWN_ERROR);
      }
    }
  }

  /**
   * @deprecated as of August 8, 2023, use FulfillmentOrderClient instead
   */
  async createOrder(orderData: DeliverrOrderData): Promise<CreatedOrder | undefined> {
    try {
      return await this.apiClient.post({
        url: "/order",
        body: orderData,
        authentication: isAuthenticated,
      });
    } catch (error) {
      switch (error.response?.status) {
        case 400:
          throw new Error(OrderExceptions.INVALID_ADDRESS);
        case 409:
          throw new Error(OrderExceptions.DUPLICATE_ORDER);
        case 410:
          throw new Error(OrderExceptions.STOCKOUT);
        case 504:
          throw new Error(ApiError.NETWORK_ERROR);
        default:
          throw new Error(ApiError.UNKNOWN_ERROR);
      }
    }
  }

  async getBulkOrderPreview(signedUrl: string): Promise<BulkOrderPreview> {
    return await this.apiClient.get({
      url: `/orders/bulk/preview?signedUrl=${signedUrl}`,
      authentication: isAuthenticated,
    });
  }

  /**
   * @deprecated as of August 8, 2023, use FulfillmentOrderClient instead
   */
  async getOrder(orderId: string): Promise<OrderSummary | undefined> {
    try {
      return await this.apiClient.get<OrderSummary | undefined>({
        url: `/workflow/order/${orderId}`,
        authentication: isAuthenticated,
      });
    } catch (error) {
      switch (error.response?.status) {
        case 404:
          return undefined;
        case 504:
          throw new Error(ApiError.NETWORK_ERROR);
        default:
          throw new Error(ApiError.UNKNOWN_ERROR);
      }
    }
  }

  async importBulkOrder(signedUrl: string, sellerId: string): Promise<BulkOrderCreationResponse> {
    return await this.apiClient.post({
      url: `/orders/bulk`,
      body: { sellerId, signedUrl },
      authentication: isAuthenticated,
    });
  }

  /**
   * @deprecated as of August 8, 2023, use FulfillmentOrderClient instead
   */
  async updateShippingAddress(orderId: string, address: DeliverrAddress, forceUpdate = false): Promise<{}> {
    try {
      return await this.apiClient.put({
        url: `/order/${orderId}/address`,
        body: {
          address,
          forceUpdate,
        },
        authentication: isAuthenticated,
      });
    } catch (error) {
      switch (error.response?.status) {
        case 400:
          throw new Error(OrderExceptions.ORDER_SHIPPED);
        case 404:
          throw new Error(OrderExceptions.NOT_FOUND);
        case 422:
          throw new Error(OrderExceptions.INVALID_ADDRESS);
        case 504:
          throw new Error(ApiError.NETWORK_ERROR);
        default:
          throw new Error(ApiError.UNKNOWN_ERROR);
      }
    }
  }
}
