import React from "react";
import {
  toast as toastify,
  ToastContainer as BaseToastContainer,
  ToastContainerProps,
  ToastContentProps,
  ToastOptions,
  TypeOptions,
} from "react-toastify";
import styled from "@emotion/styled";
import { defaultTheme, DefaultTheme, ThemeProps } from "../shared/theme";
import { IconV2, IconV2Props, IconV2Types } from "../IconV2";
import { Text, TextProps } from "../Text";

import { injectStyle } from "react-toastify/dist/inject-style";
import { LocalizedMessage } from "../types";

if (typeof document !== "undefined") {
  injectStyle();
}

const TOAST_WIDTH = "440px";
const TOAST_HEIGHT = "52px";

export const ToastContainer = styled(BaseToastContainer)<ToastContainerProps, DefaultTheme>(
  ({ theme }) => `
  &.Toastify__toast-container {
    color: ${theme.colors.NEUTRAL[500]};
    width: ${TOAST_WIDTH};
    padding: 0;

    &.Toastify__toast-container--bottom-right {
      bottom: ${theme.spacing.S5};
      right: ${theme.spacing.S5};
    }

    &.Toastify__toast-container--bottom-left {
      bottom: ${theme.spacing.S5};
      left: ${theme.spacing.S5};
    }

    &.Toastify__toast-container--top-right {
      top: ${theme.spacing.S5};
      right: ${theme.spacing.S5};
    }

    &.Toastify__toast-container--top-left{
      top: ${theme.spacing.S5};
      left: ${theme.spacing.S5};
    }
  }
  .Toastify__progress-bar {
    height: ${theme.spacing.S1};
  }
  .Toastify__toast {
    width: ${TOAST_WIDTH};
    min-height: ${TOAST_HEIGHT};
    height: auto;
    background: ${theme.colors.BLUE[50]};
    border-left: ${theme.border.radius.R4} ${theme.border.type} ${theme.colors.BLUE[400]};
    margin-bottom: 0;

    .Toastify__progress-bar {
      background: ${theme.colors.BLUE[400]};
    }
  }
  .Toastify__toast--error {
    background: ${theme.colors.RED[100]};
    border-left: ${theme.border.radius.R4} ${theme.border.type} ${theme.colors.RED[300]};

    .Toastify__progress-bar {
      background: ${theme.colors.RED[300]};
    }
  }
  .Toastify__toast--warning {
    background: ${theme.colors.YELLOW[100]};
    border-left: ${theme.border.radius.R4} ${theme.border.type} ${theme.colors.YELLOW[300]};

    .Toastify__progress-bar {
      background: ${theme.colors.YELLOW[300]};
    }
  }
  .Toastify__toast--success {
    background: ${theme.colors.GREEN[100]};
    border-left: ${theme.border.radius.R4} ${theme.border.type} ${theme.colors.GREEN[300]};

    .Toastify__progress-bar {
      background: ${theme.colors.GREEN[300]};
    }
  }
  .Toastify__toast-body {
    color: ${theme.colors.NEUTRAL[500]};
    border-radius: ${theme.border.radius.R4} 0 0 ${theme.border.radius.R4};
  }
`
);

ToastContainer.defaultProps = {
  closeButton: false,
  autoClose: 5000,
  closeOnClick: false,
  newestOnTop: true,
  position: "bottom-left",
};

const StyledToast = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledIconContainer = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
`;

const StyledIcon = styled(IconV2)<IconV2Props, DefaultTheme>(
  ({ theme }) => `
    margin-right: ${theme.spacing.S4};
  `
);

const StyledText = styled(Text)<TextProps, DefaultTheme>(
  ({ theme }) => `
    display: block;
    width: 100%;
    text-align: left;
    line-height: ${theme.font.lineHeight.LH2};
  `
);

const StyledCTA = styled.button<ThemeProps>(
  ({ theme }) => `
  font-family: ${theme.font.family.STANDARD};
  font-weight: ${theme.font.weight.BOLD};
  font-size: ${theme.font.size.F2};
  color: ${theme.colors.BLUE[300]};
  margin-right: ${theme.spacing.S4};
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  &:hover {
    color: ${theme.colors.BLUE[500]};
  }
`
);

interface CTAProps {
  text: LocalizedMessage;
  onClick: () => void;
}

const iconProps: Record<Exclude<TypeOptions, "default" | "dark">, { type: IconV2Types; color: string }> = {
  info: {
    type: "info-circle" as IconV2Types,
    color: defaultTheme.colors.BLUE[400],
  },
  warning: {
    type: "exclamation-triangle" as IconV2Types,
    color: defaultTheme.colors.YELLOW[300],
  },
  success: {
    type: "check-circle" as IconV2Types,
    color: defaultTheme.colors.GREEN[300],
  },
  error: {
    type: "exclamation-circle" as IconV2Types,
    color: defaultTheme.colors.RED[300],
  },
};

export const Toast: React.FC<
  Partial<ToastContentProps> & {
    message: LocalizedMessage;
    icon?: IconV2Types;
    action?: CTAProps;
  }
> = ({ toastProps = { type: "info" }, closeToast, message, action }) => (
  <StyledToast>
    <StyledIcon {...iconProps[toastProps.type!]} />
    <StyledText>{message}</StyledText>
    <StyledIconContainer>
      {action && <StyledCTA onClick={action.onClick}>{action.text}</StyledCTA>}
      <IconV2 type="close" aria-label="close toast" onClick={closeToast} />
    </StyledIconContainer>
  </StyledToast>
);

export const toast = {
  info: (message: LocalizedMessage, options?: ToastOptions, action?: CTAProps) =>
    toastify.info(<Toast message={message} action={action} />, options),
  success: (message: LocalizedMessage, options?: ToastOptions, action?: CTAProps) =>
    toastify.success(<Toast message={message} action={action} />, options),
  warning: (message: LocalizedMessage, options?: ToastOptions, action?: CTAProps) =>
    toastify.warning(<Toast message={message} action={action} />, options),
  error: (message: LocalizedMessage, options?: ToastOptions, action?: CTAProps) =>
    toastify.error(<Toast message={message} action={action} />, options),
  dismiss: (toastId?: string | number) => toastify.dismiss(toastId),
};
